import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "assets/scss/user.scss";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconLogout, IconSettings } from "@tabler/icons";
import { apiRequest } from "Helper/ApiRequest";
import { handleLogout } from "Helper/Tokens";

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  const anchorRef = useRef(null);
  const logout = async () => {
    await handleLogout();
    navigate("/login");
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    fetchUser();

    prevOpen.current = open;
  }, [open]);

  async function fetchUser() {
    const response = await apiRequest({
      api: "GetProfile",
    });

    setUser(response.data);
  }

  const greeting = () => {
    const currentTime = new Date().getHours();
    let greetingText = "";

    if (currentTime < 12) {
      greetingText = "Good Morning";
    } else if (currentTime < 18) {
      greetingText = "Good Afternoon";
    } else {
      greetingText = "Good Evening";
    }
    return greetingText;
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <Box
        onClick={handleToggle}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        ref={anchorRef}
        sx={{
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          transform: "none",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          boxSizing: "border-box",
          backgroundColor: "transparent", // Base background color
          cursor: "pointer",
          userSelect: "none",
          verticalAlign: "middle",
          appearance: "none",
          textAlign: "center",
          fontSize: "1.5rem",
          color: "#637381",
          margin: 0,
          padding: 0,
          borderWidth: "0px",
          flex: "0 0 auto",
          borderRadius: "50%",
          overflow: "visible",
          transition: "transform 250ms ease, background-color 250ms ease", // Smoother transition for transform
          "&:hover": {
            backgroundColor: "transparent",
            transform: "scale(1.1) translateZ(0)", // Slightly larger scale for a more noticeable effect
          },
          "&:focus": {
            backgroundColor: "transparent",
            outline: "none", // Optional: Remove focus outline if needed
          },
          "&:active": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box
          sx={{
            mr: 3,
            width: "45px",
            height: "45px",
            flexShrink: 0,
            borderRadius: "50%",
            position: "relative",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              border: "4px solid transparent",
              background: "linear-gradient(45deg, #f06, #0cf, #f06, #0cf)",
              backgroundSize: "400% 400%",
              zIndex: 0,
              animation: "spin 2s linear infinite",
            },
            "@keyframes spin": {
              "0%": { transform: "rotate(0deg)" },
              "100%": { transform: "rotate(360deg)" },
            },
          }}
        >
          <Avatar
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexShrink: 0,
              fontSize: "1.25rem",
              lineHeight: 1,
              borderRadius: "50%",
              overflow: "hidden",
              userSelect: "none",
              zIndex: 1,
              width: "calc(100% - 6px)",
              height: "calc(100% - 6px)",
            }}
            src={`${process.env.PUBLIC_URL}/images/user.png`}
          />
        </Box>
      </Box>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack
                        sx={{ paddingTop: 1 }}
                        direction="row"
                        spacing={0.5}
                        alignItems="center"
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {greeting()},
                        </Typography>
                        {user && (
                          <Typography
                            component="span"
                            variant="h4"
                            sx={{
                              fontWeight: 400,
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            }}
                          >
                            {capitalizeFirstLetter(user.firstName)}{" "}
                            {capitalizeFirstLetter(user.lastName)}
                          </Typography>
                        )}
                      </Stack>
                      {user && (
                        <Typography
                          className="mb-3"
                          variant="subtitle2"
                          sx={{
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {capitalizeFirstLetter(user.roles[0].title)}
                        </Typography>
                      )}
                    </Stack>
                    <Divider
                      sx={{
                        m: 0,
                        borderWidth: "0px 0px thin",
                        borderStyle: "dashed",
                        borderColor: "rgba(145 158 171 / 0.2)",
                      }}
                    />
                  </Box>
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ p: 3, paddingTop: 0 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 0}
                          onClick={(event) =>
                            handleListItemClick(event, 0, "/account")
                          }
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                }}
                              >
                                Account Settings
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={logout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                }}
                              >
                                Logout
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
