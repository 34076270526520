/* eslint-disable */
import { Box, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";

const PrivacyPolicyPage = () => {
  return (
    <MainCard>
      {/* Introduction */}
      <Box>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 28,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Privacy Policy
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontStyle: "italic",
          }}
        >
          Effective starting August 28th, 2024
        </Typography>

        {/* TODO: Replce ABN */}
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          This Privacy Policy sets out our commitment to protecting the privacy
          of personal information provided to us, or otherwise collected by us,
          offline or online, including through this website (Site). In this
          Privacy Policy we, us or our means Project Pit ABN 37 310 353 796.
          When we collect, store and use your personal information, we do so in
          accordance with the rules set down in the Australian Privacy Act 1988
          (Cth) and, to the extent applicable, by the European Union General
          Data Protection Regulation (EU) 2016/279 (the GDPR).
        </Typography>
      </Box>
      {/* Personal Information */}
      <Box>
        <Typography
          sx={{
            mt: 3,
            fontWeight: 500,
            fontSize: 26,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Personal Information
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          <b>Personal Information</b>: The types of personal information or
          personal data we may collect about you include:
        </Typography>

        {/* TODO: Replce ABN */}
        <Typography
          component="ul"
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            paddingLeft: 2,
            listStyleType: "disc", // Use 'disc' for bullet points
            lineHeight: 1.6, // Optional: adjust line height for better readability
          }}
        >
          <li>your name</li>
          <li>
            your contact details, including email address, mailing address,
            street address and/or telephone number
          </li>
          <li>your age and/or date of birth</li>
          <li>your demographic information, such as postcode</li>
          <li>
            any other personal information requested by us and/or provided by
            you or a third party
          </li>
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          We may collect these types of personal information directly from you
          or from third parties.
        </Typography>
      </Box>
      {/* Disclosure of personal information to third parties */}
      <Box>
        <Typography
          sx={{
            mt: 3,
            fontWeight: 500,
            fontSize: 26,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Disclosure of personal information to third parties
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          We may disclose personal information to:
        </Typography>
        <Typography
          component="ul"
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            paddingLeft: 2,
            listStyleType: "disc", // Use 'disc' for bullet points
            lineHeight: 1.6, // Optional: adjust line height for better readability
          }}
        >
          <li>
            third party service providers for the purpose of enabling them to
            provide their services, including (without limitation) IT service
            providers, data storage, web-hosting and server providers, debt
            collectors, maintenance or problem-solving providers, marketing or
            advertising providers, professional advisors and payment systems
            operators;
          </li>
          <li>
            courts, tribunals, regulatory authorities and law enforcement
            officers, as required by law, in connection with any actual or
            prospective legal proceedings, or in order to establish, exercise or
            defend our legal rights; and
          </li>
          <li>
            third parties to collect and process data, such as Google Analytics
            or other relevant businesses. This may include parties that store
            data outside of Australia including in in the U.S.A.
          </li>
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Where we disclose your personal information to third parties,
          including data processors, we will request or ensure that the third
          party handle your personal information in accordance with this Privacy
          Policy. The third party will only process your personal information in
          accordance with written instructions from us and we require that the
          third party either complies with the privacy shield principles set out
          in the GDPR or another mechanism set out by applicable EU & Swiss data
          protection laws for the transfer and processing of personal
          information. When we refer to ‘processing’ in this clause and this
          Privacy Policy in general, we mean any operation or set of operations
          which is performed on personal information, whether or not by
          automated means, such as collecting, recording, organising,
          structuring, storage, adaptation or alteration, retrieval,
          consultation, use, disclosure by transmission, dissemination or
          otherwise making available personal information. <br />
          <br />
          Please note that we use the following third parties to process your
          personal information: Amazon Web Services, U.S.A.
          <br /> <br />
          By providing us with personal information, you consent to the
          disclosure of your personal information to third parties who are
          outside Australia and, if you are a European Union (EU) citizen, to
          third parties that are outside the EU. Where the disclosure of your
          personal information is solely subject to Australian privacy laws (and
          not subject to the GDPR), you acknowledge that we are not required to
          ensure that those third parties comply with Australian privacy laws.
        </Typography>
      </Box>
      {/* Our responsibilities as a ‘processor’ under the GDPR */}
      <Box>
        <Typography
          sx={{
            mt: 3,
            fontWeight: 500,
            fontSize: 26,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Our responsibilities as a ‘processor’ under the GDPR
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Where we are a processor, we have contracts containing certain
          prescribed terms in our contracts with controllers, including:
        </Typography>

        {/* TODO: Replce ABN */}
        <Typography
          component="ul"
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            paddingLeft: 2,
            listStyleType: "disc", // Use 'disc' for bullet points
            lineHeight: 1.6, // Optional: adjust line height for better readability
          }}
        >
          <li>
            not to use a sub-processor without the prior written authorisation
            of the data controller;
          </li>
          <li>to co-operate with supervisory authorities;</li>
          <li>to ensure the security of its processing;</li>
          <li>to keep records of processing activities;</li>
          <li>
            to notify any personal data breaches to the data controller; and
          </li>
          <li>
            to employ a data protection officer and appoint (in writing) a
            representative within the European Union if required by the GDPR.
            (These are not required for the company at the present time).
          </li>
        </Typography>
      </Box>
      {/* Your rights and controlling your personal information */}
      <Box>
        <Typography
          sx={{
            mt: 3,
            fontWeight: 500,
            fontSize: 26,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Your rights and controlling your personal information
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Where we are a processor, we have contracts containing certain
          prescribed terms in our contracts with controllers, including:
        </Typography>

        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          <b>Information from third parties:</b> If we receive personal
          information about you from a third party, we will protect it as set
          out in this Privacy Policy. If you are a third party providing
          personal information about somebody else, you represent and warrant
          that you have such person’s consent to provide the personal
          information to us.
        </Typography>

        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          <b>Complaints:</b> If you believe that we have breached the Australian
          Privacy Principles or an article of the GDPR and wish to make a
          complaint, please contact us using the details below and provide us
          with full details of the alleged breach. We will promptly investigate
          your complaint and respond to you, in writing, setting out the outcome
          of our investigation and the steps we will take to deal with your
          complaint. You also have the right to contact the Office of the
          Australian Information Commissioner if you wish to make a complaint.
        </Typography>
      </Box>
      {/* Storage and security */}
      <Box>
        <Typography
          sx={{
            mt: 3,
            fontWeight: 500,
            fontSize: 26,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Storage and security
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          We are committed to ensuring that the personal information we collect
          is secure. In order to prevent unauthorised access or disclosure, we
          have put in place suitable physical, electronic and managerial
          procedures such as the pseudonymisation and encryption of personal
          information, to safeguard and secure personal information and protect
          it from misuse, interference, loss and unauthorised access,
          modification and disclosure.
        </Typography>

        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          We cannot guarantee the security of any information that is
          transmitted to or by us over the Internet. The transmission and
          exchange of information is carried out at your own risk. Although we
          take measures to safeguard against unauthorised disclosures of
          information, we cannot assure you that the personal information we
          collect will not be disclosed in a manner that is inconsistent with
          this Privacy Policy.
        </Typography>
      </Box>
      {/* Cookies and web beacons */}
      <Box>
        <Typography
          sx={{
            mt: 3,
            fontWeight: 500,
            fontSize: 26,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Cookies and web beacons
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          We may use cookies on our Site from time to time. Cookies are text
          files placed in your computer's browser to store your preferences.
          Cookies, by themselves, do not tell us your email address or other
          personally identifiable information. However, they do allow third
          parties, such as Google and Facebook, to cause our advertisements to
          appear on your social media and online media feeds as part of our
          retargeting campaigns. If and when you choose to provide our Site with
          personal information, this information may be linked to the data
          stored in the cookie.
        </Typography>

        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          We may use web beacons on our Site from time to time. Web beacons
          (also known as Clear GIFs) are small pieces of code placed on a web
          page to monitor the visitor’s behaviour and collect data about the
          visitor’s viewing of a web page. For example, web beacons can be used
          to count the users who visit a web page or to deliver a cookie to the
          browser of a visitor viewing that page.
        </Typography>

        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          We may use Google Analytics to collect and process data. To find out
          how Google uses data when you use third party websites or
          applications, please see{" "}
          <a
            href="https://www.google.com/policies/privacy/partners/"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer" // Security best practices
            style={{ color: "blue", textDecoration: "underline" }} // Custom styles for the link
          >
            https://www.google.com/policies/privacy/partners/
          </a>{" "}
          or any other URL Google may use from time to time.
        </Typography>

        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          For more information regarding cookies and web beacons, please visit{" "}
          <a
            href="https://elev.io/legal/cookies"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer" // Security best practices
            style={{ color: "blue", textDecoration: "underline" }} // Custom styles for the link
          >
            https://elev.io/legal/cookies
          </a>
        </Typography>
      </Box>
      {/* Links to other websites */}
      <Box>
        <Typography
          sx={{
            mt: 3,
            fontWeight: 500,
            fontSize: 26,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Links to other websites
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Our Site may contain links to other websites. We do not have any
          control over those websites and we are not responsible for the
          protection and privacy of any personal information which you provide
          whilst visiting those websites. Those websites are not governed by
          this Privacy Policy.
        </Typography>
      </Box>
      {/* Amendments */}
      <Box>
        <Typography
          sx={{
            mt: 3,
            fontWeight: 500,
            fontSize: 26,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Amendments
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          We may, at any time and at our discretion, vary this Privacy Policy.
          We will notify you if we amend this Privacy Policy, by contacting you
          through the contact details you have provided to us. Any amended
          Privacy Policy is effective once we notify you of the change.
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          <b>
            For any questions or notices, please contact our Privacy Officer at:
          </b>
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Project Pit ABN 37 310 353 796
        </Typography>

        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Email: support@project-pit.com
        </Typography>
      </Box>
    </MainCard>
  );
};

export default PrivacyPolicyPage;
