import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

const useDropdownStyles = (width) => {
  const theme = useTheme();

  return makeStyles(() => ({
    formControl: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: 8,
        backgroundColor: theme.palette.background.default,
      },
    },
    select: {
      width: width,
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.background.default,
    },
  }))();
};

export default useDropdownStyles;
