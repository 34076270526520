import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";

import MainCard from "ui-component/cards/MainCard";
import { useNavigate } from "react-router";
import { pagingApiRequest } from "Helper/ApiRequest";
import moment from "moment/moment";
import { handleGetStrength } from "Helper/Tokens";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import ForecastImport from "../analytics/components/ForecastImport";
import Bugsnag from "@bugsnag/js";

const Projects = () => {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const [lifecycleFilter, setLifecycleFilter] = useState([]);

  const [projects, setProjects] = useState([]);

  const navigate = useNavigate();

  // Used for Importing projects.
  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   if (canUserCreate()) {
  //     setOpen(true);
  //   } else {
  //     enqueueSnackbar("Error: You don't have permission to import projects.", {
  //       variant: "error",
  //     });
  //   }
  // };

  const handleNavigateProjectsCreate = () => {
    if (canUserCreate()) {
      handleNavigate(`/projects/create`);
    } else {
      enqueueSnackbar("Error: You don't have permission to create projects.", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [page, search, lifecycleFilter, statusFilter]);

  async function fetchProjects() {
    setProjects([]);

    // Prepare the params
    const searchParam = search ? `search=${encodeURIComponent(search)}` : "";
    const statusParam =
      statusFilter.length > 0
        ? `status=${encodeURIComponent(statusFilter.join(","))}`
        : "";
    const lifecycleParam =
      lifecycleFilter.length > 0
        ? `lifecycle=${encodeURIComponent(lifecycleFilter.join(","))}`
        : "";

    // Construct the query string
    const params = [searchParam, statusParam, lifecycleParam]
      .filter((param) => param)
      .join("&");
    const queryString = params ? `?${params}` : "";

    const response = await pagingApiRequest({
      api: "ListProjects",
      params: queryString,
      page,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    } else {
      setProjects(response);
    }
  }

  const handleChangeStatusFilter = (event) => {
    const {
      target: { value },
    } = event;
    setStatusFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeLifecycleFilter = (event) => {
    const {
      target: { value },
    } = event;
    setLifecycleFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onChangeHandler = (event) => {
    setSearch(event.target.value);
  };

  const unixToDate = (time) => {
    var day = moment.unix(time);
    return day.format("MMMM Do YYYY, h:mm a");
  };

  async function handleNavigate(route) {
    navigate(route);
  }

  useEffect(() => {
    if (open !== true) {
      fetchProjects();
    }
  }, [open]);

  const statusColours = {
    TBA: {
      colour: "#637381",
      backgroundColor: "rgba(145, 158, 171, 0.16)",
    },
    Monitor: {
      colour: "#B76E00",
      backgroundColor: "rgba(255, 171, 0, 0.16)",
    },
    "On Track": {
      colour: "#118D57",
      backgroundColor: "rgba(34, 197, 94, 0.16)",
    },
    "Action Required": {
      colour: "#B71D18",
      backgroundColor: "rgba(255, 86, 48, 0.16)",
    },
  };

  const canUserCreate = () => {
    return handleGetStrength() >= 4;
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [openImport, setOpenImport] = useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
  };

  return (
    <MainCard>
      {/* Import Modal */}
      <Modal open={openImport} onClose={handleCloseImport}>
        <Box sx={style}>
          <ForecastImport onClose={handleCloseImport} setOpen={setOpenImport} />
        </Box>
      </Modal>
      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
              onClick={handleOpenImport}
            >
              Projects
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Dashboard
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
                color="text.primary"
              >
                Projects
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box sx={{ flexShrink: 0 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                boxSizing: "border-box",
                cursor: "pointer",
                verticalAlign: "middle",
                backgroundColor: "#1C252E",
                textDecoration: "none",
                borderRadius: "8px",
                padding: "6px 12px",
                minWidth: "64px",
                boxShadow: "none",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                "&:hover": {
                  backgroundColor: "#2D3A45",
                  boxShadow: "none",
                },
              }}
              disabled={!canUserCreate()}
              onClick={handleNavigateProjectsCreate}
            >
              New project
            </Button>
          </Box>
        </Box>
      </Stack>
      {/* Content */}
      <Card
        sx={{
          color: "#1C252E",
          backgroundImage: "none",
          position: "relative",
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        {/* Table Toolbar */}
        <Grid
          container
          spacing={2}
          sx={{
            paddingY: "20px",
            alignItems: "center",
            paddingRight: "8px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={9}
            container
            spacing={2}
            direction={{ xs: "column", sm: "row" }} // Stack vertically on extra-small screens, horizontally on small and larger screens
            alignItems="stretch" // Ensure items stretch to fill the available space
          >
            <Grid item xs>
              <FormControl
                fullWidth
                sx={{
                  display: "inline-flex",
                  verticalAlign: "top",
                  flexDirection: "column",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                }}
              >
                <InputLabel
                  sx={{
                    "&.MuiInputLabel-root.Mui-focused": {
                      color: "#1C252E",
                      fontWeight: 600,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  label="Status"
                  fullWidth
                  multiple
                  value={statusFilter}
                  renderValue={(selected) => selected.join(", ")}
                  onChange={handleChangeStatusFilter}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ddd",
                      borderRadius: "8px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1C252E",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1C252E",
                    },
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 12px",
                      fontSize: "14px",
                      background: "white",
                      textAlign: "center",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                    "& .MuiSelect-icon": {
                      color: "#1C252E",
                    },
                  }}
                >
                  <MenuItem value={"TBA"}>
                    <Checkbox checked={statusFilter.indexOf("TBA") > -1} />
                    <ListItemText primary={"TBA"} />
                  </MenuItem>

                  <MenuItem value={"On Track"}>
                    <Checkbox checked={statusFilter.indexOf("On Track") > -1} />
                    <ListItemText primary={"On Track"} />
                  </MenuItem>

                  <MenuItem value={"Monitor"}>
                    <Checkbox checked={statusFilter.indexOf("Monitor") > -1} />
                    <ListItemText primary={"Monitor"} />
                  </MenuItem>

                  <MenuItem value={"Action Required"}>
                    <Checkbox
                      checked={statusFilter.indexOf("Action Required") > -1}
                    />
                    <ListItemText primary={"Action Required"} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl
                fullWidth
                sx={{
                  display: "inline-flex",
                  verticalAlign: "top",
                  flexDirection: "column",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                }}
              >
                <InputLabel
                  sx={{
                    "&.MuiInputLabel-root.Mui-focused": {
                      color: "#1C252E",
                      fontWeight: 600,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                  }}
                >
                  Lifecycle
                </InputLabel>
                <Select
                  label="Lifecycle"
                  fullWidth // Ensure the Select takes full width
                  multiple
                  value={lifecycleFilter}
                  renderValue={(selected) => selected.join(", ")}
                  onChange={handleChangeLifecycleFilter}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ddd",
                      borderRadius: "8px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1C252E",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1C252E",
                    },
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 12px",
                      fontSize: "14px",
                      background: "white",
                      textAlign: "center",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                    "& .MuiSelect-icon": {
                      color: "#1C252E",
                    },
                  }}
                >
                  <MenuItem value={"new"}>
                    <Checkbox checked={lifecycleFilter.indexOf("new") > -1} />
                    <ListItemText primary={"New"} />
                  </MenuItem>

                  <MenuItem value={"renewal"}>
                    <Checkbox
                      checked={lifecycleFilter.indexOf("renewal") > -1}
                    />
                    <ListItemText primary={"Renewal"} />
                  </MenuItem>

                  <MenuItem value={"upgrade"}>
                    <Checkbox
                      checked={lifecycleFilter.indexOf("upgrade") > -1}
                    />
                    <ListItemText primary={"Upgrade"} />
                  </MenuItem>

                  <MenuItem value={"disposal"}>
                    <Checkbox
                      checked={lifecycleFilter.indexOf("disposal") > -1}
                    />
                    <ListItemText primary={"Disposal"} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                fullWidth // Ensure the TextField takes full width
                placeholder="Search..."
                onChange={onChangeHandler}
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    height: "25px",
                    backgroundColor: "white",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "white",
                    height: "52px",
                    "& input": {
                      height: "100%", // Ensure the input takes the full height of the container
                    },
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#000",
                      borderRadius: "8px",
                    },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Active Search */}
        {((lifecycleFilter && lifecycleFilter.length > 0) ||
          (statusFilter && statusFilter.length > 0) ||
          (search && search.length > 0)) && (
          <Box sx={{ padding: "0px 0px 20px" }}>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexWrap: "wrap",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                {statusFilter && statusFilter.length > 0 && (
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      padding: "8px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "dashed 1px rgba(145, 158, 171, 0.2)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        lineHeight: "24px",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Status:
                    </Typography>
                    <Box
                      sx={{
                        gap: "8px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        endIcon={
                          <CancelIcon
                            sx={{
                              cursor: "pointer",
                              width: 16,
                              color: "rgba(28, 37, 46, 0.26)",
                              margin: "0px 4px 0px -4px",
                              mr: 1,
                            }}
                          />
                        }
                        onClick={() => {
                          setStatusFilter([]);
                        }}
                        sx={{
                          position: "relative",
                          userSelect: "none",
                          appearance: "none",
                          maxWidth: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "24px",
                          color: "#1C252E",
                          cursor: "unset",
                          verticalAlign: "middle",
                          boxSizing: "border-box",
                          backgroundColor: "rgba(145, 158, 171, 0.16)",
                          margin: "0px",
                          whiteSpace: "nowrap",
                          transition:
                            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                          outline: "0px",
                          textDecoration: "none",
                          borderWidth: "0px",
                          borderStyle: "initial",
                          borderColor: "initial",
                          borderImage: "initial",
                          padding: "0px",
                          borderRadius: "8px",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            px: "8px",
                            whiteSpace: "nowrap",
                            fontWeight: 500,
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {statusFilter.join(", ")}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
                {lifecycleFilter && lifecycleFilter.length > 0 && (
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      padding: "8px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "dashed 1px rgba(145, 158, 171, 0.2)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        lineHeight: "24px",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Lifecycle:
                    </Typography>
                    <Box
                      sx={{
                        gap: "8px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        endIcon={
                          <CancelIcon
                            sx={{
                              cursor: "pointer",
                              width: 16,
                              color: "rgba(28, 37, 46, 0.26)",
                              margin: "0px 4px 0px -4px",
                              mr: 1,
                            }}
                          />
                        }
                        onClick={() => {
                          setLifecycleFilter([]);
                        }}
                        sx={{
                          position: "relative",
                          userSelect: "none",
                          appearance: "none",
                          maxWidth: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "24px",
                          color: "#1C252E",
                          cursor: "unset",
                          verticalAlign: "middle",
                          boxSizing: "border-box",
                          backgroundColor: "rgba(145, 158, 171, 0.16)",
                          margin: "0px",
                          whiteSpace: "nowrap",
                          transition:
                            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                          outline: "0px",
                          textDecoration: "none",
                          borderWidth: "0px",
                          borderStyle: "initial",
                          borderColor: "initial",
                          borderImage: "initial",
                          padding: "0px",
                          borderRadius: "8px",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            px: "8px",
                            whiteSpace: "nowrap",
                            fontWeight: 500,
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {lifecycleFilter.join(", ")}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
                {search && search.length > 0 && (
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      padding: "8px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "dashed 1px rgba(145, 158, 171, 0.2)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        lineHeight: "24px",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Search:
                    </Typography>
                    <Box
                      sx={{
                        gap: "8px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        endIcon={
                          <CancelIcon
                            sx={{
                              cursor: "pointer",
                              width: 16,
                              color: "rgba(28, 37, 46, 0.26)",
                              margin: "0px 4px 0px -4px",
                              mr: 1,
                            }}
                          />
                        }
                        onClick={() => {
                          setSearch("");
                        }}
                        sx={{
                          position: "relative",
                          userSelect: "none",
                          appearance: "none",
                          maxWidth: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "24px",
                          color: "#1C252E",
                          cursor: "unset",
                          verticalAlign: "middle",
                          boxSizing: "border-box",
                          backgroundColor: "rgba(145, 158, 171, 0.16)",
                          margin: "0px",
                          whiteSpace: "nowrap",
                          transition:
                            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                          outline: "0px",
                          textDecoration: "none",
                          borderWidth: "0px",
                          borderStyle: "initial",
                          borderColor: "initial",
                          borderImage: "initial",
                          padding: "0px",
                          borderRadius: "8px",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            px: "8px",
                            whiteSpace: "nowrap",
                            fontWeight: 500,
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {search}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
                <Button
                  startIcon={<DeleteIcon />}
                  sx={{
                    color: "#FF5630",
                    fontWeight: 700,
                    minWidth: "64px",
                    fontSize: 14,
                    backgroundColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                  onClick={() => {
                    setSearch("");
                    setLifecycleFilter([]);
                    setStatusFilter([]);
                  }}
                >
                  Clear
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {/* Table */}
        <Box
          sx={{
            position: "relative",
            overflowX: "auto", // Enable horizontal scrolling
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  color: "#637381",
                  backgroundColor: "#F4F6F8",
                  outline: "0px",
                }}
              >
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Lifecycle
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Subprogram
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Created
                </TableCell>
              </TableRow>
            </TableHead>
            {projects.data && (
              <TableBody>
                {projects.data &&
                  projects.data.map((project) => (
                    <TableRow key={project._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottomStyle: "dashed",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        {project._id}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottomStyle: "dashed",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        <Link
                          href={`/projects/${project._id}`}
                          underline="hover"
                          color="inherit"
                        >
                          {project.title}
                        </Link>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottomStyle: "dashed",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 13,
                            height: "24px",
                            minWidth: "24px",
                            alignItems: "center",
                            lineHeight: 0,
                            fontWeight: 700,
                            padding: "0px 6px",
                            borderRadius: "6px",
                            color: statusColours[project.status].colour,
                            backgroundColor:
                              statusColours[project.status].backgroundColor,
                            whiteSpace: "nowrap",
                            justifyContent: "center",
                            display: "inline-flex",
                          }}
                        >
                          {project.status}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottomStyle: "dashed",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        {capitalizeFirstLetter(project.lifecycle)}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottomStyle: "dashed",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        {project.subprogram.title}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottomStyle: "dashed",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        {unixToDate(project.inserted)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </Box>
        {/* Pagination */}
        <Box
          sx={{
            position: "relative",
            alignItems: "flex-start",
          }}
        >
          {projects.settings && (
            <Pagination
              count={projects.settings.pages}
              page={page}
              onChange={handleChange}
              sx={{ mt: 4 }}
            />
          )}
        </Box>
      </Card>
    </MainCard>
  );
};

export default Projects;
