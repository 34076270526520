import { Box, Button, Card, Grid, Stack, TextField } from "@mui/material";
import "assets/scss/style.scss";
import { useState } from "react";
import { apiRequest } from "Helper/ApiRequest";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const ProfileSecurity = () => {
  const [{ currentPassword, newPassword, newPasswordConfirm }, setPasswords] =
    useState({
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    });

  async function handleUpdatePassword() {
    if (newPassword !== newPasswordConfirm) {
      enqueueSnackbar("Error: Passwords don't match!", { variant: "error" });
      return;
    }

    const changePayload = {
      password: currentPassword,
      newPassword: newPassword,
    };

    const response = await apiRequest({
      api: "ChangePassword",
      body: changePayload,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    enqueueSnackbar("Successfully changed password!", { variant: "success" });
    setPasswords((prev) => ({
      ...prev,
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    }));
  }

  const isActive = () => {
    if (
      currentPassword.length > 0 &&
      newPassword.length > 0 &&
      newPasswordConfirm.length > 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        flexFlow: "wrap",
        minWidth: "0px",
        display: "flex",
        boxSizing: "border-box",
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          flexGrow: 0,
          flexBasis: "auto",
          width: "calc(100% * 8 / 12)",
          pl: 0,
        }}
      >
        <Card
          sx={{
            backgroundColor: "white",
            color: "#1C252E",
            backgroundImage: "none",
            position: "relative",
            boxShadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            zIndex: 0,
            overflow: "hidden",
            borderRadius: "16px",
            padding: "24px",
          }}
        >
          <Stack>
            <TextField
              variant="outlined"
              fullWidth
              label="Current Password"
              value={currentPassword}
              onChange={(e) =>
                setPasswords({
                  currentPassword: e.target.value,
                  newPassword: newPassword,
                  newPasswordConfirm: newPasswordConfirm,
                })
              }
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                mb: 3,
                "& .MuiFormHelperText-root": {
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiInputBase-root": {
                  height: 52,
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            />
          </Stack>
          <Box
            sx={{
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "24px 16px",
              display: "grid",
            }}
          >
            {/* New Password Field */}
            <TextField
              variant="outlined"
              fullWidth
              label="New Password"
              value={newPassword}
              onChange={(e) =>
                setPasswords({
                  currentPassword: currentPassword,
                  newPassword: e.target.value,
                  newPasswordConfirm: newPasswordConfirm,
                })
              }
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiFormHelperText-root": {
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiInputBase-root": {
                  height: 52,
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            />
            {/* New Password Confirm Field */}
            <TextField
              variant="outlined"
              fullWidth
              label="New Password Confirm"
              value={newPasswordConfirm}
              onChange={(e) =>
                setPasswords({
                  currentPassword: currentPassword,
                  newPassword: newPassword,
                  newPasswordConfirm: e.target.value,
                })
              }
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiFormHelperText-root": {
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiInputBase-root": {
                  height: 52,
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            />
          </Box>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              alignItems: "flex-end",
              mt: "24px",
            }}
          >
            <Button
              sx={{
                color: "white",
                fontWeight: 700,
                fontSize: "0.875rem",
                minWidth: "64px",
                boxShadow: "none",
                padding: "6px 12px",
                borderRadius: "8px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                backgroundColor: "#1C252E",
                "&:hover": {
                  backgroundColor: "#2D3A45",
                  boxShadow: "none",
                },
                "&:disabled": {
                  backgroundColor: "#2D3A45",
                  color: "white",
                  boxShadow: "none",
                },
              }}
              disabled={!isActive()}
              onClick={handleUpdatePassword}
            >
              Update password
            </Button>
          </Stack>
        </Card>
      </Grid>
    </Grid>
    // <Card color="neutral" orientation="horizontal" size="lg" variant="outlined">
    //   <Typography className="account-title" variant="h3">
    //     {"Change Password"}
    //   </Typography>
    //   <Divider />
    //   <div className="p-4">
    //     {success && (
    //       <Alert severity="success">Updated Password Successfully!</Alert>
    //     )}
    //     {error && <Alert severity="error">{error}</Alert>}
    //     <TextField
    //       className="mt-4"
    //       fullWidth
    //       label="Current Password"
    //       value={currentPassword}
    //       onChange={(e) =>
    //         setPasswords({
    //           currentPassword: e.target.value,
    //           newPassword: newPassword,
    //           newPasswordConfirm: newPasswordConfirm,
    //         })
    //       }
    //       sx={{
    //         input: {
    //           color: theme.palette.grey[600],
    //           background: theme.palette.background.default,
    //         },
    //       }}
    //     />
    //     <div className="d-flex mt-4 justify-content-center gap-3">
    //       <TextField
    //         fullWidth
    //         label="New Password"
    //         value={newPassword}
    //         onChange={(e) =>
    //           setPasswords({
    //             currentPassword: currentPassword,
    //             newPassword: e.target.value,
    //             newPasswordConfirm: newPasswordConfirm,
    //           })
    //         }
    //         sx={{
    //           input: {
    //             color: theme.palette.grey[600],
    //             background: theme.palette.background.default,
    //           },
    //         }}
    //       />
    //       <TextField
    //         fullWidth
    //         label="Re-enter New Password"
    //         value={newPasswordConfirm}
    //         onChange={(e) =>
    //           setPasswords({
    //             currentPassword: currentPassword,
    //             newPassword: newPassword,
    //             newPasswordConfirm: e.target.value,
    //           })
    //         }
    //         sx={{
    //           input: {
    //             color: theme.palette.grey[600],
    //             background: theme.palette.background.default,
    //           },
    //         }}
    //       />
    //     </div>

    //     <Button
    //       className="mt-4"
    //       variant="contained"
    //       disabled={!isActive()}
    //       onClick={handleUpdatePassword}
    //     >
    //       Update
    //     </Button>
    //   </div>
    // </Card>
  );
};

export default ProfileSecurity;
