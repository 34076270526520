import { Box, Button, Chip, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import MainCard from "ui-component/cards/MainCard";

const InsufficientRolesPage = () => {
  let navigate = useNavigate();

  return (
    <MainCard>
      <Box
        sx={{
          display: "flex", // Enable flexbox
          flexDirection: "column", // Arrange children vertically
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          height: "100%", // Make the box full height (to center vertically)
        }}
      >
        <Chip
          size="small"
          label="401 error"
          color="primary"
          sx={{
            my: 2,
          }}
        />
        <Typography variant="h1">{"Unauthorized Access"}</Typography>
        <Typography sx={{ mb: 2 }}>
          {
            "Sorry, you don't have access to this page. Contact an Administrator"
          }
        </Typography>

        <Button variant="outlined" sx={{ marginTop: 2 }} onClick={() => {
            navigate('/')
        }}>
          Back Home
        </Button>
      </Box>
    </MainCard>
  );
};

export default InsufficientRolesPage;
