/* eslint-disable */
import { Box, Stack, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";

const TermsOfServicePage = () => {
  return (
    <MainCard>
      {/* Introduction */}
      <Box>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 28,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Terms of Use
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontStyle: "italic",
          }}
        >
          Effective starting August 28th, 2024
        </Typography>

        <Typography
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Please read this Agreement carefully and immediately cease using the
          Services if you do not agree to it.
        </Typography>

        {/* Acceptance */}
        <Typography
          sx={{
            mt: 2,
            fontWeight: 500,
            fontSize: 24,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Acceptance
        </Typography>

        <Typography
          component="ul"
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            paddingLeft: 2,
            listStyleType: "disc", // Use 'disc' for bullet points
            lineHeight: 1.6, // Optional: adjust line height for better readability
          }}
        >
          <li>
            These Terms and Conditions, and any other terms and policies
            referred to in these Terms and Conditions, form the agreement
            between Project Pit ABN 373 103 537 96 (referred to as “SaaS
            Provider”, “we” or “us”) and the user (referred to as “Customer” or
            “you”), collectively referred to as the Parties or each a Party,
            (Agreement).
          </li>
          <li>
            The SaaS Provider owns, or holds the relevant rights to, the Elevio
            Software and will license the use of the Software as a service to
            the Customer.
          </li>
          <li>
            The Customer wishes to license the SaaS Services available at
            https://project-pit.com (Site) from the SaaS Provider.
          </li>
          <li>
            This Agreement sets out the terms upon which the SaaS Provider has
            agreed to grant a license to the Customer to use the SaaS Services.
            This Agreement is binding on any use of the Services and applies to
            the Customer from the time that the SaaS Provider provides the
            Customer with an account (Customer’s account) to access and use the
            Services (Effective Date).
          </li>
          <li style={{ marginTop: 5 }}>
            By accessing and/or using the Services you:
          </li>
          <li style={{ marginLeft: 20, marginTop: 5 }}>
            warrant to us that you have reviewed this Agreement, including our
            Website Terms of Use (available on the Site) and our Privacy Policy
            (available on the Site), with your parent or legal guardian (if you
            are under 18 years of age), and you understand it;
          </li>
          <li style={{ marginLeft: 20, marginTop: 5 }}>
            warrant to us that you have the legal capacity to enter into a
            legally binding agreement with us or (if you are under 18 years of
            age) you have your parent’s or legal guardian’s permission to access
            and use the Site and they have agreed to the Terms on your behalf;
            and
          </li>
          <li style={{ marginLeft: 20, marginTop: 5 }}>
            agree to use the Services in accordance with this Agreement.
          </li>
          <li style={{ marginTop: 5 }}>
            You must not create a Customer account unless you are at least 18
            years of age. If you are a parent or legal guardian permitting a
            person who is at least 13 years of age but under 18 years of age (a
            Minor) create a Customer account and/or use the Site, you agree to:
            (i) supervise the Minor’s use of the Site and their account; (ii)
            assume all risks associated with, and liabilities resulting from,
            the Minor’s use of the Site and their Customer account; (iii) ensure
            that the content on the Site is suitable for the Minor; (iv) ensure
            all information submitted to us by the Minor is accurate; and (v)
            provide the consents, representations and warranties contained in
            the Terms on the Minor’s behalf.
          </li>
          <li>
            By using our Services and subscribing on our Site, you acknowledge
            that you have read, understood, and accepted this Agreement and you
            have the authority to act on behalf of any person or entity for whom
            you are using the Services, and you are deemed to have agreed to
            this Agreement on behalf of any entity for whom you use the
            Services.
          </li>
        </Typography>
        {/* Services */}
        <Typography
          sx={{
            mt: 2,
            fontWeight: 500,
            fontSize: 24,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Services
        </Typography>

        <Typography
          component="ul"
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            paddingLeft: 2,
            listStyleType: "disc", // Use 'disc' for bullet points
            lineHeight: 1.6, // Optional: adjust line height for better readability
          }}
        >
          <li>
            On or from the Effective Date and during the Term, the SaaS Provider
            agrees to provide the Services in accordance with the terms of this
            Agreement.
          </li>
          <li>
            The Customer agrees the SaaS Provider owns or holds the applicable
            licences to all Intellectual Property Rights including but not
            limited to copyright in the Software and SaaS Services and any
            documentation provided with the Services by the SaaS Provider to the
            Customer including any Customer configuration documentation.
          </li>
          <li>
            The SaaS Provider reserves the right to change or remove features of
            the SaaS Services from time to time. Where there is any material
            alteration to the SaaS Services in accordance with this clause, the
            SaaS Provider will provide the Customer with 20 Business Days’
            notice and the Customer agrees that any material alteration is at
            the SaaS Provider’s discretion.
          </li>
          <li>The Parties agree that the SaaS Provider:</li>
          <li style={{ marginLeft: 20, marginTop: 5 }}>
            will supply the Services on a non-exclusive basis;
          </li>
          <li style={{ marginLeft: 20, marginTop: 5 }}>
            does not make any warranty or representation as to the ability of
            the facilities or services of any third-party suppliers; and
          </li>
          <li style={{ marginLeft: 20, marginTop: 5 }}>
            is not liable for any failure in, fault with or degradation of the
            Services if that failure, fault or degradation is attributable to or
            caused by any failure of the Customer Environment or the facilities
            or services of any third party.
          </li>
          <li style={{ marginTop: 5 }}>
            The SaaS Provider reserves the right to refuse any request in
            relation to the Services that it deems inappropriate, unreasonable
            or illegal.
          </li>
        </Typography>
        {/* Service Licence */}
        <Typography
          sx={{
            mt: 2,
            fontWeight: 500,
            fontSize: 24,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Service Licence
        </Typography>

        <Typography
          component="ul"
          sx={{
            mt: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            paddingLeft: 2,
            listStyleType: "disc", // Use 'disc' for bullet points
            lineHeight: 1.6, // Optional: adjust line height for better readability
          }}
        >
          <li>
            In consideration for payment of the Fees, the SaaS Provider grants
            to the Customer a non-exclusive, non-transferable (except as
            otherwise permitted under this Agreement), personal, revocable,
            licence to access and use the SaaS Services in accordance with the
            Service Provider’s intended purpose for the SaaS Services (SaaS
            Licence).
          </li>
          <li>The Customer agrees that the SaaS Licence:</li>
          <li style={{ marginLeft: 20, marginTop: 5 }}>
            commences from the Effective Date or the day the Customer is granted
            access to the SaaS Services by the SaaS Provider, whichever occurs
            first;
          </li>
          <li style={{ marginLeft: 20, marginTop: 5 }}>
            permits the Customer to use the SaaS Services in accordance with the
            SaaS Services’ normal operating procedures; and
          </li>
          <li style={{ marginLeft: 20, marginTop: 5 }}>
            permits the Customer to provide access and use of the SaaS Services
            to Authorised Users by embedding the SaaS Services into Customer’s
            services to its customers, as applicable.
          </li>
        </Typography>
      </Box>
    </MainCard>
  );
};

export default TermsOfServicePage;
