// assets
import {
  IconSettings,
  IconBrandChrome,
  IconHelp,
  IconRobot,
} from "@tabler/icons";

// constant
const icons = {
  IconSettings,
  IconBrandChrome,
  IconHelp,
  IconRobot,
};

const pages = {
  id: "settings",
  title: "Settings",
  caption: "",
  type: "group",
  children: [
    {
      id: "settings",
      title: "Settings",
      type: "item",
      url: "/settings",
      icon: icons.IconSettings,
    },
    {
      id: "documentation",
      title: "Documentation",
      type: "item",
      url: "/documentation",
      target: true,
      icon: icons.IconHelp,
    },
  ],
};

export default pages;
