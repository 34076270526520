import { createTheme } from "@mui/material/styles";

// assets
import colors from "assets/scss/_themes-vars.module.scss";

// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: customization.navType === "dark" ? "#fff" : color.grey900,
    paper: customization.navType === "dark" ? "#151515" : color.paper,
    paperGrey: customization.navType === "dark" ? '#232323' : "#f7f7f7",
    backgroundDefault:
      customization.navType === "dark" ? "#151515" : color.paper,
    background:
      customization.navType === "dark" ? "#121212" : color.primaryLight,
    darkTextPrimary:
      customization.navType === "dark" ? "#E7E7E7" : color.grey700,
    darkTextSecondary:
      customization.navType === "dark"
        ? "rgba(255, 255, 255, 0.18)"
        : color.grey500,
    textDark: color.grey900,
    menuSelected:
      customization.navType === "dark"
        ? "rgba(255, 255, 255, 0.7)"
        : color.secondaryDark,
    menuSelectedBack:
      customization.navType === "dark"
        ? "rgba(231, 246, 231, 0.05)"
        : color.secondaryLight,
    divider:
      customization.navType === "dark"
        ? "rgba(255, 255, 255, 0.12)"
        : color.grey200,
    secondaryLight:
      customization.navType === "dark"
        ? "rgba(231, 246, 231, 0.05)"
        : color.secondaryLight,
    secondaryDark:
      customization.navType === "dark"
        ? "rgba(255, 255, 255, 0.7)"
        : color.secondaryDark,
    primaryLight:
      customization.navType === "dark"
        ? "rgba(255, 255, 255, 0.05)"
        : color.primaryLight,
    borderColour:
      customization.navType === "dark"
        ? "rgba(112, 112, 112, 1)"
        : "rgba(204, 204, 204, 1)",
    customization,
  };

  const themeOptions = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;
