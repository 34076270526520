import {
  FormGroup,
  TextField,
  Button,
  Box,
  Stepper,
  Typography,
  Step,
  StepLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Divider,
  Stack,
  ListItemText,
} from "@mui/material";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { apiRequest } from "../../../../Helper/ApiRequest";
import PropTypes from "prop-types";
import "assets/scss/user.scss";
import { Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const SubprogramCreate = ({ setOpen, program, edit, subprogramId }) => {
  let theme = useTheme();

  const [budgetItems, setBudgetItems] = useState([
    {
      amount: "",
      year: "",
    },
  ]);

  const steps = ["Subprogram", "Budget"];

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 2;
  const years = Array.from({ length: 15 }, (_, index) => {
    const year = startYear + index;
    return `${year}/${year + 1}`;
  });

  useEffect(() => {
    if (edit) {
      handleFetchSubprogram();
    }
  }, []);

  async function handleFetchSubprogram() {
    const response = await apiRequest({
      api: "GetSubProgram",
      params: `/${subprogramId}`,
    });
    setTitle(response.title);

    const budgetResponse = await apiRequest({
      api: "GetSubprogramBudget",
      params: `?subprogram=${subprogramId}`,
    });

    // Assuming budgetResponse is an array of budget items
    if (Array.isArray(budgetResponse)) {
      setBudgetItems(
        budgetResponse.map((budgetItem) => ({
          amount: budgetItem.cost.toString(), // Assuming cost is the amount
          year: budgetItem.year,
        }))
      );
    }
  }

  const isYearSelected = (year, currentIndex) => {
    return budgetItems.some(
      (item, index) => index !== currentIndex && item.year === year
    );
  };

  const handleBudgetYearChange = (index, event) => {
    // Update the year for the specified budget item
    const updatedBudgetItems = [...budgetItems];
    updatedBudgetItems[index].year = event.target.value;
    setBudgetItems(updatedBudgetItems);
  };

  const handleAddClick = () => {
    // Create a new budget item with the current year and an empty amount
    const newBudgetItem = { year: "", amount: "" };
    // Update the state with the new budget item
    setBudgetItems([...budgetItems, newBudgetItem]);
  };

  const handleAmountChange = (index, value) => {
    // Update the amount for the specified budget item
    const updatedBudgetItems = [...budgetItems];
    updatedBudgetItems[index].amount = value;
    // event.target.value;
    setBudgetItems(updatedBudgetItems);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [title, setTitle] = useState("");

  const handleCloseCreate = () => {
    setOpen(false);
  };

  const createSubprogram = async () => {
    if (edit) {
      updateSubprogram();
      return;
    }

    const payload = {
      title,
      program: program._id,
    };

    const response = await apiRequest({
      api: "CreateSubprogram",
      body: payload,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    const hasBudget = budgetItems.some((item) =>
      Object.values(item).some((value) => value && value.length > 0)
    );
    if (hasBudget) {
      for (let i = 0; i < budgetItems.length; i++) {
        const budget = budgetItems[i];
        const budgetPayload = {
          cost: Number(budget.amount),
          year: budget.year,
          subprogram: response._id,
        };
        const budgetResponse = await apiRequest({
          api: "CreateBudget",
          body: budgetPayload,
        });

        if (response.error || budgetResponse.error) {
          // Determine which error message to show
          const errorMessage = response.error || budgetResponse.error;

          // Show notification to user
          enqueueSnackbar(errorMessage, { variant: "error" });

          // Log error with Bugsnag
          Bugsnag.notify(new Error(errorMessage));
          return;
        } else {
          setOpen(false);
        }
      }
    } else {
      if (response.error) {
        enqueueSnackbar(response.error, { variant: "error" });

        // Log error with Bugsnag
        Bugsnag.notify(new Error(response.error));
        return;
      } else {
        setOpen(false);
      }
    }
    enqueueSnackbar(`Created Sub-Program!`, { variant: "success" });
  };

  const updateSubprogram = async () => {
    const budgetResponse = await apiRequest({
      api: "GetSubprogramBudget",
      params: `?subprogram=${subprogramId}`,
    });

    if (budgetResponse.error) {
      enqueueSnackbar(budgetResponse.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(budgetResponse.error));
      return;
    }

    const { foundItems, notFoundItems } = budgetItems.reduce(
      (result, item1) => {
        if (item1.year.length > 0) {
          // Check if budgetResponse is an array
          if (Array.isArray(budgetResponse)) {
            const matchingItem = budgetResponse.find(
              (item2) => item2.year === item1.year
            );

            const newItem = {
              year: item1.year,
              _id: matchingItem ? matchingItem._id : null,
              value: item1.amount,
            };

            if (matchingItem) {
              result.foundItems.push(newItem);
            } else {
              result.notFoundItems.push(newItem);
            }
          } else {
            const newItem = {
              year: item1.year,
              value: item1.amount,
            };
            result.notFoundItems.push(newItem);
          }
        }

        return result;
      },
      { foundItems: [], notFoundItems: [] }
    );

    for (let i = 0; i < foundItems.length; i++) {
      const founditem = foundItems[i];

      var updatePayload = {
        cost: Number(founditem.value),
      };

      const response = await apiRequest({
        api: "UpdateBudget",
        params: `/${founditem._id}`,
        body: updatePayload,
      });

      if (response.error) {
        enqueueSnackbar(response.error, { variant: "error" });

        // Bugsnag error
        Bugsnag.notify(new Error(response.error));
        return;
      }
    }

    for (let i = 0; i < notFoundItems.length; i++) {
      const notFoundItem = notFoundItems[i];

      var createPayload = {
        year: notFoundItem.year,
        cost: Number(notFoundItem.value),
        subprogram: subprogramId,
      };

      const response = await apiRequest({
        api: "CreateBudget",
        body: createPayload,
      });

      if (response.error) {
        enqueueSnackbar(response.error, { variant: "error" });

        // Bugsnag error
        Bugsnag.notify(new Error(response.error));
        return;
      }
    }

    const newPayload = {
      title,
    };

    const updateSubprogram = await apiRequest({
      api: "UpdateSubprogram",
      params: `/${subprogramId}`,
      body: newPayload,
    });

    if (updateSubprogram.error) {
      enqueueSnackbar(updateSubprogram.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(updateSubprogram.error));
      return;
    }

    enqueueSnackbar(`Updated Sub-Program!`, { variant: "success" });
    handleCloseCreate();
  };

  const viewState = () => {
    switch (activeStep) {
      case 0:
        return (
          <FormGroup sx={{ mt: 2, mb: 1 }}>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                my: 2,
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Program"
              disabled
              value={program.title}
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                my: 1,
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
              }}
            />
          </FormGroup>
        );
      case 1:
        return (
          <Box sx={{ mt: 4 }}>
            {budgetItems.map((item, index) => {
              const currentValue = item.amount;
              const formattedValue = formatNumberWithCommas(currentValue);
              return (
                <Stack
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      mt: 3,
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        gap: "16px",
                      }}
                    >
                      <FormControl
                        fullWidth
                        sx={{
                          display: "inline-flex",
                          verticalAlign: "top",
                          flexDirection: "column",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "8px",
                          },
                        }}
                      >
                        <InputLabel
                          sx={{
                            "&.MuiInputLabel-root.Mui-focused": {
                              color: "#1C252E",
                              fontWeight: 600,
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                          }}
                        >
                          Year
                        </InputLabel>
                        <Select
                          label="Year"
                          fullWidth
                          value={item.year}
                          onChange={(event) =>
                            handleBudgetYearChange(index, event)
                          }
                          sx={{
                            height: 50,
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#ddd",
                              borderRadius: "8px",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#1C252E",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#1C252E",
                            },
                            "& .MuiSelect-select": {
                              display: "flex",
                              alignItems: "center",
                              padding: "16px 12px",
                              fontSize: "14px",
                              background: "white",
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiSelect-icon": {
                              color: "#1C252E",
                            },
                          }}
                        >
                          {years.map((year) => (
                            <MenuItem
                              key={year}
                              value={year}
                              disabled={isYearSelected(year, index)}
                            >
                              <ListItemText primary={year} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Total */}
                      <FormControl fullWidth>
                        <InputLabel
                          sx={{
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            color: "#888", // Default color
                            "&.Mui-focused": {
                              color: "#000", // Color when focused
                            },
                          }}
                        >
                          Total
                        </InputLabel>
                        <OutlinedInput
                          type="text"
                          placeholder="0.00"
                          value={formattedValue}
                          onChange={(event) => {
                            const parsedValue = parseNumberFromString(
                              event.target.value
                            );
                            handleAmountChange(index, parsedValue);
                          }}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          label="Total"
                          sx={{
                            maxHeight: "50px",
                            background: "white",
                            input: {
                              backgroundColor: "white",
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiInputBase-root": {
                              backgroundColor: "white",
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#ddd",
                              borderRadius: "8px",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#000",
                              borderRadius: "8px",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#000",
                                borderRadius: "8px",
                              },
                            "& .MuiFormLabel-root": {
                              color: "#888", // Change this to the desired color
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "#000", // Change this to the desired color when focused
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#000",
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#000",
                              },
                          }}
                        />
                      </FormControl>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}

            <Divider
              sx={{
                mt: 4,
                borderWidth: "0px 0px thin",
                borderStyle: "dashed",
                borderColor: "rgba(145 158 171 / 0.2)",
              }}
            />

            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "24px",
                mt: 2,
              }}
            >
              <Button
                startIcon={<AddIcon />}
                sx={{
                  fontWeight: 700,
                  fontSize: 13,
                  minWidth: "64px",
                  height: "30px",
                  verticalAlign: "middle",
                  color: "#00A76F",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
                onClick={handleAddClick}
              >
                Add Year
              </Button>
            </Stack>
          </Box>
        );
    }
  };

  // Function to format number with commas
  const formatNumberWithCommas = (value) => {
    if (value === undefined || value === null || value === "") return "";
    return new Intl.NumberFormat().format(value);
  };

  // Function to parse the number removing commas
  const parseNumberFromString = (value) => {
    return parseFloat(value.replace(/,/g, "")) || 0;
  };

  return (
    <Box sx={{ width: 600 }}>
      <DialogTitle
        sx={{
          padding: "24px",
          fontWeight: 600,
          fontSize: 20,
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
      >{`${edit ? "Edit" : "Create"}`}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </Fragment>
          ) : (
            <Fragment>{viewState()}</Fragment>
          )}
        </Box>
      </DialogContent>
      <DialogActions className="mb-4 mx-3">
        <Button
          onClick={handleCloseCreate}
          autoFocus
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
            borderColor: "rgba(145, 158, 171, 0.32)",
            borderWidth: "1px",
            borderStyle: "solid",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />

        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          autoFocus
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
            borderColor: "rgba(145, 158, 171, 0.32)",
            borderWidth: "1px",
            borderStyle: "solid",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          Back
        </Button>

        <Button
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "#1C252E",
            color: "white",
            "&:hover": {
              backgroundColor: "#2D3A45",
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: "#2D3A45",
              color: "white",
              boxShadow: "none",
            },
          }}
          disabled={title && title.length > 0 ? false : true}
          onClick={() => {
            {
              activeStep === steps.length - 1
                ? createSubprogram()
                : handleNext();
            }
          }}
        >
          {activeStep === steps.length - 1
            ? edit
              ? "Update"
              : "Create"
            : "Next"}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default SubprogramCreate;

SubprogramCreate.propTypes = {
  open: PropTypes.any,
};
