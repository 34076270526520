import MainCard from "ui-component/cards/MainCard";
import { useEffect } from "react";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import { apiRequest, pagingApiRequest } from "Helper/ApiRequest";
import moment from "moment";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  IconButton,
  InputAdornment,
  Pagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import UserCreatePage from "./components/UserCreate";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UserEdit from "./components/UserEdit";
import AddIcon from "@mui/icons-material/Add";
import { getUserId, handleGetStrength } from "Helper/Tokens";
import { enqueueSnackbar } from "notistack";
import ReactGA from "react-ga4";
import Bugsnag from "@bugsnag/js";

const Users = () => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const [users, setUsers] = useState([]);

  const [open, setOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);

  const [search, setSearch] = useState("");

  const onChangeHandler = (event) => {
    setSearch(event.target.value);
  };

  const [editUserSelected, setEditUserSelected] = useState(null);
  const handleClickEditOpen = (id) => {
    setEditOpen(true);
    setEditUserSelected(id);
  };

  const handleEditClose = (e) => {
    e.preventDefault();
    setEditOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  useEffect(() => {
    fetchUsers();

    // Google Anayltics
    ReactGA.send({
      hitType: "pageview",
      page: "/users",
      title: "Users",
    });
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [page, search]);

  useEffect(() => {
    if (!open) {
      fetchUsers();
    }
  }, [open]);

  const deleteUser = async () => {
    for (let i = 0; i < selected.length; i++) {
      const response = await apiRequest({
        api: "DeleteUser",
        params: `/${selected[i]}`,
      });
      if (response.error) {
        enqueueSnackbar(response.error, { variant: "error" });

        // Bugsnag error
        Bugsnag.notify(new Error(response.error));
        return;
      }
    }
    setSelected([]);
    enqueueSnackbar("Successfully deleted user(s)", { variant: "success" });
    fetchUsers();
  };

  async function fetchUsers() {
    const response = await pagingApiRequest({
      api: "ListUsers",
      params: search.length > 0 ? `?search=${search}` : null,
      page,
    });
    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
    } else {
      setUsers(response);
    }
  }

  const unixToDate = (time) => {
    var day = moment.unix(time);
    return day.format("MMMM Do YYYY, h:mm a");
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const canEditUsers = () => {
    return handleGetStrength() >= 5;
  };

  const userId = getUserId();

  return (
    <MainCard title="Users">
      {/* Create user popup */}
      <React.Fragment>
        <Dialog open={open} onClose={handleClose}>
          <UserCreatePage setOpen={setOpen} />
        </Dialog>
      </React.Fragment>
      {editUserSelected && (
        <React.Fragment>
          <Dialog open={openEdit} onClose={handleEditClose}>
            <UserEdit setOpen={setEditOpen} id={editUserSelected} />
          </Dialog>
        </React.Fragment>
      )}
      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Users
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Dashboard
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
                color="text.primary"
              >
                Users
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box sx={{ flexShrink: 0 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              disabled={!canEditUsers()}
              onClick={handleClickOpen}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                boxSizing: "border-box",
                cursor: "pointer",
                verticalAlign: "middle",
                backgroundColor: "#1C252E",
                textDecoration: "none",
                borderRadius: "8px",
                padding: "6px 12px",
                minWidth: "64px",
                boxShadow: "none",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                "&:hover": {
                  backgroundColor: "#2D3A45",
                  boxShadow: "none",
                },
              }}
            >
              New user
            </Button>
          </Box>
        </Box>
      </Stack>
      {/* Content */}
      <Card
        sx={{
          color: "#1C252E",
          backgroundImage: "none",
          position: "relative",
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        {/* Table Toolbar */}
        <Stack
          sx={{
            display: "flex",
            paddingY: "20px",
            alignItems: "center",
            paddingRight: "8px",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignItems: "center",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Search..."
              onChange={onChangeHandler}
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
              }}
            />
            {/* <IconButton>
              <MoreVertIcon />
            </IconButton> */}
          </Stack>
        </Stack>
        {/* Active Search */}
        {search && search.length > 0 && (
          <Box sx={{ padding: "0px 0px 20px" }}>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexWrap: "wrap",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    gap: "8px",
                    display: "flex",
                    padding: "8px",
                    borderRadius: "8px",
                    overflow: "hidden",
                    border: "dashed 1px rgba(145, 158, 171, 0.2)",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      lineHeight: "24px",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Search:
                  </Typography>
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      endIcon={
                        <CancelIcon
                          sx={{
                            cursor: "pointer",
                            width: 16,
                            color: "rgba(28, 37, 46, 0.26)",
                            margin: "0px 4px 0px -4px",
                            mr: 1,
                          }}
                        />
                      }
                      onClick={() => {
                        setSearch("");
                      }}
                      sx={{
                        position: "relative",
                        userSelect: "none",
                        appearance: "none",
                        maxWidth: "100%",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "24px",
                        color: "#1C252E",
                        cursor: "unset",
                        verticalAlign: "middle",
                        boxSizing: "border-box",
                        backgroundColor: "rgba(145, 158, 171, 0.16)",
                        margin: "0px",
                        whiteSpace: "nowrap",
                        transition:
                          "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                        outline: "0px",
                        textDecoration: "none",
                        borderWidth: "0px",
                        borderStyle: "initial",
                        borderColor: "initial",
                        borderImage: "initial",
                        padding: "0px",
                        borderRadius: "8px",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          px: "8px",
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        {search}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                <Button
                  startIcon={<DeleteIcon />}
                  sx={{
                    color: "#FF5630",
                    fontWeight: 700,
                    minWidth: "64px",
                    fontSize: 14,
                    backgroundColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                  onClick={() => {
                    setSearch("");
                  }}
                >
                  Clear
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {/* Table */}
        <Box
          sx={{
            position: "relative",
            overflowX: "auto", // Enable horizontal scrolling
          }}
        >
          {/* Selection Overlay */}
          {selected.length > 0 && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                pl: "8px",
                pr: "16px",
                top: "0px",
                left: "0px",
                width: "100%",
                zIndex: 9,
                height: "58px",
                position: "absolute",
                backgroundColor: "#C8FAD6",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  margin: "0px 0px 0px 16px",
                  color: "#00A76F",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  flexGrow: 1,
                }}
              >
                {selected.length} Selected
              </Typography>
              <Tooltip title="Delete">
                <IconButton onClick={deleteUser}>
                  <DeleteIcon
                    sx={{ color: "#00A76F", width: "20px", height: "20px" }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  color: "#637381",
                  backgroundColor: "#F4F6F8",
                  outline: "0px",
                }}
              >
                <TableCell
                  sx={{
                    width: "48px",
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Roles
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Created
                </TableCell>

                <TableCell
                  sx={{
                    width: "48px",
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.data &&
                users.data.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderBottomStyle: "dashed",
                        width: "48px",
                        color: "#1C252E",
                        padding: "0px 0px 0px 8px",
                      }}
                    >
                      <Checkbox
                        color="primary"
                        disabled={user._id === userId}
                        checked={isSelected(user.id)}
                        onClick={(event) => handleClick(event, user.id)}
                        sx={{
                          color: "#637381",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {user._id}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      <Box
                        sx={{
                          flex: "1 1 auto",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          display: "flex",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              mb: 0.5,
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            }}
                          >
                            {capitalizeFirstLetter(user.firstName)}{" "}
                            {capitalizeFirstLetter(user.lastName)}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            color: "#919EAB",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {user.email}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {user.roles
                        .map((role) =>
                          // Capitalize each word in role.title
                          role.title
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")
                        )
                        .join(", ")}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {unixToDate(user.inserted)}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {user._id !== userId && (
                          <Tooltip title="Edit">
                            <IconButton
                              sx={{ color: "#637381" }}
                              disabled={!canEditUsers() || user._id === userId}
                              onClick={() => {
                                handleClickEditOpen(user._id);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {/* Pagination */}
        <Box
          sx={{
            position: "relative",
            alignItems: "flex-start",
          }}
        >
          {users.settings && (
            <Pagination
              count={users.settings.pages}
              page={page}
              onChange={handleChange}
              sx={{ mt: 4 }}
            />
          )}
        </Box>
      </Card>
    </MainCard>
  );
};

export default Users;
