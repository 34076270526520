import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";

import useScriptRef from "hooks/useScriptRef";
import AnimateButton from "ui-component/extended/AnimateButton";
import { useState } from "react";
import { apiRequest } from "Helper/ApiRequest";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const AuthResetPassword = ({ ...others }) => {
  const theme = useTheme();
  const scriptedRef = useScriptRef();

  const [isLoading, setIsLoading] = useState(false);

  const [emailAddress, setEmailAddress] = useState("");

  const onChangeHandler = (event) => {
    setEmailAddress(event.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    const payload = {
      email: emailAddress,
    };

    const response = await apiRequest({
      api: "RequestPasswordReset",
      body: payload,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      
      setIsLoading(false);
      return;
    }
    enqueueSnackbar(
      "If a user exists with that email address they have been emailed!",
      { variant: "success" }
    );
    setIsLoading(false);
  }

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, touched }) => (
          <form noValidate onSubmit={handleSubmit} {...others}>
            <FormControl
              fullWidth
              error={Boolean(touched.email && errors.email)}
              sx={{
                ...theme.typography.customInput,
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            >
              <InputLabel htmlFor="outlined-adornment-email-register">
                Email Address
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-register"
                type="email"
                value={emailAddress}
                name="email"
                onChange={onChangeHandler}
                inputProps={{}}
                sx={{
                  input: {
                    color: theme.palette.grey[600],
                    background: theme.palette.background.default,
                  },
                }}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isLoading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  height="55px"
                  sx={{
                    fontWeight: 700,
                    fontSize: 15,
                    color: "white",
                    minWidth: "64px",
                    boxShadow: "none",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    backgroundColor: "#1C252E",
                    "&:hover": {
                      backgroundColor: "#2D3A45",
                      boxShadow: "none",
                    },
                    "&:disabled": {
                      backgroundColor: "#2D3A45",
                      boxShadow: "none",
                      color: "white",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30px", // Ensure the Box fills the height of the Button
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress sx={{ p: 1 }} />
                    ) : (
                      <Typography p={0.5} fontWeight={500}>
                        Request Reset
                      </Typography>
                    )}
                  </Box>
                </Button>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthResetPassword;
