// TimelineItemComponent.js
import React from "react";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";
import moment from "moment";

const TimelineItemComponent = ({
  title,
  startDate,
  current,
  last,
}) => {
  const formattedStartDate = startDate
    ? moment(startDate).format("DD MMM YYYY")
    : "N/A";
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            boxShadow: "none",
            backgroundColor: current ? "#00A76F" : "rgba(145, 158, 171, 0.2)",
            "&:hover": {
              backgroundColor: current ? "#00A76F" : "rgba(145, 158, 171, 0.2)",
            },
          }}
        />
        {!last && (
          <TimelineConnector
            sx={{
              boxShadow: "none",
              backgroundColor: "rgba(145, 158, 171, 0.2)",
              "&:hover": {
                backgroundColor: "rgba(145, 158, 171, 0.2)",
              },
            }}
          />
        )}
      </TimelineSeparator>
      <TimelineContent>
        <Box>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 14,
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            }}
          >
            {title}
          </Typography>
          {startDate && (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 12,
                color: "#919EAB",
                mt: "4px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              {formattedStartDate}
            </Typography>
          )}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimelineItemComponent;
