import { Box, Button, Card, Grid, Stack, TextField } from "@mui/material";
import { apiRequest } from "Helper/ApiRequest";
import "assets/scss/style.scss";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const ProfileDetails = () => {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    _id: null,
    email: "",
    firstName: "",
    lastName: "",
  });
  const [initialProfile, setInitialProfile] = useState({
    _id: null,
    email: "",
    firstName: "",
    lastName: "",
  });

  async function handleUpdateUser() {
    setLoading(true);

    // Construct the payload with only changed values
    const payload = {};
    if (credentials.email !== initialProfile.email)
      payload.email = credentials.email;
    if (credentials.firstName !== initialProfile.firstName)
      payload.firstName = credentials.firstName;
    if (credentials.lastName !== initialProfile.lastName)
      payload.lastName = credentials.lastName;

    if (Object.keys(payload).length === 0) {
      // No changes to update
      setLoading(false);
      return;
    }

    try {
      const response = await apiRequest({
        api: "UpdateUser",
        params: `/${credentials._id}`,
        body: payload,
      });

      if (response.error) {
        enqueueSnackbar(response.error, { variant: "error" });

        // Bugsnag error
        Bugsnag.notify(new Error(response.error));
        return;
      }

      // Update the initial profile with the latest changes
      setInitialProfile({ ...credentials });

      enqueueSnackbar("Updated Profile!", { variant: "success" });
    } catch (error) {
      console.error("Error updating user:", error);
      enqueueSnackbar("An error occurred while updating the profile.", {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(error));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function getCurrentUser() {
      const response = await apiRequest({
        api: "GetProfile",
      });

      if (response.data) {
        setCredentials({
          _id: response.data._id,
          email: response.data.email,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
        });
        setInitialProfile({
          _id: response.data._id,
          email: response.data.email,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
        });
      }
    }

    getCurrentUser();
  }, []);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        flexFlow: "wrap",
        minWidth: "0px",
        display: "flex",
        boxSizing: "border-box",
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          flexGrow: 0,
          flexBasis: "auto",
          width: "calc(100% * 8 / 12)",
          pl: 0,
        }}
      >
        <Card
          sx={{
            backgroundColor: "white",
            color: "#1C252E",
            backgroundImage: "none",
            position: "relative",
            boxShadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            zIndex: 0,
            overflow: "hidden",
            borderRadius: "16px",
            padding: "24px",
          }}
        >
          <Box
            sx={{
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "24px 16px",
              display: "grid",
            }}
          >
            {/* First Name Field */}
            <TextField
              variant="outlined"
              fullWidth
              value={
                credentials.firstName
                  ? credentials.firstName.charAt(0).toUpperCase() +
                    credentials.firstName.slice(1)
                  : ""
              }
              onChange={(e) =>
                setCredentials((prev) => ({
                  ...prev,
                  firstName: e.target.value,
                }))
              }
              label="First name"
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={
                {
                  // Add your custom styles here
                }
              }
            />
            {/* Last Name Field */}
            <TextField
              variant="outlined"
              fullWidth
              value={
                credentials.lastName
                  ? credentials.lastName.charAt(0).toUpperCase() +
                    credentials.lastName.slice(1)
                  : ""
              }
              onChange={(e) =>
                setCredentials((prev) => ({
                  ...prev,
                  lastName: e.target.value,
                }))
              }
              label="Last name"
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={
                {
                  // Add your custom styles here
                }
              }
            />
          </Box>
          <Stack
            sx={{
              mt: 3,
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              value={credentials.email}
              onChange={(e) =>
                setCredentials((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
              label="Email Address"
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={
                {
                  // Add your custom styles here
                }
              }
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              alignItems: "flex-end",
              mt: "24px",
            }}
          >
            <Button
              sx={{
                color: "white",
                fontWeight: 700,
                fontSize: "0.875rem",
                minWidth: "64px",
                boxShadow: "none",
                padding: "6px 12px",
                borderRadius: "8px",
                backgroundColor: "#1C252E",
                "&:hover": {
                  backgroundColor: "#2D3A45",
                  boxShadow: "none",
                },
              }}
              onClick={handleUpdateUser}
              disabled={loading}
            >
              Save changes
            </Button>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProfileDetails;
