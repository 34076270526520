import {
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router";
import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fragment } from "react";

const ProjectFinancialsDetails = ({
  project,
  projectPriority,
  budgetTotal,
  view,
  handleChangeView,
  canUserCreate,
  forecasts,
  actuals,
}) => {
  let navigate = useNavigate();

  const [openRow, setOpenRow] = useState(null);

  const calculateTotal = (values) => {
    const totalActuals = values.reduce(
      (accumulator, item) => accumulator + item.cost,
      0
    );
    return totalActuals;
  };

  return (
    <Grid
      container
      direction="row"
      spacing={3}
      sx={{
        flexFlow: "wrap",
        minWidth: "0px",
        boxSizing: "border-box",
        display: "flex",
        margin: "calc(24px / -2) calc(24px / -2)",
      }}
    >
      <Grid
        item
        xs={12} // Full width on extra-small screens
        md={12} // 8 columns wide on medium screens and up
        sm={12} // 8 columns wide on medium screens and up
        sx={{
          flexGrow: 0,
          flexBasis: "auto",
          padding: "calc(24px / 2) calc(24px / 2)",
        }}
      >
        <Stack sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Card
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#1C252E",
              backgroundImage: "none",
              position: "relative",
              boxShadow:
                "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
              zIndex: 0,
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
              overflow: "hidden",
              borderRadius: "16px",
            }}
          >
            <Box
              sx={{
                padding: "24px 24px 0px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  flex: "1 1 auto",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 20,
                    display: "block",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Budget details
                </Typography>
              </Box>
            </Box>

            <Table sx={{ my: 3 }}>
              <TableHead>
                <TableRow
                  sx={{
                    color: "#637381",
                    backgroundColor: "#F4F6F8",
                    outline: "0px",
                  }}
                >
                  <TableCell
                    sx={{
                      fontWeight: 600,
                      borderBottomStyle: "dashed",
                      borderColor: "transparent",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Year
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 600,
                      borderBottomStyle: "dashed",
                      borderColor: "transparent",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Budget
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 600,
                      borderBottomStyle: "dashed",
                      borderColor: "transparent",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Reprovision
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 600,
                      borderBottomStyle: "dashed",
                      borderColor: "transparent",
                      minWidth: "64px",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Total
                  </TableCell>

                  <TableCell
                    sx={{
                      fontWeight: 600,
                      borderBottomStyle: "dashed",
                      borderColor: "transparent",
                      minWidth: "64px",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {project &&
                  projectPriority &&
                  projectPriority.length > 0 &&
                  projectPriority.map((item) => (
                    <Fragment key={item.year}>
                      {/* Default Row */}
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          sx={{
                            borderBottomStyle: "dashed",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {item.year}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          sx={{
                            borderBottomStyle: "dashed",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          $
                          {item.cost.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          sx={{
                            borderBottomStyle: "dashed",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          $
                          {item.reprovision
                            ? item.reprovision.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                              })
                            : 0}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          sx={{
                            borderBottomStyle: "dashed",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          $
                          {budgetTotal(item).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottomStyle: "dashed",
                          }}
                        >
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            disabled={!item.breakdown.length > 0}
                            onClick={() => {
                              if (openRow !== item.year) {
                                setOpenRow(item.year);
                              } else {
                                setOpenRow(null);
                              }
                            }}
                          >
                            {openRow === item.year ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      {/* Breakdown Row */}
                      {/* Collapsible Row */}
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{
                            padding: 0,
                            borderBottom: "none",
                          }}
                        >
                          <Collapse
                            in={openRow === item.year}
                            timeout="auto"
                            unmountOnExit
                          >
                            {item.breakdown && item.breakdown.length > 0 && (
                              <Stack
                                spacing={1}
                                sx={{
                                  mt: 1,
                                }}
                              >
                                {item.breakdown.map((breakdownItem, index) => (
                                  <Stack
                                    key={index}
                                    direction="row"
                                    spacing={2}
                                  >
                                    <TableCell
                                      sx={{
                                        borderBottom: "none",
                                        padding: "8px 16px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: 400,
                                          fontFamily:
                                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                        }}
                                      >
                                        {breakdownItem.source}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderBottom: "none",
                                        padding: "8px 16px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: 400,
                                          fontFamily:
                                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                        }}
                                      >
                                        $
                                        {breakdownItem.value.toLocaleString(0, {
                                          maximumFractionDigits: 2,
                                        })}
                                      </Typography>
                                    </TableCell>
                                  </Stack>
                                ))}
                              </Stack>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </Card>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12} // Full width on extra-small screens
        md={12} // 8 columns wide on medium screens and up
        sx={{
          flexGrow: 0,
          flexBasis: "auto",
          padding: "calc(24px / 2) calc(24px / 2)",
        }}
      >
        <Stack sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Card
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#1C252E",
              backgroundImage: "none",
              position: "relative",
              boxShadow:
                "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
              zIndex: 0,
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
              overflow: "hidden",
              borderRadius: "16px",
            }}
          >
            <Box
              sx={{
                padding: "24px 24px 0px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  flex: "1 1 auto",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 20,
                    display: "block",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Forecast / Actuals
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControl
                  sx={{
                    mr: 1.5,
                    display: "inline-flex",
                    verticalAlign: "top",
                    flexDirection: "column",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                    },
                    "& .MuiSelect-select": {
                      fontSize: "14px", // Decrease font size
                      padding: "8px 12px", // Adjust padding for smaller height
                      lineHeight: "1.5", // Adjust line height
                    },
                  }}
                >
                  <Select
                    value={view}
                    onChange={handleChangeView}
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1C252E",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1C252E",
                      },
                      "& .MuiSelect-icon": {
                        color: "#1C252E", // Change this to your preferred color for the dropdown indicator
                      },
                    }}
                  >
                    <MenuItem value={"forecast"}>Forecast</MenuItem>
                    <MenuItem value={"actuals"}>Actuals</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  disabled={!canUserCreate()}
                  onClick={() => {
                    if (view === "actuals") {
                      navigate("actuals");
                    } else {
                      navigate("forecast");
                    }
                  }}
                  sx={{
                    padding: "6px 12px",
                    backgroundColor: "#1C252E",
                    fontWeight: 700,
                    borderRadius: "8px",
                    minWidth: "64px",
                    boxShadow: "none",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    "&:hover": {
                      backgroundColor: "#2D3A45",
                      boxShadow: "none",
                    },
                  }}
                >
                  Update
                </Button>
              </Box>
            </Box>

            {/* Table Content */}
            <TableContainer>
              <Table sx={{ my: 3 }}>
                <TableHead>
                  <TableRow
                    sx={{
                      color: "#637381",
                      backgroundColor: "#F4F6F8",
                      outline: "0px",
                    }}
                  >
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Year
                    </TableCell>

                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Jul
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Aug
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Sep
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Oct
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Nov
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Dec
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Jan
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Feb
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Mar
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Apr
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      May
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Jun
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        borderBottomStyle: "dashed",
                        borderColor: "transparent",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {view === "actuals" ? (
                    <>
                      {actuals &&
                        Object.keys(actuals).map((year) => (
                          <TableRow key={year}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              sx={{
                                borderBottomStyle: "dashed",
                                fontFamily:
                                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                              }}
                            >
                              {year}
                            </TableCell>
                            {Object.entries(actuals[year].actuals).map(
                              ([key, value]) => (
                                <TableCell
                                  key={key}
                                  component="th"
                                  scope="row"
                                  align="left"
                                  sx={{
                                    borderBottomStyle: "dashed",
                                    fontFamily:
                                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                  }}
                                >
                                  $
                                  {value.cost.toLocaleString(0, {
                                    maximumFractionDigits: 2,
                                  })}
                                </TableCell>
                              )
                            )}
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              sx={{
                                borderBottomStyle: "dashed",
                                fontFamily:
                                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                              }}
                            >
                              $
                              {calculateTotal(
                                actuals[year].actuals
                              ).toLocaleString(0, {
                                maximumFractionDigits: 2,
                              })}
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <>
                      {forecasts &&
                        forecasts.map((forecast, index) => (
                          <TableRow key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              sx={{
                                borderBottomStyle: "dashed",
                                fontFamily:
                                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                              }}
                            >
                              {forecast.year}
                            </TableCell>
                            {forecast.forecasts.map((data, i) => (
                              <TableCell
                                key={i}
                                component="th"
                                scope="row"
                                align="left"
                                sx={{
                                  borderBottomStyle: "dashed",
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                }}
                              >
                                $
                                {data.cost.toLocaleString(0, {
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                            ))}
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              sx={{
                                borderBottomStyle: "dashed",
                                fontFamily:
                                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                              }}
                            >
                              $
                              {calculateTotal(
                                forecast.forecasts
                              ).toLocaleString(0, {
                                maximumFractionDigits: 2,
                              })}
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProjectFinancialsDetails;
