import Cookies from "universal-cookie";

const cookies = new Cookies();

export function handleSetTokens(access_token, refresh_token, userId, strength) {
  localStorage.setItem("refresh_token", refresh_token);
  localStorage.setItem("userId", userId);
  localStorage.setItem("strength", strength)
  cookies.set("access_token", access_token);
}

export async function handleGetAccessToken() {
  return cookies.get("access_token");
}

export function getUserId() {
  return localStorage.getItem("userId");
}

export function handleGetStrength() {
  return localStorage.getItem("strength");
}

export async function handleLogout() {
  cookies.remove("access_token");
  localStorage.clear()
}