import { Timeline } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import TimelineItemComponent from "../TimelineComponent";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment";

const ProjectInformationDetails = ({
  project,
  files,
  gateway,
  handleChangeGateway,
  notes,
  handleChangeNotes,
  handlePostComment
}) => {
  const hasLocationData =
    project &&
    (project.latitude ||
      project.longitude ||
      project.facility_name ||
      project.road_name ||
      project.address);

  const unixToDate = (time) => {
    var day = moment.unix(time);
    return day.format("MMMM Do YYYY, h:mm a");
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const gatewaysTitles = {
    notStarted: "Not Started",
    concept: "Concept & Feasibility",
    planning: "Planning & Design",
    procurement: "Procurement",
    delivery: "Delivery & Construction",
    finalisation: "Finalisation",
    finished: "Finished",
  };

  return (
    <Grid
      container
      direction="row"
      spacing={3}
      sx={{
        flexFlow: "wrap",
        minWidth: "0px",
        boxSizing: "border-box",
        display: "flex",
        margin: "calc(24px / -2) calc(24px / -2)",
      }}
    >
      <Grid
        item
        xs={12} // Full width on extra-small screens
        md={8} // 8 columns wide on medium screens and up
        sx={{
          flexGrow: 0,
          flexBasis: "auto",
          padding: "calc(24px / 2) calc(24px / 2)",
          width: "calc(100%* 8 / 12)",
        }}
      >
        <Stack sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <Card
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#1C252E",
              backgroundImage: "none",
              position: "relative",
              boxShadow:
                "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
              zIndex: 0,
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
              overflow: "hidden",
              borderRadius: "16px",
            }}
          >
            <Box
              sx={{
                padding: "24px 24px 0px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  flex: "1 1 auto",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: 20,
                    display: "block",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Details
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      mt: "24px",
                      mb: "8px",
                      fontSize: 16,
                      fontWeight: 600,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    ID
                  </Typography>
                  {project && (
                    <>
                      {project.existing_project_id ? (
                        <Typography
                          sx={{
                            mb: "8px",
                            fontSize: 14,
                            fontWeight: 400,
                            lineHeight: 1.5,
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {project.existing_project_id}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            mb: "8px",
                            fontSize: 14,
                            fontWeight: 400,
                            lineHeight: 1.5,
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {project._id}
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      mt: "24px",
                      mb: "8px",
                      fontSize: 16,
                      fontWeight: 600,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Project description
                  </Typography>
                  {project && project.details && (
                    <Typography
                      sx={{
                        mb: "8px",
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: 1.5,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {project.details}
                    </Typography>
                  )}
                </Box>

                {/* Start Date */}
                {project && project.startDate && (
                  <Box>
                    <Typography
                      sx={{
                        mt: "24px",
                        mb: "8px",
                        fontSize: 16,
                        fontWeight: 600,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Start Date
                    </Typography>
                    <Typography
                      sx={{
                        mb: "8px",
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: 1.5,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {moment
                        .unix(project.startDate / 1000)
                        .format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                )}
                {/* End Date */}
                {project && project.endDate && (
                  <Box>
                    <Typography
                      sx={{
                        mt: "24px",
                        mb: "8px",
                        fontSize: 16,
                        fontWeight: 600,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      End Date
                    </Typography>
                    <Typography
                      sx={{
                        mb: "8px",
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: 1.5,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {moment.unix(project.endDate / 1000).format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography
                    sx={{
                      mt: "24px",
                      mb: "8px",
                      fontSize: 16,
                      fontWeight: 600,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Last Updated
                  </Typography>
                  {project && project.updated && (
                    <Typography
                      sx={{
                        mb: "8px",
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: 1.5,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {unixToDate(project.updated)}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      mt: "24px",
                      mb: "8px",
                      fontSize: 16,
                      fontWeight: 600,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Created
                  </Typography>
                  {project && project.inserted && (
                    <Typography
                      sx={{
                        mb: 3,
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: 1.5,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {unixToDate(project.inserted)}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#1C252E",
              backgroundImage: "none",
              position: "relative",
              boxShadow:
                "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
              zIndex: 0,
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
              overflow: "hidden",
              borderRadius: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "24px 24px 0px",
              }}
            >
              <Box sx={{ flex: "1 1 auto" }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 20,
                    margin: 0,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Gateways
                </Typography>
              </Box>
              {project && (
                <FormControl
                  sx={{
                    display: "inline-flex",
                    verticalAlign: "top",
                    flexDirection: "column",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                    },
                    "& .MuiSelect-select": {
                      fontSize: "14px", // Decrease font size
                      padding: "8px 12px", // Adjust padding for smaller height
                      lineHeight: "1.5", // Adjust line height
                    },
                  }}
                >
                  <Select
                    value={gateway}
                    onChange={handleChangeGateway}
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1C252E",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1C252E",
                      },
                      "& .MuiSelect-icon": {
                        color: "#1C252E", // Change this to your preferred color for the dropdown indicator
                      },
                    }}
                  >
                    <MenuItem value={"notStarted"}>Not Started</MenuItem>
                    <MenuItem value={"concept"}>Concept & Feasibility</MenuItem>
                    <MenuItem value={"planning"}>Planning & Design</MenuItem>
                    <MenuItem value={"procurement"}>Procurement</MenuItem>
                    <MenuItem value={"delivery"}>
                      Delivery & Construction
                    </MenuItem>
                    <MenuItem value={"finalisation"}>Finalisation</MenuItem>
                    <MenuItem value={"finished"}>Finished</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>
            <Stack
              sx={{
                display: "flex",
                padding: "24px 0px 0px 10px",
                alignItems: "flex-start",
                flexDirection: "row",
                gap: "24px",
              }}
            >
              {project && (
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      display: "none", // Hide the default line if you want custom styling
                    },
                  }}
                >
                  <TimelineItemComponent
                    title="Not Started"
                    current={
                      project.currentGateway === null ||
                      project.currentGateway === "notStarted"
                    }
                  />
                  <TimelineItemComponent
                    title="Concept & Feasibility"
                    startDate={project.gateways.concept.start}
                    endDate={project.gateways.concept.end}
                    current={project.currentGateway === "concept"}
                  />
                  <TimelineItemComponent
                    title="Planning & Design"
                    startDate={project.gateways.planning.start}
                    endDate={project.gateways.planning.end}
                    current={project.currentGateway === "planning"}
                  />
                  <TimelineItemComponent
                    title="Procurement"
                    startDate={project.gateways.procurement.start}
                    endDate={project.gateways.procurement.end}
                    current={project.currentGateway === "procurement"}
                  />
                  <TimelineItemComponent
                    title="Delivery & Construction"
                    startDate={project.gateways.delivery.start}
                    endDate={project.gateways.delivery.end}
                    current={project.currentGateway === "delivery"}
                  />
                  <TimelineItemComponent
                    title="Finalisation"
                    startDate={project.gateways.concept.start}
                    endDate={project.gateways.concept.end}
                    current={project.currentGateway === "finalisation"}
                  />
                  <TimelineItemComponent
                    title="Finished"
                    last={true}
                    current={project.currentGateway === "finished"}
                  />
                </Timeline>
              )}
            </Stack>
          </Card>
        </Stack>
      </Grid>

      <Grid
        item
        xs={12} // Full width on extra-small screens
        md={4} // 4 columns wide on medium screens and up
      >
        <Card
          sx={{
            backgroundImage: "none",
            position: "relative",
            boxShadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            zIndex: 0,
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            overflow: "hidden",
            borderRadius: "16px",
          }}
        >
          {/* Project Additional details */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "24px 24px 0px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 20,
                display: "block",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Additional details
            </Typography>
          </Box>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "24px",
              gap: "14px",
            }}
          >
            {/* Lifecycle */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#637381",
                  width: "120px",
                  fontSize: 14,
                  flexShrink: 0,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Lifecycle
              </Typography>
              {project && project.lifecycle && (
                <Typography
                  sx={{
                    width: "120px",
                    fontSize: 14,
                    flexShrink: 0,
                    flex: 1,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {capitalizeFirstLetter(project.lifecycle)}
                </Typography>
              )}
            </Stack>

            {/* Subprogram */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#637381",
                  width: "120px",
                  fontSize: 14,
                  flexShrink: 0,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Sub-Program
              </Typography>
              {project && project.subprogram ? (
                <Typography
                  sx={{
                    width: "120px",
                    fontSize: 14,
                    flexShrink: 0,
                    flex: 1,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {project.subprogram.title}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    width: "120px",
                    fontSize: 14,
                    flexShrink: 0,
                    flex: 1,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  None
                </Typography>
              )}
            </Stack>

            {/* Gateway */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#637381",
                  width: "120px",
                  fontSize: 14,
                  flexShrink: 0,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Gateway
              </Typography>
              {project && (
                <Typography
                  sx={{
                    width: "120px",
                    fontSize: 14,
                    flexShrink: 0,
                    flex: 1,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {project.currentGateway
                    ? gatewaysTitles[project.currentGateway]
                    : "Not Started"}
                </Typography>
              )}
            </Stack>

            {/* Department */}
            {project && project.department && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#637381",
                    width: "120px",
                    fontSize: 14,
                    flexShrink: 0,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Department
                </Typography>
                <Typography
                  sx={{
                    width: "120px",
                    fontSize: 14,
                    flexShrink: 0,
                    flex: 1,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {project.department.title}
                </Typography>
              </Stack>
            )}

            {/* Business Unit */}
            {project && project.businessUnit && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#637381",
                    width: "120px",
                    fontSize: 14,
                    flexShrink: 0,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Business Unit
                </Typography>
                <Typography
                  sx={{
                    width: "120px",
                    fontSize: 14,
                    flexShrink: 0,
                    flex: 1,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {project.businessUnit.title}
                </Typography>
              </Stack>
            )}

            {/* Branch */}
            {project && project.branch && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#637381",
                    width: "120px",
                    fontSize: 14,
                    flexShrink: 0,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Branch
                </Typography>
                <Typography
                  sx={{
                    width: "120px",
                    fontSize: 14,
                    flexShrink: 0,
                    flex: 1,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {project.branch.title}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Divider
            sx={{
              borderWidth: "0px 0px thin",
              borderColor: "rgba(145, 158, 171, 0.2)",
              borderStyle: "dashed",
            }}
          />

          {/* Project Location */}
          {hasLocationData && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "24px 24px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: 20,
                    display: "block",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Location
                </Typography>
              </Box>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "24px",
                  gap: "14px",
                }}
              >
                {project && project.facility_name && (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#637381",
                        width: "120px",
                        fontSize: 14,
                        flexShrink: 0,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Facility Name
                    </Typography>
                    <Typography
                      sx={{
                        width: "120px",
                        fontSize: 14,
                        flexShrink: 0,
                        flex: 1,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {project.facility_name}
                    </Typography>
                  </Stack>
                )}
                {project && project.road_name && (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#637381",
                        width: "120px",
                        fontSize: 14,
                        flexShrink: 0,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Road Name
                    </Typography>
                    <Typography
                      sx={{
                        width: "120px",
                        fontSize: 14,
                        flexShrink: 0,
                        flex: 1,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {project.road_name}
                    </Typography>
                  </Stack>
                )}
                {project && project.address && (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#637381",
                        width: "120px",
                        fontSize: 14,
                        flexShrink: 0,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Address
                    </Typography>
                    <Typography
                      sx={{
                        width: "120px",
                        fontSize: 14,
                        flexShrink: 0,
                        flex: 1,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {project.address}
                    </Typography>
                  </Stack>
                )}

                {project && project.latitude && project.longitude && (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#637381",
                        width: "120px",
                        fontSize: 14,
                        flexShrink: 0,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Coordinates
                    </Typography>
                    <Typography
                      sx={{
                        width: "120px",
                        fontSize: 14,
                        flexShrink: 0,
                        flex: 1,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {project.latitude}, {project.longitude}
                    </Typography>
                  </Stack>
                )}
              </Stack>

              <Divider
                sx={{
                  borderWidth: "0px 0px thin",
                  borderColor: "rgba(145, 158, 171, 0.2)",
                  borderStyle: "dashed",
                }}
              />
            </>
          )}

          {/* Project Files */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "24px 24px 0px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 20,
                display: "block",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Files
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 24px 0px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 14,
                display: "block",
                color: "#637381",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Project has {files.length} files
            </Typography>
          </Box>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "20px 24px",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                alignItems: "flex-start",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                fontWeight: 400,
                fontSize: 17,
                lineHeight: 1.5,
                width: "100%",
              }}
            >
              {files &&
                files.map((file) => (
                  <Stack
                    key={file.id}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%", // Ensure it takes the full width of the parent
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 13,
                          maxWidth: "300px", // Adjust width as needed
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        {file.id}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 12,
                          maxWidth: "300px", // Adjust width as needed
                          color: "#637381",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {file.url}
                      </Typography>
                    </Box>
                    <Tooltip title="Download">
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(file.url, "_blank");
                        }}
                      >
                        <FileDownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ))}
            </Stack>
          </Stack>
          <Divider
            sx={{
              borderWidth: "0px 0px thin",
              borderColor: "rgba(145, 158, 171, 0.2)",
              borderStyle: "dashed",
            }}
          />

          {/* Project Owner */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "24px 24px 0px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 20,
                display: "block",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Owner
            </Typography>
          </Box>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "20px 24px",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                alignItems: "flex-start",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                fontWeight: 400,
                fontSize: 17,
                lineHeight: 1.5,
              }}
            >
              {project && project.owner && (
                <Typography
                  sx={{
                    fontWeight: 600,
                    lineHeight: 1.5,
                    fontSize: 16,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {capitalizeFirstLetter(project.owner.firstName)}{" "}
                  {capitalizeFirstLetter(project.owner.lastName)}
                </Typography>
              )}
              {project && project.owner && (
                <Typography
                  sx={{
                    color: "#637381",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    fontSize: 15,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {project.owner.email}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Divider
            sx={{
              borderWidth: "0px 0px thin",
              borderColor: "rgba(145, 158, 171, 0.2)",
              borderStyle: "dashed",
            }}
          />

          {/* Project Owner */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "24px 24px 0px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 20,
                display: "block",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Comments
            </Typography>
          </Box>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "20px 24px",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                alignItems: "flex-start",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                fontWeight: 400,
                fontSize: 17,
                lineHeight: 1.5,
                width: "100%",
              }}
            >
              {/* Notes Field */}
              {project && (
                <>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Add comments"
                    multiline
                    value={notes}
                    onChange={handleChangeNotes}
                    minRows={5} // Set the minimum number of lines
                    maxRows={5} // Set the maximum number of lines (optional)
                    inputProps={{
                      style: {
                        backgroundColor: "white",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                  />
                  {/* Add comment button */}

                  <Button
                    sx={{
                      mt: 1,
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      boxSizing: "border-box",
                      cursor: "pointer",
                      userSelect: "none",
                      verticalAlign: "center",
                      fontWeight: 700,
                      fontSize: "0.8125rem",
                      minWidth: "50px",
                      height: "30px",
                      color: "#FFFFFF",
                      padding: "2px 4px",
                      backgroundColor: "#1C252E",
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#2D3A45",
                        boxShadow: "none",
                      },
                      "& .MuiLoadingButton-loadingIndicator": {
                        color: "white",
                      },
                    }}
                    onClick={handlePostComment}
                  >
                    Post
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
          <Divider
            sx={{
              borderWidth: "0px 0px thin",
              borderColor: "rgba(145, 158, 171, 0.2)",
              borderStyle: "dashed",
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProjectInformationDetails;
