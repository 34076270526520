import { handleGetAccessToken } from "./Tokens";

/** Util type-checking for API error responses */
function isApiError(output) {
  if (output.error) {
    return true;
  }
  return false;
}

// Define the custom ApiError class
class ApiError extends Error {
  constructor(message, statusCode) {
    super(message);
    this.name = "ApiError";
    this.statusCode = statusCode;
  }
}

const BASE_URL =
  process.env.REACT_APP_NODE_ENV === "development"
    ? "http://localhost:7070/"
    : "https://project-pit-backend-goldcoast-2ngydgfk3q-ts.a.run.app/";

/** Defines the mapping from API friendly-names to the actual request details. */
const Info = {
  Login: {
    method: "POST",
    route: "api/v1/users/login",
    authenticated: false,
    responseValidator(obj) {
      return (
        typeof obj === "object" &&
        obj !== null &&
        typeof obj.accessToken === "string" &&
        typeof obj.refreshToken === "string"
      );
    },
  },
  DeleteUser: {
    method: "DELETE",
    route: "api/v1/users",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListUsers: {
    method: "GET",
    route: "api/v1/users",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetProfile: {
    method: "GET",
    route: "api/v1/users/profile",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  RefreshToken: {
    method: "POST",
    route: "api/v1/users/token",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  UpdateUser: {
    method: "PUT",
    route: "api/v1/users",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateUser: {
    method: "POST",
    route: "api/v1/users/register",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  RequestPasswordReset: {
    method: "POST",
    route: "api/v1/users/request_reset_password",
    authenticated: false,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ChangePasswordReset: {
    method: "POST",
    route: "api/v1/users/reset_password",
    authenticated: false,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ChangePassword: {
    method: "POST",
    route: "api/v1/users/change_password",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ContactPublic: {
    method: "POST",
    route: "api/v1/public/contact",
    authenticated: false,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateProgram: {
    method: "POST",
    route: "api/v1/program",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  UpdateProgram: {
    method: "PUT",
    route: "api/v1/program",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListPrograms: {
    method: "GET",
    route: "api/v1/program",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  DeleteProgram: {
    method: "DELETE",
    route: "api/v1/program",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListSubPrograms: {
    method: "GET",
    route: "api/v1/subprogram/list",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetSubPrograms: {
    method: "GET",
    route: "api/v1/subprogram",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetSubProgramImport: {
    method: "GET",
    route: "api/v1/subprogram/import-name/name/",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetSubProgram: {
    method: "GET",
    route: "api/v1/subprogram/",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateSubprogram: {
    method: "POST",
    route: "api/v1/subprogram",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  UpdateSubprogram: {
    method: "PUT",
    route: "api/v1/subprogram",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  DeleteSubprogram: {
    method: "DELETE",
    route: "api/v1/subprogram",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListNonPrioritised: {
    method: "GET",
    route: "api/v1/project-priority",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListPrioritiedProjects: {
    method: "GET",
    route: "api/v1/project/prioritised/projects",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetPrioritiedProjects: {
    method: "GET",
    route: "api/v1/project-priority/project",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateProjectPriority: {
    method: "POST",
    route: "api/v1/project-priority",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  UpdateProjectPriority: {
    method: "PUT",
    route: "api/v1/project-priority",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  DeleteProjectPriority: {
    method: "DELETE",
    route: "api/v1/project-priority",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListProjects: {
    method: "GET",
    route: "api/v1/project",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListProjectsLocations: {
    method: "GET",
    route: "api/v1/project/list/locations",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListProjectsDate: {
    method: "GET",
    route: "api/v1/project/dates/list",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListProjectsGantt: {
    method: "GET",
    route: "api/v1/project/gantt/list",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListSubprogramProjects: {
    method: "GET",
    route: "api/v1/project/subprogram/list",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  DeleteProjects: {
    method: "DELETE",
    route: "api/v1/project",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateProjects: {
    method: "POST",
    route: "api/v1/project",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  UpdateProject: {
    method: "PUT",
    route: "api/v1/project",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListComments: {
    method: "GET",
    route: "api/v1/comment",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateComment: {
    method: "POST",
    route: "api/v1/comment",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  DeleteComment: {
    method: "DELETE",
    route: "api/v1/comment",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListActuals: {
    method: "GET",
    route: "api/v1/actual",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateActuals: {
    method: "POST",
    route: "api/v1/actual",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  UpdateActuals: {
    method: "PUT",
    route: "api/v1/actual",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  DeleteActuals: {
    method: "DELETE",
    route: "api/v1/actual",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListForecasts: {
    method: "GET",
    route: "api/v1/forecast",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateForecasts: {
    method: "POST",
    route: "api/v1/forecast",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  UpdateForecasts: {
    method: "PUT",
    route: "api/v1/forecast",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  DeleteForecasts: {
    method: "DELETE",
    route: "api/v1/forecast",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetAnalytics: {
    method: "GET",
    route: "api/v1/analytics",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetStatistics: {
    method: "GET",
    route: "api/v1/statistics",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetProgramStatistics: {
    method: "GET",
    route: "api/v1/statistics/programs",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetProgramAnalytics: {
    method: "GET",
    route: "api/v1/program/analytics",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListBusinessUnit: {
    method: "GET",
    route: "api/v1/businessUnit",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateBusinessUnit: {
    method: "POST",
    route: "api/v1/businessUnit",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListDepartments: {
    method: "GET",
    route: "api/v1/departments",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateDepartments: {
    method: "POST",
    route: "api/v1/departments",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListBranch: {
    method: "GET",
    route: "api/v1/branch",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateBranch: {
    method: "POST",
    route: "api/v1/branch",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListPriorities: {
    method: "GET",
    route: "api/v1/priority",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreatePriorities: {
    method: "POST",
    route: "api/v1/priority",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetBudget: {
    method: "GET",
    route: "api/v1/budget/year",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  UpdateBudget: {
    method: "PUT",
    route: "api/v1/budget",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetProjectBudgets: {
    method: "GET",
    route: "api/v1/budget/project/budget",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  DeleteBudget: {
    method: "DELETE",
    route: "api/v1/budget",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateBudget: {
    method: "POST",
    route: "api/v1/budget",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetSubprogramBudget: {
    method: "GET",
    route: "api/v1/budget/subprogram/budget",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  DeleteSubprogramBudget: {
    method: "DELETE",
    route: "api/v1/budget/subprogram/budget",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  ListDocumentation: {
    method: "GET",
    route: "api/v1/documentation",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetLogo: {
    method: "GET",
    route: "api/v1/globals/logo",
    authenticated: false,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  UploadFiles: {
    method: "POST",
    route: "api/v1/project/upload",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetFile: {
    method: "GET",
    route: "api/v1/project/list/files",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetSettings: {
    method: "GET",
    route: "api/v1/settings",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  UpdateSettings: {
    method: "PUT",
    route: "api/v1/settings",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetGantt: {
    method: "GET",
    route: "api/v1/gantt",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  CreateLayouts: {
    method: "POST",
    route: "api/v1/layout",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
  GetLayouts: {
    method: "GET",
    route: "api/v1/layout",
    authenticated: true,
    responseValidator(obj) {
      return typeof obj === "object" && obj !== null;
    },
  },
};

export async function uploadFormData({ api, params, formData }) {
  try {
    const { method, route } = Info[api];

    const headers = {};
    const accessToken = await handleGetAccessToken();
    const bearer = accessToken;
    headers.Authorization = `Bearer ${await bearer}`;

    let url = `${BASE_URL}${route}`;
    if (params) {
      url += `${params}`;
    }

    const res = await fetch(url, {
      method,
      headers,
      body: formData,
    });
    const output = await res.json();

    if (res.status >= 400) {
      throw new TypeError(`Unknown Error ${res.status}: ${res.statusText}`);
    }

    return output;
  } catch (e) {
    return { error: e ? e.message : "" };
  }
}

/** The actual generic request function */
export async function apiRequest({ api, params, body }) {
  try {
    const { method, route, authenticated } = Info[api];

    const headers = { "Content-Type": "application/json" };

    const accessToken = await handleGetAccessToken();
    const bearer = accessToken;
    if (authenticated) {
      headers.Authorization = `Bearer ${await bearer}`;
    }

    let url = `${BASE_URL}${route}`;
    if (params) {
      url += `${params}`;
    }

    const res = await fetch(url, {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    });

    const output = await res.json();

    if (isApiError(output)) {
      if (output.error && output.error.message) {
        throw new ApiError(output.error.message, res.status);
      } else {
        throw new ApiError("Unknown Error!", res.status);
      }
    }

    if (res.status >= 400) {
      throw new ApiError(
        `Unknown Error ${res.status}: ${res.statusText}`,
        res.status
      );
    }

    return output;
  } catch (e) {
    if (e instanceof ApiError) {
      return { error: e.message, statusCode: e.statusCode };
    }
    return {
      error: e.message || "An unexpected error occurred",
      statusCode: null,
    };
  }
}

export async function pagingApiRequest({ api, params, page }) {
  try {
    const { method, route, authenticated } = Info[api];

    const headers = { "Content-Type": "application/json" };

    const accessToken = await handleGetAccessToken();
    const bearer = accessToken;
    if (authenticated) {
      headers.Authorization = `Bearer ${await bearer}`;
    }

    let url = `${BASE_URL}${route}`;
    if (params) {
      url += `${params}&page=${page}`;
    } else {
      url += `?page=${page}`;
    }

    const res = await fetch(url, {
      method,
      headers,
    });
    const output = await res.json();

    if (isApiError(output)) {
      if (output.error && output.error.message) {
        throw new ApiError(output.error.message, res.status);
      } else {
        throw new ApiError("Unknown Error!", res.status);
      }
    }

    if (res.status >= 400) {
      throw new ApiError(
        `Unknown Error ${res.status}: ${res.statusText}`,
        res.status
      );
    }

    return output;
  } catch (e) {
    return { error: e ? e.message : "" };
  }
}

export default { apiRequest, pagingApiRequest };
