import { TextField, Button, Box } from "@mui/material";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { apiRequest } from "../../../../Helper/ApiRequest";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const ProgramsCreatePage = ({ setOpen }) => {
  let theme = useTheme();

  const [{ title }, setProgram] = useState({
    title: "",
  });
  const handleCloseCreate = () => {
    setOpen(false);
  };

  const createProgram = async () => {
    const payload = {
      title,
    };
    const response = await apiRequest({
      api: "CreateProgram",
      body: payload,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });
      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
    } else {
      enqueueSnackbar("Created Program!", { variant: "success" });
      setOpen(false);
    }
  };

  return (
    <Box
      sx={{
        width: 475,
      }}
    >
      <DialogTitle
        sx={{
          margin: 0,
          fontSize: 19,
          fontWeight: 500,
          flex: "0 0 auto",
          padding: "24px 24px 16px",
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
      >
        {"Create"}
      </DialogTitle>
      <DialogContent
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
          padding: "0px 24px",
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          fontWeight: 400,
        }}
      >
        <Box>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Title"
            onChange={(event) =>
              setProgram({
                title: event.target.value,
              })
            }
            value={title}
            inputProps={{
              style: {
                height: "25px",
                backgroundColor: "white",
              },
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-root": {
                backgroundColor: "white",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ddd",
                borderRadius: "8px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flex: "0 0 auto",
          padding: "24px",
        }}
      >
        <Button
          onClick={handleCloseCreate}
          autoFocus
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
            borderColor: "rgba(145, 158, 171, 0.32)",
            borderWidth: "1px",
            borderStyle: "solid",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          Cancel
        </Button>

        <Button
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "#1C252E",
            color: "white",
            "&:hover": {
              backgroundColor: "#2D3A45",
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: "#2D3A45",
              color: "white",
              boxShadow: "none",
            },
          }}
          disabled={title.length > 0 ? false : true}
          onClick={() => {
            createProgram();
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Box>
  );
};

export default ProgramsCreatePage;

ProgramsCreatePage.propTypes = {
  open: PropTypes.any,
};
