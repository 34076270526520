import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { apiRequest } from "Helper/ApiRequest";
import { handleGetStrength } from "Helper/Tokens";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const Item = styled(Paper)(() => ({
  textAlign: "left",
}));

const SettingsGeneralPage = ({ generalSettings, setGeneralSettings }) => {
  const [isExportable, setIsExportable] = useState(false); // State for isExportable

  const canEditDetails = () => {
    return handleGetStrength() >= 5;
  };

  async function handleFetchDetails() {
    const response = await apiRequest({
      api: "GetSettings",
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    setIsExportable(response.allowExport);
  }

  async function handleSaveDetails() {
    const response = await apiRequest({
      api: "UpdateSettings",
      body: {
        title: generalSettings.title,
        subtitle: generalSettings.subtitle,
        allowExport: isExportable,
      },
      params: `/${generalSettings.id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }
    enqueueSnackbar("Update Settings! (please refresh page)", {
      variant: "success",
    });
  }

  useEffect(() => {
    handleFetchDetails();
  }, []);

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid md={6}>
          <Item>
            <Box>
              <Typography
                fontSize={16}
                fontWeight={700}
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Basic Details
              </Typography>
              <Typography
                fontWeight={400}
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Update Name
              </Typography>
            </Box>
          </Item>
        </Grid>
        <Grid md={6}>
          <Item>
            <TextField
              variant="outlined"
              fullWidth
              value={generalSettings.title}
              disabled={!canEditDetails()}
              inputProps={{
                style: {
                  height: "15px",
                  backgroundColor: "white",
                },
              }}
              onChange={(event) => {
                setGeneralSettings((prev) => ({
                  ...prev,
                  title: event.target.value,
                }));
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              value={generalSettings.subtitle}
              disabled
              onChange={(event) => {
                setGeneralSettings((prev) => ({
                  ...prev,
                  subtitle: event.target.value,
                }));
              }}
              inputProps={{
                style: {
                  height: "15px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                mt: 2,
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
              }}
            />
          </Item>
        </Grid>
        <Grid md={6}>
          <Item>
            <Box>
              <Typography
                fontSize={16}
                fontWeight={700}
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Exportable
              </Typography>
              <Typography
                fontWeight={400}
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Allow Data Exported
              </Typography>
            </Box>
          </Item>
        </Grid>
        <Grid md={6}>
          <Item>
            <FormControl>
              <RadioGroup
                value={isExportable} // Controlled by state
                onChange={(event) =>
                  setIsExportable(event.target.value === "true")
                } // Update state
              >
                <FormControlLabel
                  value={true}
                  control={<Radio size="small" />}
                  label="All data is exportable"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio size="small" />}
                  label="No data is exportable"
                />
              </RadioGroup>
            </FormControl>
          </Item>
        </Grid>
        <Grid md={12} mt={2}>
          <Item>
            <Button
              variant="contained"
              onClick={handleSaveDetails}
              disabled={!canEditDetails()}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                boxSizing: "border-box",
                cursor: "pointer",
                verticalAlign: "middle",
                backgroundColor: "#1C252E",
                textDecoration: "none",
                borderRadius: "8px",
                padding: "6px 12px",
                minWidth: "64px",
                boxShadow: "none",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                "&:hover": {
                  backgroundColor: "#2D3A45",
                  boxShadow: "none",
                },
              }}
            >
              Save
            </Button>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsGeneralPage;
