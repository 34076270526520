import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import Users from "views/pages/users/Users";
import Projects from "views/pages/projects/Projects";
import Programs from "views/pages/programs/Programs";
import Subprogram from "views/pages/subprograms/Subprogram";
import ProfilePage from "views/pages/profile/Profile";
import UtilitiesPage from "views/pages/utilities/Utilities";
import PrioritisedProjects from "views/pages/prioritised/PrioritisedProjects";
import ProjectDetails from "views/pages/projects/ProjectDetails";
import ProjectCreate from "views/pages/projects/ProjectCreate";
import Settings from "views/pages/settings/Settings";
import AnalyticsPage from "views/pages/analytics/AnalyticsPage";
import MapPage from "views/pages/map/MapPage";
import DashboardPage from "views/pages/dashboard/Dashboard";
import AddUpdateForecastActuals from "views/pages/projects/components/forecast/AddUpdateForecastActuals";
import GanttPage from "views/pages/gantt/GanttPage";
import InsufficientRoles from "ui-component/cards/InsufficientRolesPage";
import ForecastImport from "views/pages/analytics/components/ForecastImport";

// dashboard routing

// utilities routing
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardPage />,
    },
    {
      path: "/unauthorised",
      element: <InsufficientRoles />,
    },
    {
      path: "/analytics",
      element: <AnalyticsPage />,
    },
    {
      path: "/projects",
      element: <Projects />,
    },
    {
      path: "/timeline",
      element: <GanttPage />,
    },
    {
      path: "/map",
      element: <MapPage />,
    },
    {
      path: "/projects/:id",
      element: <ProjectDetails />,
    },
    {
      path: "/projects/:id/forecast",
      element: <AddUpdateForecastActuals isActuals={false} />,
    },
    {
      path: "/projects/:id/actuals",
      element: <AddUpdateForecastActuals isActuals={true} />,
    },
    {
      path: "/projects/:id/edit",
      element: <ProjectCreate edit={true} />,
    },
    {
      path: "/projects/create",
      element: <ProjectCreate edit={false} />,
    },
    {
      path: "/forecast/import",
      element: <ForecastImport />,
    },
    {
      path: "/users",
      element: <Users />,
    },
    {
      path: "/programs",
      element: <Programs />,
    },
    {
      path: "/programs/:id",
      element: <Subprogram />,
    },
    {
      path: "/subprogram/:id",
      element: <PrioritisedProjects />,
    },
    {
      path: "/account/",
      element: <ProfilePage />,
    },
    {
      path: "/utilities",
      element: <UtilitiesPage />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "icons",
      children: [
        {
          path: "tabler-icons",
          element: <UtilsTablerIcons />,
        },
      ],
    },
    {
      path: "icons",
      children: [
        {
          path: "material-icons",
          element: <UtilsMaterialIcons />,
        },
      ],
    },
    {
      path: "sample-page",
      element: <SamplePage />,
    },
  ],
};

export default MainRoutes;
