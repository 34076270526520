const sections = [
  {
    id: "get-started",
    name: "Getting Started",
    articles: [
      {
        id: "introduction",
        name: "Introduction",
        paragraphs: [
          "Welcome to Project Pit!",
          "This documentation will walk you through everything you need to know about using Project Pit, from setting up your first project to equipping your team with all of the tools they need to get the job done.",
          "Each tab includes easy-to-follow steps, tips, and guides that will ensure your journey through project management is smooth and successful.",
        ],
      },
      {
        id: "versioning",
        name: "Versioning",
        paragraphs: [
          "The version you'll use is always the latest for a bug-free experience. If you prefer to stay on a specific version, please contact support at the email provided.",
          `The current version is v${process.env.REACT_APP_VERSION}`,
        ],
      },
      {
        id: "support",
        name: "Support",
        paragraphs: [
          "Need assistance? Send us an email and we will help you out!",
          "If you think the problem is a bug, send us an email too and we will collect full details to report it to development.",
          "Support@project-pit.com",
        ],
      },
    ],
  },
  {
    id: "program",
    name: "Program",
    articles: [
      {
        id: "create-program",
        name: "Create Programs",
        paragraphs: [
          "Navigate to the programs page by selecting 'Programs' in the sidebar, once there click the three dots in the right hand corner, and select 'Create'.",
          "When the popup appears simply enter a unique name for the new program & click the create button. \n (Required Role: Administrator, Super Administrator)",
        ],
      },
      {
        id: "update-program",
        name: "Update Programs",
        paragraphs: [
          "From the programs page click the program you want to update. Once clicked, click the three dots in the right hand corner then click 'Edit'. A popup will appear allowing you to update the programs title. (Required Role: Administrator, Super Administrator)",
        ],
      },
      {
        id: "delete-program",
        name: "Delete Programs",
        paragraphs: [
          "From the programs page click the program you want to update. Once clicked, click the three dots in the right hand corner then click 'Delete'. A prompt will appear, select delete or cancel. (Required Role: Administrator, Super Administrator)",
        ],
      },
    ],
  },
  {
    id: "subprogram",
    name: "SubProgram",
    articles: [
      {
        id: "create-subprogram",
        name: "Create Subprograms",
        paragraphs: [
          "Navigate to the programs page by selecting 'Programs' in the sidebar, once there click the program you wish to create a Subprogram for. Once clicked, click the three dots in the right hand corner then click 'Create'.",
          "A prompt will appear, enter a title for the new subprogram and click next.",
          "Optionally setup a budget for the new Subprogram by selecting the budget year and entering the amount for said year. To add additional years simply click 'Add' and repeat the step again. Adding a budget is important to allow projects to be prioritised within each Subprogram.",
          "Once completed click the create button to finish creating the new Subprogram.",
        ],
      },
      {
        id: "update-subprogram",
        name: "Update Subprograms",
        paragraphs: [
          "From the Subprograms page click the Subprogram you want to update. Click the three dots in the top right hand corner and click on 'Edit'.",
          "From the prompt update all the Subprogram details including the title, budget or both, once finished click on 'Update'.",
        ],
      },
      {
        id: "delete-subprogram",
        name: "Delete Subprograms",
        paragraphs: [
          "From the Subprograms page click the Subprogram you want to delete. Click the three dots in the top right hand corner and click on 'Delete'.",
          "A prompt will appear, if you wish to delete all the projects under the selected subprogram toggle the checkbox stating such. Click either delete or cancel to finish deleting.",
        ],
      },
    ],
  },
  {
    id: "project",
    name: "Project",
    articles: [
      {
        id: "create-projects",
        name: "Create Projects",
        paragraphs: [
          "Navigate to the projects page by selecting 'Projects' in the sidebar, once there click the three dots in the right hand corner then click 'Create'.",
          "The first step is to enter a Title, Details, Lifecycle, Existing Project ID (Optional). Once done, click next.",
          "The second step is to optionally add additional information, Business Unit, Department & Directorate.",
          "The third step is to optionally set a location for the project, Facility ID, Facility Name, Road Name, and the Location",
          "If you have created priorities, you can assign a numerical value between 0 and 100 to indicate the impact of each priority. For example, 'Environmental - 75'.",
          "In the next step, enter your project's budget. Select the year from the dropdown menu and enter the numerical budget value for that year.",
          "Now, for the next step, select the associated subprogram for the project you are creating.",
          "You may now optionally enter all your gateways. Here, you will specify the start and end dates for each stage: Concept & Feasibility, Planning & Design, Procurement, Delivery & Construction, and Finalization.",
          "If you have any files you wish to attach to the new project, you can select them now. Please ensure that each file does not exceed 10MB in size",
          "The final step is to review all the information you've entered for the new project, ensure everything is correct, and then select 'Create'.",
        ],
      },
      {
        id: "update-projects",
        name: "Update Projects",
        paragraphs: [
          "Navigate to the projects page by selecting 'Projects' in the sidebar. Click on the project you want to update, then go to settings and choose 'Edit Project'.",
          "On the update page, use the navigator at the top to navigate to the specific tab you need.",
          "After updating all desired information, review it and click 'Update'.",
        ],
      },
      {
        id: "delete-projects",
        name: "Delete Projects",
        paragraphs: [
          "Navigate to the projects page by selecting 'Projects' in the sidebar. Click on the project you want to delete, then go to settings and choose 'Delete Project'.",
          "A prompt will appear asking you to confirm the deletion of the project. Click 'Delete'.",
          "Please Note: Deleting the project is irreversible.",
        ],
      },
      {
        id: "import-projects",
        name: "Import Projects",
        paragraphs: [
          "Navigate to the projects page by selecting 'Projects' in the sidebar, once there click the three dots in the right hand corner then click 'Import'.",
          "Download the import template and ensure all data is correctly formatted. Once ready, select the .CSV file using the file selector to import the projects.",
        ],
      },
      {
        id: "export-projects",
        name: "Export Projects",
        paragraphs: [
          "Navigate to the projects page by selecting 'Projects' in the sidebar, once there click the three dots in the right hand corner then click 'Export'.",
          "A download prompt will appear in your browser containing all the projects in a .CSV file.",
        ],
      },
    ],
  },
  {
    id: "forecast-actuals",
    name: "Forecast/Actuals",
    articles: [
      {
        id: "create-forecast",
        name: "Create Forecast",
        paragraphs: [
          "Navigate to the projects page by selecting 'Projects' in the sidebar. Once there, click the project you want to create a forecast for.",
          "Click on the 'Forecast/Actuals' tab, select 'Forecast' from the dropdown, and then click on 'Edit Forecast'.",
          "Enter a numerical value for which ever month(s) you wish to forecast for.",
        ],
      },
      {
        id: "update-forecast",
        name: "Update Forecast",
        paragraphs: [
          "Navigate to the projects page by selecting 'Projects' in the sidebar. Once there, click the project you want to create a forecast for.",
          "Click on the 'Forecast/Actuals' tab, select 'Forecast' from the dropdown, and then click on 'Edit Forecast'.",
          "Enter a new numerical value for the month(s) you wish to forecast. This will create a revised version with the updated forecast.",
        ],
      },
      {
        id: "create-actuals",
        name: "Create Actuals",
        paragraphs: [
          "Navigate to the projects page by selecting 'Projects' in the sidebar. Once there, click the project you want to add actuals for.",
          "Click on the 'Forecast/Actuals' tab, select 'Actuals' from the dropdown, and then click on 'Edit Actuals'.",
          "Enter a numerical value for which ever month(s) you wish enter actuals for.",
        ],
      },
      {
        id: "update-actuals",
        name: "Update Actuals",
        paragraphs: [
          "Navigate to the projects page by selecting 'Projects' in the sidebar. Once there, click the project you want to add actuals for.",
          "Click on the 'Forecast/Actuals' tab, select 'Actuals' from the dropdown, and then click on 'Edit Actuals'.",
          "Enter new numerical values for the month(s) you wish to update with actuals; entering a revised version will override the existing values.",
        ],
      },
    ],
  },
  {
    id: "utilities",
    name: "Utilities",
    articles: [
      {
        id: "department",
        name: "Department",
        paragraphs: [
          "Navigate to the utilities page by selecting 'Utilities' in the sidebar. Once there, choose 'Department' from the dropdown menu. Next, click on the three dots in the top right corner and select 'Create'.",
          "A prompt will appear where you can enter the name of the new department. Click 'Create' when you're finished.",
        ],
      },
      {
        id: "business-unit",
        name: "Business Unit",
        paragraphs: [
          "Navigate to the utilities page by selecting 'Utilities' in the sidebar. Once there, choose 'Business Unit' from the dropdown menu. Next, click on the three dots in the top right corner and select 'Create'.",
          "A prompt will appear where you can enter the name of the new business unit. Click 'Create' when you're finished.",
        ],
      },
      {
        id: "directorate",
        name: "Directorate",
        paragraphs: [
          "Navigate to the utilities page by selecting 'Utilities' in the sidebar. Once there, choose 'Directorate' from the dropdown menu. Next, click on the three dots in the top right corner and select 'Create'.",
          "A prompt will appear where you can enter the name of the new directorate. Click 'Create' when you're finished.",
        ],
      },
      {
        id: "priority",
        name: "Priorities",
        paragraphs: [
          "Navigate to the utilities page by selecting 'Utilities' in the sidebar. Once there, choose 'Priority' from the dropdown menu. Next, click on the three dots in the top right corner and select 'Create'.",
          "A prompt will appear where you can enter the name of the new priority. Click 'Create' when you're finished.",
        ],
      },
    ],
  },
  {
    id: "users",
    name: "Users",
    articles: [
      {
        id: "create-users",
        name: "Creating Users",
        paragraphs: [
          "Navigate to the users page & click the three dots in the right hand corner, select 'Create'. Enter a unique email address, first name, last name and a password (The password can be changed upon login).",
          "Select a role or roles you wish to assign to the new user, once all the details are filled out, click the create button.",
        ],
      },
      {
        id: "update-users",
        name: "Updating Users",
        paragraphs: [
          "From the users page, find the user you want to update & select the pencil icon under the action header on their row. A popup will appear allowing you to update the email address, first name, last name and the roles. The password can only be updated by the logged in user or by resetting using the forgot password link.",
          "Once the changes have been made click the update button, for the updates to take affect the user may need to logout and login again.",
        ],
      },
      {
        id: "delete-users",
        name: "Delete Users",
        paragraphs: [
          "From the users page select the user(s) you want to delete. Once selected press the trash icon on the right side of the screen, once clicked the selected users are deleted. Please note that deleted users are unrecoverable.",
        ],
      },
      {
        id: "update-profile",
        name: "Update Profile",
        paragraphs: [
          "Look to the top right hand corner & select the settings button, navigate to the account settings.",
          "From the profile tab you will be able to update your email address, first name and last name.",
          "To update your password navigate to the security tab on the same page, and enter your current password followed by your new password.",
        ],
      },
      {
        id: "dark-mode",
        name: "Dark/Light Mode",
        paragraphs: [
          "To update your preferred colour scheme simply select the settings button in the top right hand corner & toggle light or dark mode on/off.",
        ],
      },
    ],
  },
];

export default sections;
