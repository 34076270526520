import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// project imports
import App from "App";
import { store } from "store";

// style + assets
import "assets/scss/style.scss";
import config from "./config";

// Bugsnag
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

// Bugsnag
Bugsnag.start({
  apiKey: '82edbb69c44d2ec2f03021ef15563229',
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: '82edbb69c44d2ec2f03021ef15563229' });

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);
