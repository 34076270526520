import { useState, useEffect, useRef } from "react";
import {
  monthDiff,
  getDaysInMonth,
  createFormattedDateFromStr,
} from "../../../../Helper/GanttDataFunctions";
import { useTheme } from "@mui/material/styles";
import GanttItem from "./GanttItem";
import { Typography } from "@mui/material";
import { styled } from "@mui/styles";
import Menu from "@mui/material/Menu";
import GanttChartPopup from "./GanttChartPopup";
import moment from "moment";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function TimeTable({
  timeRange,
  tasks,
  taskDurations,
  selectedToday,
  setSelectedToday,
  selectedYear,
}) {
  const theme = useTheme();
  const containerRef = useRef(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [anchorContextEl, setAnchorContextEl] = useState(null);
  const contextOpen = Boolean(anchorContextEl);

  const ganttTimePeriod = {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "minmax(300px, 1fr)",
    textAlign: "center",
    height: "var(--cell-height)",
    fontFamily:
      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  };

  const ganttTimePeriodRow = {
    display: "grid",
    gridAutoFlow: "column", // Ensure months flow horizontally
    gridAutoColumns: "minmax(300px, 1fr)", // Adjust column size as needed
    textAlign: "center",
    height: "50px",
    fontFamily:
      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  };

  const ganttTimePeriodSpan = {
    display: "flex",
    margin: "auto",
  };

  const ganttTimePeriodCell = {
    position: "relative",
    outline: "0.25px solid var(--color-outline)",
  };

  const taskDuration = {
    position: "absolute",
    marginTop: "calc(var(--cell-height) / 5)",
    height: "calc(var(--cell-height) / 1.7)",
    zIndex: "1",
    borderRadius: "var(--border-radius)",
    cursor: "move",
  };

  const getTodayPosition = (container, today) => {
    const monthsWidth = container.scrollWidth;
    const monthsCount =
      monthDiff(
        new Date(timeRange.fromSelectYear, timeRange.fromSelectMonth),
        new Date(timeRange.toSelectYear, timeRange.toSelectMonth)
      ) + 1;
    const dayWidth =
      monthsWidth /
      monthsCount /
      getDaysInMonth(today.getFullYear(), today.getMonth() + 1);
    const currentMonthWidth =
      dayWidth * getDaysInMonth(today.getFullYear(), today.getMonth() + 1);
    const monthsOffset = monthDiff(
      new Date(timeRange.fromSelectYear, timeRange.fromSelectMonth),
      new Date(today.getFullYear(), today.getMonth())
    );
    const position =
      (monthsOffset * monthsWidth) / monthsCount +
      (currentMonthWidth * today.getDate()) /
        getDaysInMonth(today.getFullYear(), today.getMonth() + 1);
    return position;
  };

  const handleScrollToday = () => {
    const container = containerRef.current;
    const today = new Date();
    const todayPosition = getTodayPosition(container, today);

    if (todayPosition !== null) {
      container.scrollTo({
        left: todayPosition - container.clientWidth / 2,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    handleScrollToday();
  }, []);

  useEffect(() => {
    if (selectedToday) {
      handleScrollToday();
      setSelectedToday(false);
    }
  }, [selectedToday]);

  useEffect(() => {
    monthRows = [];
    dayRows = [];
    dayRow = [];
    taskRows = [];
    taskRow = [];
    handleScrollToday();
  }, []);

  const handleContextClick = (event, project) => {
    setSelectedProject(project);
    setAnchorContextEl({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
  };

  const handleContextClose = () => {
    setAnchorContextEl(null);
  };

  const startYear = Number(timeRange.fromSelectYear);
  const endYear = Number(timeRange.toSelectYear);
  const yearsArray = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  let monthRows = [];
  let dayRows = [];
  let dayRow = [];
  let taskRows = [];
  let taskRow = [];

  for (let i = 0; i < yearsArray.length; i++) {
    const monthsOfYear = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let j = 0; j < monthsOfYear.length; j++) {
      const currentDate = new Date(Number(yearsArray[i]), j);
      const monthOfYear = monthsOfYear[currentDate.getMonth()];

      const today = moment().format("MM/YYYY");
      const momentDate = moment(currentDate).format("MM/YYYY");

      const isToday = momentDate === today;

      const currentYear = moment().format("YYYY");
      const year = moment(currentDate).format("YYYY");
      const isCurrentYear = year === currentYear;

      dayRow.push(
        <div
          key={j}
          style={{
            ...ganttTimePeriodCell,
            outline: "none",
            width: "100%",
          }}
        >
          <span
            style={{
              ...ganttTimePeriodSpan,
              color: theme.palette.text.primary,
              paddingLeft: 5,
              paddingRight: 5,
              display: "inline-block", // Make the span an inline-block to set width
              textAlign: "center", // Center text within the span
              width: "100%", // Ensure the span takes full width
              height: "100%",
              alignContent: "center",
              borderBottom: "0.25px solid var(--color-outline)",
              background: isToday ? "rgba(145, 158, 171, 0.16)" : "transparent",
            }}
          >
            <Typography
              fontSize={13}
              sx={{
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              {!isCurrentYear ? `${monthOfYear} ${year}` : monthOfYear}
            </Typography>
          </span>
        </div>
      );
    }

    dayRows.push(
      <div
        key={i}
        style={{
          ...ganttTimePeriodRow,
          outline: "none",
          background: "#f4f5f7",
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
      >
        {dayRow}
      </div>
    );
    dayRow = []; // Clear dayRow for the next iteration
  }

  const maxEndDate = moment(`${selectedYear + 5}-12-31`);
  if (tasks) {
    tasks.forEach((task) => {
      let yrObj = new Date(parseInt(startYear), 0);
      for (let i = 0; i < yearsArray.length; i++) {
        for (let j = 1; j <= 12; j++) {
          const formattedDate = createFormattedDateFromStr(
            yrObj.getFullYear(),
            j,
            1
          );

          const today = moment().format("MM/YYYY");
          const momentDate = moment(formattedDate).format("MM/YYYY");

          const isToday = momentDate === today;

          taskRow.push(
            <div
              key={`${task.id}-${j}`}
              style={{
                ...ganttTimePeriodCell,
                backgroundColor: isToday
                  ? "rgba(145, 158, 171, 0.16)"
                  : theme.palette.background.default,
              }}
              data-task={task?.id}
              data-date={formattedDate}
            >
              {taskDurations.map((el) => {
                const originalDateStart = moment(el?.start);
                let originalDateEnd = moment(el?.end);

                // Clamp the end date to the max end date
                if (originalDateEnd.isAfter(maxEndDate)) {
                  originalDateEnd = maxEndDate;
                }

                const monthsBetween =
                  originalDateEnd.diff(originalDateStart, "months") + 1;
                const monthDateFirst = originalDateStart
                  .startOf("month")
                  .format("YYYY-MM-DD");

                if (el?.task === task?.id && monthDateFirst === formattedDate) {
                  return (
                    <div
                      key={`${el?.id}`}
                      style={{
                        ...taskDuration,
                        width: `calc(${monthsBetween} * 100% - 1px)`,
                        display: "flex",
                        cursor: "pointer",
                        backgroundColor: "#8edca1",
                      }}
                      role="button"
                      tabIndex={0}
                      onClick={(event) => handleContextClick(event, el)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleContextClick(event, el);
                        }
                      }}
                    >
                      <GanttItem
                        showingTitle={monthsBetween > 2}
                        project={el}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        }
        taskRows.push(
          <div key={`${i}-${task?.id}`} style={ganttTimePeriod}>
            {taskRow}
          </div>
        );
        taskRow = [];
        yrObj.setFullYear(yrObj.getFullYear() + 1);
      }
    });
  }

  return (
    <div
      id="gantt-grid-container__time"
      ref={containerRef}
      style={{
        gridTemplateColumns: `repeat(${yearsArray.length}, 1fr)`,
        position: "relative",
      }}
    >
      {monthRows}
      {dayRows}
      <div
        style={{
          gridColumn: "1 / -1", // Ensure it spans across all columns
          display: "grid",
          gridTemplateColumns: `repeat(${yearsArray.length}, 1fr)`,
          paddingLeft: "0.5px",
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        {taskRows}
      </div>
      <StyledMenu
        anchorReference="anchorPosition"
        anchorPosition={
          anchorContextEl
            ? { top: anchorContextEl.mouseY, left: anchorContextEl.mouseX }
            : undefined
        }
        open={contextOpen}
        onClose={handleContextClose}
      >
        <GanttChartPopup project={selectedProject} />
      </StyledMenu>
    </div>
  );
}
