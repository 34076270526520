import {
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  Stack,
  Box,
  Breadcrumbs,
  Card,
  Grid,
  Checkbox,
  ListItemText,
} from "@mui/material";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";
import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

import "assets/scss/map.scss";
import { apiRequest } from "Helper/ApiRequest";
import MainCard from "ui-component/cards/MainCard";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import "mapbox-gl/dist/mapbox-gl.css";
import ReactGA from "react-ga4";
import Bugsnag from "@bugsnag/js";

const MapPage = () => {
  const mode = useSelector((state) => state.customization.navType);

  const [projects, setProjects] = useState([]);

  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const [lifecycleFilter, setLifecycleFilter] = useState([]);

  const handleChangeStatusFilter = (event) => {
    const {
      target: { value },
    } = event;
    setStatusFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeLifecycleFilter = (event) => {
    const {
      target: { value },
    } = event;
    setLifecycleFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onChangeHandler = (event) => {
    setSearch(event.target.value);
  };

  const mapContainerRef = useRef(null);
  const mapRef = useRef();

  async function handleFetchProjects() {
    setProjects([]);

    // Prepare the params
    const searchParam = search ? `search=${encodeURIComponent(search)}` : "";
    const statusParam =
      statusFilter.length > 0
        ? `status=${encodeURIComponent(statusFilter.join(","))}`
        : "";
    const lifecycleParam =
      lifecycleFilter.length > 0
        ? `lifecycle=${encodeURIComponent(lifecycleFilter.join(","))}`
        : "";

    // Construct the query string
    const params = [searchParam, statusParam, lifecycleParam]
      .filter((param) => param)
      .join("&");
    const queryString = params ? `?${params}` : "";

    const response = await apiRequest({
      api: "ListProjectsLocations",
      params: queryString,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    setProjects(response);
  }

  useEffect(() => {
    handleFetchProjects();

    // Google Anayltics
    ReactGA.send({
      hitType: "pageview",
      page: "/map",
      title: "Map",
    });
  }, [search, lifecycleFilter, statusFilter]);

  useEffect(() => {
    // Important, hard code and make different for each tenant.
    const coords = String("-73.98004845345598, 40.72013456171108");
    const [longitude, latitude] = coords
      .split(",")
      .map((coord) => parseFloat(coord.trim()));

    mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_KEY;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [longitude, latitude],
      zoom: 12,
    });

    mapRef.current.on("load", () => {
      mapRef.current.addSource("projects", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: projects,
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      mapRef.current.addLayer({
        id: "clusters",
        type: "circle",
        source: "projects",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#51bbd6",
            100,
            "#f1f075",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      mapRef.current.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "projects",
        filter: ["has", "point_count"],
        layout: {
          "text-field": ["get", "point_count_abbreviated"],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      mapRef.current.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: "projects",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#3cc30d",
          "circle-radius": 6.8,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#ffffff",
        },
      });

      mapRef.current.on("click", "unclustered-point", (e) => {
        const projectId = e.features[0].properties.id;
        // Construct the URL for the project
        const url = `/projects/${projectId}`;
        // Open the URL in a new tab
        window.open(url, "_blank");
      });

      mapRef.current.on("mouseenter", "clusters", () => {
        mapRef.current.getCanvas().style.cursor = "pointer";
      });

      mapRef.current.on("mouseleave", "clusters", () => {
        mapRef.current.getCanvas().style.cursor = "";
      });

      let popup = null; // Variable to store the popup instance

      mapRef.current.on("mouseenter", "unclustered-point", (e) => {
        mapRef.current.getCanvas().style.cursor = "pointer";

        // Point
        const coordinates = e.features[0].geometry.coordinates.slice();

        // Create and store the popup instance
        const title = e.features[0].properties.title;

        popup = new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(`${title}`)
          .addTo(mapRef.current);
      });

      mapRef.current.on("mouseleave", "unclustered-point", () => {
        mapRef.current.getCanvas().style.cursor = "";

        // Remove the popup if it exists
        if (popup) {
          popup.remove();
          popup = null; // Clear the reference
        }
      });
    });

    return () => mapRef.current.remove();
  }, [projects, mode]);

  return (
    <MainCard>
      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Map
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Dashboard
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
                color="text.primary"
              >
                Map
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Stack>
      {/* Table */}
      <Card
        sx={{
          color: "#1C252E",
          backgroundImage: "none",
          position: "relative",
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        {/* Table Toolbar */}
        <Grid
          container
          spacing={2}
          sx={{
            paddingY: "20px",
            alignItems: "center",
            paddingRight: "8px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={9}
            container
            spacing={2}
            direction={{ xs: "column", sm: "row" }} // Stack vertically on extra-small screens, horizontally on small and larger screens
            alignItems="stretch" // Ensure items stretch to fill the available space
          >
            <Grid item xs>
              <FormControl
                fullWidth
                sx={{
                  display: "inline-flex",
                  verticalAlign: "top",
                  flexDirection: "column",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                }}
              >
                <InputLabel
                  sx={{
                    "&.MuiInputLabel-root.Mui-focused": {
                      color: "#1C252E",
                      fontWeight: 600,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  label="Status"
                  fullWidth
                  multiple
                  value={statusFilter}
                  renderValue={(selected) => selected.join(", ")}
                  onChange={handleChangeStatusFilter}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ddd",
                      borderRadius: "8px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1C252E",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1C252E",
                    },
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 12px",
                      fontSize: "14px",
                      background: "white",
                      textAlign: "center",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                    "& .MuiSelect-icon": {
                      color: "#1C252E",
                    },
                  }}
                >
                  <MenuItem value={"TBA"}>
                    <Checkbox checked={statusFilter.indexOf("TBA") > -1} />
                    <ListItemText primary={"TBA"} />
                  </MenuItem>

                  <MenuItem value={"On Track"}>
                    <Checkbox checked={statusFilter.indexOf("On Track") > -1} />
                    <ListItemText primary={"On Track"} />
                  </MenuItem>

                  <MenuItem value={"Monitor"}>
                    <Checkbox checked={statusFilter.indexOf("Monitor") > -1} />
                    <ListItemText primary={"Monitor"} />
                  </MenuItem>

                  <MenuItem value={"Action Required"}>
                    <Checkbox
                      checked={statusFilter.indexOf("Action Required") > -1}
                    />
                    <ListItemText primary={"Action Required"} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl
                fullWidth
                sx={{
                  display: "inline-flex",
                  verticalAlign: "top",
                  flexDirection: "column",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                  },
                }}
              >
                <InputLabel
                  sx={{
                    "&.MuiInputLabel-root.Mui-focused": {
                      color: "#1C252E",
                      fontWeight: 600,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                  }}
                >
                  Lifecycle
                </InputLabel>
                <Select
                  label="Lifecycle"
                  fullWidth // Ensure the Select takes full width
                  multiple
                  value={lifecycleFilter}
                  renderValue={(selected) => selected.join(", ")}
                  onChange={handleChangeLifecycleFilter}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ddd",
                      borderRadius: "8px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1C252E",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1C252E",
                    },
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 12px",
                      fontSize: "14px",
                      background: "white",
                      textAlign: "center",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                    "& .MuiSelect-icon": {
                      color: "#1C252E",
                    },
                  }}
                >
                  <MenuItem value={"new"}>
                    <Checkbox checked={lifecycleFilter.indexOf("new") > -1} />
                    <ListItemText primary={"New"} />
                  </MenuItem>

                  <MenuItem value={"renewal"}>
                    <Checkbox
                      checked={lifecycleFilter.indexOf("renewal") > -1}
                    />
                    <ListItemText primary={"Renewal"} />
                  </MenuItem>

                  <MenuItem value={"upgrade"}>
                    <Checkbox
                      checked={lifecycleFilter.indexOf("upgrade") > -1}
                    />
                    <ListItemText primary={"Upgrade"} />
                  </MenuItem>

                  <MenuItem value={"disposal"}>
                    <Checkbox
                      checked={lifecycleFilter.indexOf("disposal") > -1}
                    />
                    <ListItemText primary={"Disposal"} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                fullWidth // Ensure the TextField takes full width
                placeholder="Search..."
                onChange={onChangeHandler}
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    height: "25px",
                    backgroundColor: "white",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "white",
                    height: "52px",
                    "& input": {
                      height: "100%", // Ensure the input takes the full height of the container
                    },
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#000",
                      borderRadius: "8px",
                    },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Active Search */}
        {((lifecycleFilter && lifecycleFilter.length > 0) ||
          (statusFilter && statusFilter.length > 0) ||
          (search && search.length > 0)) && (
          <Box sx={{ padding: "0px 0px 20px" }}>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexWrap: "wrap",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                {statusFilter && statusFilter.length > 0 && (
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      padding: "8px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "dashed 1px rgba(145, 158, 171, 0.2)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        lineHeight: "24px",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Status:
                    </Typography>
                    <Box
                      sx={{
                        gap: "8px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        endIcon={
                          <CancelIcon
                            sx={{
                              cursor: "pointer",
                              width: 16,
                              color: "rgba(28, 37, 46, 0.26)",
                              margin: "0px 4px 0px -4px",
                              mr: 1,
                            }}
                          />
                        }
                        onClick={() => {
                          setStatusFilter([]);
                        }}
                        sx={{
                          position: "relative",
                          userSelect: "none",
                          appearance: "none",
                          maxWidth: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "24px",
                          color: "#1C252E",
                          cursor: "unset",
                          verticalAlign: "middle",
                          boxSizing: "border-box",
                          backgroundColor: "rgba(145, 158, 171, 0.16)",
                          margin: "0px",
                          whiteSpace: "nowrap",
                          transition:
                            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                          outline: "0px",
                          textDecoration: "none",
                          borderWidth: "0px",
                          borderStyle: "initial",
                          borderColor: "initial",
                          borderImage: "initial",
                          padding: "0px",
                          borderRadius: "8px",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            px: "8px",
                            whiteSpace: "nowrap",
                            fontWeight: 500,
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {statusFilter.join(", ")}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
                {lifecycleFilter && lifecycleFilter.length > 0 && (
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      padding: "8px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "dashed 1px rgba(145, 158, 171, 0.2)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        lineHeight: "24px",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Lifecycle:
                    </Typography>
                    <Box
                      sx={{
                        gap: "8px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        endIcon={
                          <CancelIcon
                            sx={{
                              cursor: "pointer",
                              width: 16,
                              color: "rgba(28, 37, 46, 0.26)",
                              margin: "0px 4px 0px -4px",
                              mr: 1,
                            }}
                          />
                        }
                        onClick={() => {
                          setLifecycleFilter([]);
                        }}
                        sx={{
                          position: "relative",
                          userSelect: "none",
                          appearance: "none",
                          maxWidth: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "24px",
                          color: "#1C252E",
                          cursor: "unset",
                          verticalAlign: "middle",
                          boxSizing: "border-box",
                          backgroundColor: "rgba(145, 158, 171, 0.16)",
                          margin: "0px",
                          whiteSpace: "nowrap",
                          transition:
                            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                          outline: "0px",
                          textDecoration: "none",
                          borderWidth: "0px",
                          borderStyle: "initial",
                          borderColor: "initial",
                          borderImage: "initial",
                          padding: "0px",
                          borderRadius: "8px",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            px: "8px",
                            whiteSpace: "nowrap",
                            fontWeight: 500,
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {lifecycleFilter.join(", ")}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
                {search && search.length > 0 && (
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      padding: "8px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "dashed 1px rgba(145, 158, 171, 0.2)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        lineHeight: "24px",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      Search:
                    </Typography>
                    <Box
                      sx={{
                        gap: "8px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        endIcon={
                          <CancelIcon
                            sx={{
                              cursor: "pointer",
                              width: 16,
                              color: "rgba(28, 37, 46, 0.26)",
                              margin: "0px 4px 0px -4px",
                              mr: 1,
                            }}
                          />
                        }
                        onClick={() => {
                          setSearch("");
                        }}
                        sx={{
                          position: "relative",
                          userSelect: "none",
                          appearance: "none",
                          maxWidth: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "24px",
                          color: "#1C252E",
                          cursor: "unset",
                          verticalAlign: "middle",
                          boxSizing: "border-box",
                          backgroundColor: "rgba(145, 158, 171, 0.16)",
                          margin: "0px",
                          whiteSpace: "nowrap",
                          transition:
                            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                          outline: "0px",
                          textDecoration: "none",
                          borderWidth: "0px",
                          borderStyle: "initial",
                          borderColor: "initial",
                          borderImage: "initial",
                          padding: "0px",
                          borderRadius: "8px",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            px: "8px",
                            whiteSpace: "nowrap",
                            fontWeight: 500,
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {search}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
                <Button
                  startIcon={<DeleteIcon />}
                  sx={{
                    color: "#FF5630",
                    fontWeight: 700,
                    minWidth: "64px",
                    fontSize: 14,
                    backgroundColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                  onClick={() => {
                    setSearch("");
                    setLifecycleFilter([]);
                    setStatusFilter([]);
                  }}
                >
                  Clear
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {/* Map */}
        <Box
          sx={{
            height: 750,
          }}
          ref={mapContainerRef}
          className="map-container"
        />
      </Card>
    </MainCard>
  );
};

export default MapPage;
