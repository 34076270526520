import { apiRequest } from "Helper/ApiRequest";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  InputAdornment,
  Link,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ReactGA from "react-ga4";

import moment from "moment/moment";

import { handleGetStrength } from "Helper/Tokens";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const PrioritisedProjects = () => {
  const { id } = useParams();
  const [subprogram, setSubprogram] = useState(null);
  const [program, setProgram] = useState(null);
  const [projects, setProjects] = useState([]);

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const [search, setSearch] = useState(null);

  const onChangeHandler = (event) => {
    setSearch(event.target.value);
  };

  const navigate = useNavigate();

  async function fetchSubprogram() {
    const response = await apiRequest({
      api: "GetSubProgram",
      params: `/${id}`,
    });
    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    } else {
      setSubprogram(response);
      setProgram(response.program);
    }
  }

  const statusColours = {
    TBA: {
      colour: "#637381",
      backgroundColor: "rgba(145, 158, 171, 0.16)",
    },
    Monitor: {
      colour: "#B76E00",
      backgroundColor: "rgba(255, 171, 0, 0.16)",
    },
    "On Track": {
      colour: "#118D57",
      backgroundColor: "rgba(34, 197, 94, 0.16)",
    },
    "Action Required": {
      colour: "#B71D18",
      backgroundColor: "rgba(255, 86, 48, 0.16)",
    },
  };

  async function handleFetchBudget() {
    const response = await apiRequest({
      api: "GetSubprogramBudget",
      params: `?subprogram=${id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    fetchProjects();
  }

  async function fetchProjects(subprogram) {
    try {
      if (subprogram) {
        // Build query parameters
        const queryParams = new URLSearchParams();
        queryParams.append("subprogram", subprogram._id);

        // Include search parameter if provided
        if (search) {
          queryParams.append("search", search);
        }

        // Make the API request
        const response = await apiRequest({
          api: "ListSubprogramProjects",
          params: `?${queryParams.toString()}`,
        });

        if (response.error) {
          enqueueSnackbar(response.error, { variant: "error" });

          // Bugsnag error
          Bugsnag.notify(new Error(response.error));
          return;
        }

        setProjects(response);
      }
    } catch (error) {
      enqueueSnackbar(error.message || "An unexpected error occurred", {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(error.message));
    }
  }

  useEffect(() => {
    fetchSubprogram();

    // Google Anayltics
    ReactGA.send({
      hitType: "pageview",
      page: "/nested-projects",
      title: "Nested-Projects",
    });
  }, []);

  useEffect(() => {
    if (subprogram) {
      fetchProjects(subprogram);
    }
  }, [subprogram, page, search]);

  const unixToDate = (time) => {
    var day = moment.unix(time);
    return day.format("MMMM Do YYYY, h:mm a");
  };

  useEffect(() => {
    fetchSubprogram();
    handleFetchBudget();
  }, []);

  const handleNavigateProjectsCreate = () => {
    if (canUserCreate()) {
      navigate(`/projects/create`);
    } else {
      enqueueSnackbar("Error: You don't have permission to create projects.", {
        variant: "error",
      });
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const canUserCreate = () => {
    return handleGetStrength() >= 4;
  };

  return (
    <MainCard>
      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Projects
            </Typography>
            {program && subprogram && (
              <Breadcrumbs aria-label="breadcrumb">
                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Dashboard
                </Typography>

                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Programs
                </Typography>

                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {program.title}
                </Typography>

                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Sub-Programs
                </Typography>

                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {subprogram.title}
                </Typography>

                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                  color="text.primary"
                >
                  Projects
                </Typography>
              </Breadcrumbs>
            )}
          </Box>
          <Box sx={{ flexShrink: 0 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                boxSizing: "border-box",
                cursor: "pointer",
                verticalAlign: "middle",
                backgroundColor: "#1C252E",
                textDecoration: "none",
                borderRadius: "8px",
                padding: "6px 12px",
                minWidth: "64px",
                boxShadow: "none",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                "&:hover": {
                  backgroundColor: "#2D3A45",
                  boxShadow: "none",
                },
              }}
              disabled={!canUserCreate()}
              onClick={handleNavigateProjectsCreate}
            >
              New project
            </Button>
          </Box>
        </Box>
      </Stack>

      <Card
        sx={{
          color: "#1C252E",
          backgroundImage: "none",
          position: "relative",
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        {/* Table Toolbar */}
        <Stack
          sx={{
            display: "flex",
            paddingY: "20px",
            alignItems: "center",
            paddingRight: "8px",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignItems: "center",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Search..."
              onChange={onChangeHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
              }}
            />
          </Stack>
        </Stack>
        {/* Active Search */}
        {search && search.length > 0 && (
          <Box sx={{ padding: "0px 0px 20px" }}>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexWrap: "wrap",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    gap: "8px",
                    display: "flex",
                    padding: "8px",
                    borderRadius: "8px",
                    overflow: "hidden",
                    border: "dashed 1px rgba(145, 158, 171, 0.2)",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      lineHeight: "24px",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Search:
                  </Typography>
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      endIcon={
                        <CancelIcon
                          sx={{
                            cursor: "pointer",
                            width: 16,
                            color: "rgba(28, 37, 46, 0.26)",
                            margin: "0px 4px 0px -4px",
                            mr: 1,
                          }}
                        />
                      }
                      onClick={() => {
                        setSearch("");
                      }}
                      sx={{
                        position: "relative",
                        userSelect: "none",
                        appearance: "none",
                        maxWidth: "100%",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "24px",
                        color: "#1C252E",
                        cursor: "unset",
                        verticalAlign: "middle",
                        boxSizing: "border-box",
                        backgroundColor: "rgba(145, 158, 171, 0.16)",
                        margin: "0px",
                        whiteSpace: "nowrap",
                        transition:
                          "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                        outline: "0px",
                        textDecoration: "none",
                        borderWidth: "0px",
                        borderStyle: "initial",
                        borderColor: "initial",
                        borderImage: "initial",
                        padding: "0px",
                        borderRadius: "8px",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          px: "8px",
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        {search}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                <Button
                  startIcon={<DeleteIcon />}
                  sx={{
                    color: "#FF5630",
                    fontWeight: 700,
                    minWidth: "64px",
                    fontSize: 14,
                    backgroundColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                  onClick={() => {
                    setSearch("");
                  }}
                >
                  Clear
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {/* Table */}
        <Box
          sx={{
            position: "relative",
            overflowX: "auto", // Enable horizontal scrolling
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  color: "#637381",
                  backgroundColor: "#F4F6F8",
                  outline: "0px",
                }}
              >
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Lifecycle
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Subprogram
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Created
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.data &&
                projects.data.map((project) => (
                  <TableRow key={project._id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {project._id}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      <Link
                        href={`/projects/${project._id}`}
                        underline="hover"
                        color="inherit"
                      >
                        {project.title}
                      </Link>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 13,
                          height: "24px",
                          minWidth: "24px",
                          alignItems: "center",
                          lineHeight: 0,
                          fontWeight: 700,
                          padding: "0px 6px",
                          borderRadius: "6px",
                          color: statusColours[project.status].colour,
                          backgroundColor:
                            statusColours[project.status].backgroundColor,
                          whiteSpace: "nowrap",
                          justifyContent: "center",
                          display: "inline-flex",
                        }}
                      >
                        {project.status}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {capitalizeFirstLetter(project.lifecycle)}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {project.subprogram.title}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {unixToDate(project.inserted)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>

        {/* Pagination */}
        <Box
          sx={{
            position: "relative",
            alignItems: "flex-start",
          }}
        >
          {projects.settings && (
            <Pagination
              count={projects.settings.pages}
              page={page}
              onChange={handleChange}
              sx={{ mt: 4 }}
            />
          )}
        </Box>
      </Card>
    </MainCard>
  );
};

export default PrioritisedProjects;
