import { Button, Box, DialogContentText } from "@mui/material";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { apiRequest } from "../../../../Helper/ApiRequest";
import { useTheme } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const SubprogramDelete = ({ setOpen, selected, setSelected }) => {
  let theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  // deletes in order of subprogram
  const handleDeleteSubProgram = async () => {
    for (let i = 0; i < selected.length; i++) {
      const subprogram = selected[i];

      // Delete subprogram budget
      await apiRequest({
        api: "DeleteSubprogramBudget",
        params: `/?subprogram=${subprogram}`,
      });

      const response = await apiRequest({
        api: "DeleteSubprogram",
        params: `/${subprogram}`,
      });
      if (response.error) {
        enqueueSnackbar(response.error, { variant: "error" });
        // Log error with Bugsnag
        Bugsnag.notify(new Error(response.error));
        return;
      }
    }
    enqueueSnackbar(`Deleted ${selected.length} Sub-Programs!`, {
      variant: "success",
    });
    setSelected([]);
    handleClose();
  };

  return (
    <Box
      sx={{
        width: 475,
      }}
    >
      <DialogTitle
        sx={{
          margin: 0,
          fontSize: 19,
          fontWeight: 500,
          flex: "0 0 auto",
          padding: "24px 24px 16px",
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
      >
        {"Delete"}
      </DialogTitle>
      <DialogContent
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
          padding: "0px 24px",
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          fontWeight: 400,
        }}
      >
        <DialogContentText
          sx={{
            color: "#1C252E",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          Are you sure want to delete {selected.length} sub-programs?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flex: "0 0 auto",
          padding: "24px",
        }}
      >
        <Button
          onClick={handleDeleteSubProgram}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "#FF5630",
            color: "white",
            "&:hover": {
              backgroundColor: "#FF5630",
              boxShadow: "none",
            },
          }}
        >
          Delete
        </Button>
        <Button
          onClick={handleClose}
          autoFocus
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
            borderColor: "rgba(145, 158, 171, 0.32)",
            borderWidth: "1px",
            borderStyle: "solid",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
};

export default SubprogramDelete;
