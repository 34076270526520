import {
  IconDashboard,
  IconUserSearch,
  IconCards,
  IconSitemap,
  IconAdjustmentsAlt,
  IconCalendar,
  IconChartArea,
  IconMapPin,
  IconAlertTriangle,
} from "@tabler/icons";

const icons = {
  IconDashboard,
  IconUserSearch,
  IconCards,
  IconSitemap,
  IconAdjustmentsAlt,
  IconCalendar,
  IconChartArea,
  IconMapPin,
  IconAlertTriangle,
};

const dashboard = {
  id: "dashboard",
  title: "Overview",
  type: "group",
  children: [
    {
      id: "",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "analytics",
      title: "Analytics",
      type: "item",
      url: "/analytics",
      icon: icons.IconChartArea,
      breadcrumbs: false,
    },
    {
      id: "programs",
      title: "Programs",
      type: "item",
      url: "/programs",
      icon: icons.IconSitemap,
      breadcrumbs: false,
    },
    {
      id: "projects",
      title: "Projects",
      type: "item",
      url: "/projects",
      icon: icons.IconCards,
      breadcrumbs: false,
    },
    {
      id: "timeline",
      title: "Timeline",
      type: "item",
      url: "/timeline",
      icon: icons.IconCalendar,
      breadcrumbs: false,
    },
    {
      id: "map",
      title: "Map",
      type: "item",
      url: "/map",
      icon: icons.IconMapPin,
      breadcrumbs: false,
    },
    {
      id: "users",
      title: "Users",
      type: "item",
      url: "/users",
      icon: icons.IconUserSearch,
      breadcrumbs: false,
    },
    {
      id: "utilities",
      title: "Utilities",
      type: "item",
      url: "/utilities",
      icon: icons.IconAdjustmentsAlt,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
