import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { apiRequest } from "Helper/ApiRequest";
import { useEffect } from "react";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const UserEdit = ({ setOpen, id }) => {
  let theme = useTheme();

  const [originalEmail, setOriginalEmail] = useState(null);
  const [{ email, fname, lname }, setUser] = useState({
    email: "",
    fname: "",
    lname: "",
  });

  const [roles, setRoles] = useState([]);

  const [loading, setLoading] = useState(false);

  // All roles share id regardless of tenant
  const rolesEnum = {
    "Super Administrator": "65caf9528148afb86963701f",
    Administrator: "655bddb8b556fc36b40f1e8d",
    Moderator: "655bddacb556fc36b40f1e8c",
    Special: "655bdda0b556fc36b40f1e8b",
    User: "655bdd94b556fc36b40f1e8a",
    Guest: "655bdd64b556fc36b40f1e89",
  };

  const handleCloseCreate = () => {
    setOpen(false);
  };

  const removeItem = (item) => {
    setRoles((prevState) => prevState.filter((prevItem) => prevItem !== item));
  };

  const handleChange = (newValue) => {
    setRoles((prevState) => [...prevState, newValue]);
  };

  const editUser = async () => {
    setLoading(true);

    const selectedRoles = roles.filter(
      (elem, pos) => roles.indexOf(elem) === pos
    );

    const payload = {
      firstName: fname,
      lastName: lname,
      roles: selectedRoles,
      email: originalEmail === email ? undefined : email,
    };

    console.log(payload);

    const response = await apiRequest({
      api: "UpdateUser",
      params: `/${id}`,
      body: payload,
    });

    setLoading(false);
    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }
    setOpen(false);

    enqueueSnackbar("Updated User!", { variant: "success" });
  };

  async function handleFetchUser() {
    const response = await apiRequest({
      api: "ListUsers",
      params: `/${id}`,
    });
    response.roles.forEach((role) => {
      setRoles((prevState) => [...prevState, role._id]);
    });
    setOriginalEmail(response.email);
    setUser({
      email: response.email,
      fname: response.firstName,
      lname: response.lastName,
    });
  }

  useEffect(() => {
    handleFetchUser();
  }, []);

  return (
    <Box>
      <DialogTitle
        sx={{
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
        className="h2 mb-0 pb-2 mt-3"
      >
        {"Edit User"}
      </DialogTitle>
      <Typography
        sx={{
          ml: 3,
          mb: 1,
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
      >
        Updated details for user.
      </Typography>
      <DialogContent>
        <FormGroup>
          {email.length > 0 && (
            <TextField
              variant="outlined"
              fullWidth
              label="Email Address"
              defaultValue={email}
              onChange={(event) =>
                setUser({
                  email: event.target.value,
                  fname: fname,
                  lname: lname,
                })
              }
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            />
          )}
          <div className="d-flex mt-4 justify-content-center gap-3">
            {fname.length > 0 && (
              <TextField
                variant="outlined"
                fullWidth
                label="First Name"
                defaultValue={
                  fname ? fname.charAt(0).toUpperCase() + fname.slice(1) : ""
                }
                onChange={(event) =>
                  setUser({
                    email: email,
                    fname: event.target.value,
                    lname: lname,
                  })
                }
                inputProps={{
                  style: {
                    height: "25px",
                    backgroundColor: "white",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#000",
                      borderRadius: "8px",
                    },
                  "& .MuiFormLabel-root": {
                    color: "#888", // Change this to the desired color
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#000", // Change this to the desired color when focused
                  },
                }}
              />
            )}
            {lname.length > 0 && (
              <TextField
                variant="outlined"
                fullWidth
                label="Last Name"
                defaultValue={
                  lname ? lname.charAt(0).toUpperCase() + lname.slice(1) : ""
                }
                onChange={(event) =>
                  setUser({
                    email: email,
                    fname: fname,
                    lname: event.target.value,
                  })
                }
                inputProps={{
                  style: {
                    height: "25px",
                    backgroundColor: "white",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#000",
                      borderRadius: "8px",
                    },
                  "& .MuiFormLabel-root": {
                    color: "#888", // Change this to the desired color
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#000", // Change this to the desired color when focused
                  },
                }}
              />
            )}
          </div>
          <div className="mt-4">
            <p>Roles</p>
            {Object.keys(rolesEnum).map((key) => (
              <FormControlLabel
                control={<Checkbox />}
                label={key}
                key={key}
                checked={roles.includes(rolesEnum[key])}
                onChange={() => {
                  const value = rolesEnum[key];
                  if (roles.includes(value)) {
                    removeItem(value);
                  } else {
                    handleChange(value);
                  }
                }}
              />
            ))}
          </div>
        </FormGroup>
      </DialogContent>
      <DialogActions className="mb-4 mx-3">
        <Button
          onClick={handleCloseCreate}
          autoFocus
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
            borderColor: "rgba(145, 158, 171, 0.32)",
            borderWidth: "1px",
            borderStyle: "solid",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          disabled={
            roles.length > 0 &&
            email.length > 0 &&
            fname.length > 0 &&
            lname.length > 0
              ? false
              : true
          }
          sx={{
            fontWeight: 700,
            fontSize: 15,
            color: "white",
            minWidth: "64px",
            boxShadow: "none",
            padding: "8px 16px",
            borderRadius: "8px",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            backgroundColor: "#1C252E",
            "&:hover": {
              backgroundColor: "#2D3A45",
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: "#2D3A45",
              boxShadow: "none",
            },
          }}
          onClick={() => {
            editUser();
          }}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Box>
  );
};

export default UserEdit;

UserEdit.propTypes = {
  setOpen: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};
