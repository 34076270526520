import { Box, Divider, Typography } from "@mui/material";

const DocumentationSection = ({ article }) => {
  return (
    <Box>
      <Box sx={{ mx: 5 }}>
        {/* Content title */}
        <Typography variant="h2" sx={{ mb: 2, mt: 3, fontSize: 24, fontWeight: 600 }}>
          {article.name}
        </Typography>

        {/* Content paragraphs */}
        <Box sx={{ mb: 4 }}>
          {article.paragraphs && article.paragraphs.map((paragraph, paragraphIndex) => (
            <Typography key={paragraphIndex} fontSize={16} paragraph>
              {paragraph}
            </Typography>
          ))}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default DocumentationSection;
