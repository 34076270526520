/* eslint-disable */
import React, { useState } from "react";
import {
  Box,
  IconButton,
  LinearProgress,
  Typography,
  Link,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import Papa from "papaparse";
import { apiRequest } from "Helper/ApiRequest";
import dayjs from "dayjs";
import { getUserId } from "Helper/Tokens";
import { enqueueSnackbar } from "notistack";
import moment from "moment";

const ForecastImport = ({ onClose, setOpen }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);

  function handleFileChange(event) {
    const file = event.target.files[0];
    setSelectedFile(file);
  }

  const handleFileClick = () => {
    // Trigger the input element to open the file explorer
    document.getElementById("fileInput").click();
  };

  const [rows, setRows] = useState(null);
  const [addedData, setAddedData] = useState(0);
  const [missedData, setMissedData] = useState([]);

  const expectedHeaders = [
    "Name",
    "Year",
    "Status",
    "Current Gateway",
    "Program",
    "Subprogram",
    "Department",
    "Branch",
    "Description",
    "Budget",
    "Reprovision",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "Concept & Feasibility",
    "Planning & Design",
    "Procurement",
    "Delivery & Construction",
    "Finalisation",
  ];

  useEffect(() => {
    if (selectedFile) {
      Papa.parse(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const data = results.data;

          // Extract headers from the CSV file
          const csvHeaders = Object.keys(data[0]);

          console.log(csvHeaders);

          // Check if all expected headers are present in the CSV file
          const missingHeaders = expectedHeaders.filter(
            (header) => !csvHeaders.includes(header)
          );

          if (missingHeaders.length > 0) {
            // Handle case where some headers are missing
            setError(`CSV Validation failed with missing header(s)`);
            setSelectedFile(null);
            return;
          } else {
            setRows(data);
            setAddedData(0);
          }
        },
      });
    }
  }, [selectedFile]);

  useEffect(() => {
    if (rows && rows.length > 0) {
      handleImportForecastActuals();
    }
  }, [rows]);

  const handleProgram = async (program) => {
    const programSearchParam = encodeURIComponent(program);
    const programResponse = await apiRequest({
      api: "ListPrograms",
      params: `?search=${programSearchParam}`,
    });

    var programId = null;
    if (programResponse.data.length > 0) {
      programId = programResponse.data[0]._id;
      return programId;
    } else {
      const payload = {
        title: program,
      };
      const response = await apiRequest({
        api: "CreateProgram",
        body: payload,
      });

      programId = response._id;
      return programId;
    }
  };

  const handleSubProgram = async (programId, subprogram) => {
    const subprogramSearchParam = encodeURIComponent(subprogram);
    const subprogramResponse = await apiRequest({
      api: "GetSubProgramImport",
      params: `?title=${subprogramSearchParam}`,
    });

    var subprogramId = null;
    if (subprogramResponse.length > 0) {
      subprogramId = subprogramResponse[0]._id;
      return subprogramId;
    } else {
      const subpayload = {
        title: subprogram,
        program: programId,
      };
      const response = await apiRequest({
        api: "CreateSubprogram",
        body: subpayload,
      });

      subprogramId = response._id;
      return subprogramId;
    }
  };

  const handleDepartment = async (department) => {
    const departmentUnitSearchParam = encodeURIComponent(department);
    const departmentResponse = await apiRequest({
      api: "ListDepartments",
      params: `?search=${departmentUnitSearchParam}`,
    });

    var departmentId = null;
    if (departmentResponse.data.length > 0) {
      departmentId = departmentResponse.data[0]._id;
      return departmentId;
    } else {
      const payload = {
        title: department,
      };
      const response = await apiRequest({
        api: "CreateDepartments",
        body: payload,
      });

      departmentId = response._id;
      return departmentId;
    }
  };

  // const handleBusinessUnit = async (businessUnit) => {
  //   const businessUnitSearchParam = encodeURIComponent(businessUnit);
  //   const businessUnitResponse = await apiRequest({
  //     api: "ListBusinessUnit",
  //     params: `?search=${businessUnitSearchParam}`,
  //   });

  //   var bUnitId = null;
  //   if (businessUnitResponse.data.length > 0) {
  //     bUnitId = businessUnitResponse.data[0]._id;
  //     return bUnitId;
  //   } else {
  //     const payload = {
  //       title: businessUnit,
  //     };
  //     const response = await apiRequest({
  //       api: "CreateBusinessUnit",
  //       body: payload,
  //     });

  //     bUnitId = response._id;
  //     return bUnitId;
  //   }
  // };

  const handleBranch = async (branch) => {
    const branchSearchParam = encodeURIComponent(branch);
    const branchResponse = await apiRequest({
      api: "ListBranch",
      params: `?search=${branchSearchParam}`,
    });

    var branchId = null;
    if (branchResponse.data.length > 0) {
      branchId = branchResponse.data[0]._id;
      return branchId;
    } else {
      const payload = {
        title: branch,
      };
      const response = await apiRequest({
        api: "CreateBranch",
        body: payload,
      });

      branchId = response._id;
      return branchId;
    }
  };

  const handleUser = async (user) => {
    const userSearchParam = encodeURIComponent(user);
    const userResponse = await apiRequest({
      api: "ListUsers",
      params: `?search=${userSearchParam}`,
    });

    var userId = null;
    if (userResponse.data && userResponse.data.length > 0) {
      userId = userResponse.data[0]._id;
      return userId;
    }
    return null
  };

  const parseDate = (dateStr) => {
    // Parse the date using the specific format 'DD/MM/YYYY'
    const parsedDate = dayjs(dateStr, "DD/MM/YYYY", true);

    // Check if the date is valid
    return parsedDate.isValid() ? parsedDate.$d : null;
  };

  async function handleImportForecastActuals() {
    for (let i = 0; i < rows.length; i++) {
      const rowData = rows[i];
      if (rowData["Name"]) {
        const userName = rowData["User"];
        const projectName = rowData["Name"];
        const projectExistingId = projectName.split(" - ")[0];

        let lifecycle;
        if (projectName.includes("Renewal")) {
          lifecycle = "Renewal";
        } else if (projectName.includes("Upgrade")) {
          lifecycle = "Upgrade";
        } else {
          lifecycle = "New";
        }

        let currentGateway = rowData["Current Gateway"];
        let gateway = "notStarted";
        if (currentGateway === "Gate 1") {
          gateway = "concept";
        } else if (currentGateway === "Gate 2") {
          gateway = "planning";
        } else if (currentGateway === "Gate 3") {
          gateway = "procurement";
        } else if (currentGateway === "Gate 4") {
          gateway = "delivery";
        } else if (currentGateway === "Gate 5") {
          gateway = "finalisation";
        } else if (currentGateway === "Gate 5 Passed") {
          gateway = "finished";
        }

        const details =
          rowData["Description"].length > 0
            ? rowData["Description"]
            : rowData["Name"];
        const status = rowData["Status"].length > 0 ? rowData["Status"] : "TBA";

        const department = rowData.Department;
        // const businessUnit = rowData["Business Unit"];
        const branch = rowData.Branch;

        const startDate = rowData["Start Date"];
        let formatStartDate;
        if (startDate.length > 0) {
          formatStartDate =
            Number(moment(startDate, "DD/MM/YYYY").unix()) * 1000;
        }

        let formatEndDate;
        const endDate = rowData["End Date"];
        if (endDate.length > 0) {
          formatEndDate = Number(moment(endDate, "DD/MM/YYYY").unix()) * 1000;
        }

        let userId;
        if (userName.length > 0) {
          // Find the last space to split last name and first name
          const lastSpaceIndex = userName.lastIndexOf(" ");
          if (lastSpaceIndex === -1) {
            throw new Error(
              'Invalid name format. Expected "LASTNAME FIRSTNAME".', 
            );
          }

          const lastName = userName.slice(0, lastSpaceIndex);
          const firstName = userName.slice(lastSpaceIndex + 1);

          // Capitalize the first letter of each part
          const capitalize = (str) =>
            str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

          const capitalizedLastName = lastName
            .split(" ")
            .map(capitalize)
            .join(" ");
          const capitalizedFirstName = capitalize(firstName);

          const fullName = `${capitalizedFirstName} ${capitalizedLastName}`;

          if (fullName && fullName.length > 0) {
            userId = await handleUser(fullName);
          }
        }

        if (!userId) {
          userId = getUserId();
        }

        let departmentId;
        if (department && department.length > 0) {
          departmentId = await handleDepartment(department);
        }

        // Keep Removed
        // let businessUnitId;
        // if (businessUnit && businessUnit.length > 0) {
        //   businessUnitId = await handleBusinessUnit(businessUnit);
        // }

        let branchId;
        if (branch && branch.length > 0) {
          branchId = await handleBranch(branch);
        }

        const program = rowData.Program;
        const programId = await handleProgram(program);

        const subprogram = rowData.Subprogram;
        const subprogramId = await handleSubProgram(programId, subprogram);

        const concept =
          rowData["Concept & Feasibility"].length > 0
            ? rowData["Concept & Feasibility"]
            : null;
        const planning =
          rowData["Planning & Design"].length > 0
            ? rowData["Planning & Design"]
            : null;
        const procurement =
          rowData["Procurement"].length > 0 ? rowData["Procurement"] : null;
        const delivery =
          rowData["Delivery & Construction"].length > 0
            ? rowData["Delivery & Construction"]
            : null;
        const finalisation =
          rowData["Finalisation"].length > 0 ? rowData["Finalisation"] : null;

        const gateways = {
          concept: {
            start: concept ? parseDate(concept) : null,
            end: null,
          },
          planning: {
            start: planning ? parseDate(planning) : null,
            end: null,
          },
          procurement: {
            start: procurement ? parseDate(procurement) : null,
            end: null,
          },
          delivery: {
            start: delivery ? parseDate(delivery) : null,
            end: null,
          },
          finalisation: {
            start: finalisation ? parseDate(finalisation) : null,
            end: null,
          },
        };

        const createPayload = {
          title: projectName,
          details,
          lifecycle: lifecycle.toLowerCase(),
          subprogram: subprogramId,
          already_existing_project: projectExistingId ? true : false,
          existing_project_id: projectExistingId,
          status: status,
          owner: userId,
          gateways: gateways,
          currentGateway: gateway,
          department: departmentId,
          // businessUnit: businessUnitId,
          branch: branchId,
          startDate: formatStartDate,
          endDate: formatEndDate,
        };

        const response = await apiRequest({
          api: "CreateProjects",
          body: createPayload,
        });

        if (response.error) {
          enqueueSnackbar(response.error, { variant: "error" });
          return;
        }

        console.log("Created Project! ", response);

        const budget = rowData["Budget"];
        const reprovision = rowData["Reprovision"];

        const priorityPayload = {
          cost: Number(budget),
          year: "2024/2025",
          strength: Number(0),
          subprogram: subprogramId,
          project: response._id,
          reprovision,
        };

        const priorityResponse = await apiRequest({
          api: "CreateProjectPriority",
          body: priorityPayload,
        });

        if (priorityResponse.error) {
          enqueueSnackbar(priorityResponse.error, { variant: "error" });
          return;
        }

        console.log("Created Project Budget");

        await handleCreateForecast(rowData, response._id);

        setAddedData(i + 1);
      }
    }
    setOpen(false);
  }

  async function handleCreateForecast(data, project) {
    const yearLabel = `${Number(data["Year"])}/${Number(data["Year"]) + 1}`;

    console.log("PROJECT: ", project);

    const forecastArray = [
      Number(data["July"]),
      Number(data["August"]),
      Number(data["September"]),
      Number(data["October"]),
      Number(data["November"]),
      Number(data["December"]),
      Number(data["January"]),
      Number(data["February"]),
      Number(data["March"]),
      Number(data["April"]),
      Number(data["May"]),
      Number(data["June"]),
    ];

    const forecasts = forecastArray.map((cost, index) => {
      return {
        month: index, // months starting from 0
        cost: cost,
      };
    });

    const payload = {
      project: project,
      year: yearLabel,
      forecasts: forecasts,
      isActive: true,
    };

    const createResponse = await apiRequest({
      api: "CreateForecasts",
      body: payload,
    });

    if (createResponse.error) {
      enqueueSnackbar(createResponse.error, { variant: "error" });
      return;
    }
    console.log("Created Forecast ", createResponse);
  }

  const csvPath = "/docs/forecast-import-template.csv";

  return (
    <div style={{ padding: "1rem", width: "30rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "1rem",
          marginRight: "5px",
        }}
      >
        <Typography variant="h3" fontWeight={500}>
          Import Forecasts
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div
        style={{
          marginLeft: "1rem",
          marginRight: "1rem",
          marginTop: "1rem",
          marginBottom: "5px",
        }}
      >
        {error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}

        {selectedFile ? (
          <Box
            sx={{
              border: 1,
              borderRadius: 2,
              borderColor: "#DADADA",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 1.5,
              paddingTop: 2,
              paddingBottom: 2,
              marginBottom: "2rem",
            }}
          >
            {rows && (
              <>
                <Typography variant="subtitle1">
                  Importing {selectedFile.name} {addedData}/{rows.length}
                </Typography>
                <Typography variant="body" fontSize={12}>
                  Do not refresh page!
                </Typography>
                <LinearProgress sx={{ mt: 2 }} />
              </>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              border: 1,
              borderRadius: 2,
              borderColor: "#DADADA",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 1.5,
              cursor: "pointer",
              paddingTop: 2,
              paddingBottom: 2,
              marginBottom: "1.5rem",
            }}
            onClick={handleFileClick}
          >
            <Typography>Select a CSV file to upload</Typography>
            {/* Hidden input element for file selection */}
            <input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".csv"
            />
          </Box>
        )}

        <Link
          href={csvPath}
          download="forecast-template.csv"
          sx={{ cursor: "pointer" }}
        >
          Download Template
        </Link>
      </div>
    </div>
  );
};

export default ForecastImport;
