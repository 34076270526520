import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { Box, ButtonBase } from "@mui/material";

// project imports
import config from "config";
import { MENU_OPEN } from "store/actions";
import { apiRequest } from "Helper/ApiRequest";
import { useState } from "react";
import { useEffect } from "react";

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();

  const [details, setDetails] = useState({
    title: null,
    logo: null,
  });

  async function handleFetchLogo() {
    const response = await apiRequest({
      api: "GetSettings",
    });

    setDetails({
      title: response.title,
      logo: response.logo,
    });
  }

  useEffect(() => {
    handleFetchLogo();
  }, []);

  return (
    <ButtonBase
      disableRipple
      onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })}
      component={Link}
      to={config.defaultPath}
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        boxSizing: "border-box",
        backgroundColor: "transparent",
        color: "#1C252E",
        outline: "0px",
        border: 0,
        margin: 0,
        borderRadius: 0,
        cursor: "pointer",
        fontFamily:
          '"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      }}
    >
      {details.logo && (
        <Box
          component="img"
          alt="Logo"
          src={details.logo}
          sx={{
            height: "35px",
            width: "auto",
            maxWidth: "100%", // Ensure it scales with container
          }}
        />
      )}
    </ButtonBase>
  );
};

export default LogoSection;
