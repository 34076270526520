import React, { useState, useEffect } from "react";
import Grid from "./Grid";
import Tasks from "./Tasks";
import TimeTable from "./TimeTable";
import { apiRequest } from "Helper/ApiRequest";
import { Pagination, Stack } from "@mui/material";
import Bugsnag from "@bugsnag/js";

export default function GanttChart({
  filter,
  selectedToday,
  setSelectedToday,
  selectedYear,
}) {
  const [pagination, setPagination] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [taskDurations, setTaskDurations] = useState(null);
  const [timeRange, setTimeRange] = useState({
    fromSelectMonth: 0,
    fromSelectYear: "2024",
    toSelectMonth: 11,
    toSelectYear: "2025",
  });

  useEffect(() => {
    setTimeRange({
      fromSelectMonth: 0,
      fromSelectYear: `${selectedYear}`,
      toSelectMonth: 11,
      toSelectYear: `${selectedYear + 4}`,
    });
  }, [selectedYear]);

  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  async function handleFetchTasksData() {
    var params = "";

    if (filter.department) {
      params += `&department=${filter.department._id}`;
    }

    if (filter.businessUnit) {
      params += `&businessUnit=${filter.businessUnit._id}`;
    }

    if (filter.branch) {
      params += `&branch=${filter.branch._id}`;
    }

    if (filter.owner) {
      params += `&owner=${filter.owner._id}`;
    }

    if (selectedYear) {
      params += `&year=${selectedYear}`;
    }

    params += `&isMonth=${false}`;
    params += `&endYear=${selectedYear + 4}`;

    const response = await apiRequest({
      api: "GetGantt",
      params: `?page=${page}${params}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return
    }

    setPagination(response.pagination);
    setTasks(response.tasks);
    setTaskDurations(response.taskDurations);
  }

  useEffect(() => {
    handleFetchTasksData();
  }, []);

  useEffect(() => {
    handleFetchTasksData();
  }, [filter, timeRange, page]);

  return (
    <div id="gantt-container" style={{ overflowY: "auto" }}>
      <Grid>
        <Tasks tasks={tasks} />
        <TimeTable
          timeRange={timeRange}
          tasks={tasks}
          taskDurations={taskDurations}
          setTimeRange={setTimeRange}
          selectedToday={selectedToday}
          setSelectedToday={setSelectedToday}
          selectedYear={selectedYear}
        />
      </Grid>
      {pagination && (
        <Stack spacing={2} mt={3}>
          <Pagination
            count={pagination.totalPages}
            page={page}
            onChange={handleChange}
            shape="rounded"
          />
        </Stack>
      )}
    </div>
  );
}
