import MainCard from "ui-component/cards/MainCard";
import * as React from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import KeyIcon from "@mui/icons-material/Key";
import BadgeIcon from "@mui/icons-material/Badge";
import { TabContext, TabPanel } from "@mui/lab";
import ProfileDetails from "./components/ProfileDetails";
import ProfileSecurity from "./components/ProfileSecurity";
import { Breadcrumbs, Stack, Tabs, Typography } from "@mui/material";

const ProfilePage = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <MainCard>
      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Your Account
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Dashboard
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
                color="text.primary"
              >
                Account
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Stack>
      {/* Tabs */}
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            ml: 3,
            overflow: "hidden",
            minHeight: "48px",
            display: "flex",
            "& .MuiTabs-indicator": {
              background: "black",
              height: "2px", // Indicator height
              width: "93px !important",
              borderRadius: "4px", // Optional: Rounded corners
            },
            "& .MuiTab-root": {
              minWidth: 0, // Adjust tab width if needed
              width: "90px !important",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
            "& .Mui-selected": {
              color: `black !important`, // Force selected tab text color
            },
            "& .MuiTabs-flexContainer": {
              // backgroundColor: theme.palette.text.primary, // Indicator color
              gap: "20px",
            },
          }}
        >
          <Tab
            value="1"
            label={
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  maxWidth: "360px",
                  minWidth: "68px",
                  gap: "8px",
                }}
              >
                <BadgeIcon
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Profile
                </Typography>
              </Box>
            }
          />
          <Tab
            sx={{
              minWidth: "70px",
            }}
            value="2"
            label={
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  maxWidth: "360px",
                  minWidth: "68px",
                  gap: "8px",
                }}
              >
                <KeyIcon
                  sx={{
                    width: "24px",
                    height: "24px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Security
                </Typography>
              </Box>
            }
          />
        </Tabs>

        <TabPanel value="1">
          <ProfileDetails />
        </TabPanel>
        <TabPanel value="2">
          <ProfileSecurity />
        </TabPanel>
      </TabContext>
    </MainCard>
  );
};

export default ProfilePage;
