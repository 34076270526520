import {
  Box,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { IconSearch, IconArrowBack, IconSelector, IconX } from "@tabler/icons";
import { useEffect } from "react";
import { useRef } from "react";

const searchStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

const DocumentationSearch = ({ open, search, setSearch, sections, filteredArticles, handleItemClick, handleClose}) => {
  const textFieldRef = useRef(null);

  useEffect(() => {
    if (open && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    console.log(textFieldRef);
  }, [textFieldRef]);

  return (
    <Box sx={searchStyle}>
      <TextField
        inputRef={textFieldRef}
        placeholder="Search articles..."
        fullWidth
        variant="standard"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <IconSearch stroke={2} size={15} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClose}>
                <IconX stroke={2} size={15} />
              </IconButton>
            </InputAdornment>
          ),
          style: {
            borderRadius: 0,
            padding: "10px 12px",
            fontSize: "14px",
          },
        }}
      />
      <Divider />
      {/* Display filtered articles */}
      {search.length > 0 ? (
        <Box>
          <List sx={{ mx: 1, maxHeight: 400, overflowY: "auto" }}>
            {filteredArticles.map((article) => (
              <ListItem
                key={article.id}
                disablePadding
                button
                onClick={() => {
                  const sectionIndex = sections.findIndex(
                    (section) =>
                      section.articles.findIndex((a) => a.id === article.id) !==
                      -1
                  );
                  const articleIndex = sections[
                    sectionIndex
                  ].articles.findIndex((a) => a.id === article.id);
                  handleItemClick(article, sectionIndex, articleIndex);
                }}
              >
                <ListItemButton role={undefined} dense>
                  <ListItemText primary={article.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: "flex", ml: 1.5, py: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
              <IconSelector
                stroke={2}
                size={17}
                style={{ marginRight: "2px" }}
              />
              <Typography>Navigate</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconArrowBack
                stroke={1.5}
                size={17}
                style={{ marginRight: "3px" }}
              />
              <Typography>Search</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DocumentationSearch;
