/* eslint-disable */
import { Box, Autocomplete, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { apiRequest, pagingApiRequest } from "Helper/ApiRequest";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";

const GanttFilter = ({ filter, setFilter }) => {
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [branches, setBranches] = useState([]);

  async function handleFetchUsers() {
    try {
      let allUsers = [];
      let currentPage = 1;
      let totalPages;

      do {
        const response = await pagingApiRequest({
          api: "ListUsers",
          page: currentPage,
        });

        if (response.error) {
          throw new Error(response.error);
        }

        allUsers = allUsers.concat(response.data);
        totalPages = response.settings.pages;
        currentPage += 1;
      } while (currentPage <= totalPages);
      setUsers(allUsers);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  async function handleFetchDepartments() {
    try {
      let allDepartments = [];
      let currentPage = 1;
      let totalPages;

      do {
        const response = await pagingApiRequest({
          api: "ListDepartments",
          page: currentPage,
        });

        if (response.error) {
          throw new Error(response.error);
        }

        allDepartments = allDepartments.concat(response.data);
        totalPages = response.settings.pages;
        currentPage += 1;
      } while (currentPage <= totalPages);

      setDepartments(allDepartments);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  async function handleFetchBusinessUnits() {
    try {
      let allBusinessUnit = [];
      let currentPage = 1;
      let totalPages;

      do {
        const response = await pagingApiRequest({
          api: "ListBusinessUnit",
          page: currentPage,
        });

        if (response.error) {
          throw new Error(response.error);
        }

        allBusinessUnit = allBusinessUnit.concat(response.data);
        totalPages = response.settings.pages;
        currentPage += 1;
      } while (currentPage <= totalPages);

      setBusinessUnits(allBusinessUnit);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  async function handleFetchBranches() {
    try {
      let allBranch = [];
      let currentPage = 1;
      let totalPages;

      do {
        const response = await pagingApiRequest({
          api: "ListBranch",
          page: currentPage,
        });

        if (response.error) {
          throw new Error(response.error);
        }

        allBranch = allBranch.concat(response.data);
        totalPages = response.settings.pages;
        currentPage += 1;
      } while (currentPage <= totalPages);

      setBranches(allBranch);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  useEffect(() => {
    handleFetchUsers();
    handleFetchDepartments();
    handleFetchBusinessUnits();
    handleFetchBranches();
  }, []);

  async function handleClear() {
    setFilter({
      owner: "",
      department: "",
      businessUnit: "",
      branch: "",
    });
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Box
      sx={{
        minWidth: "700px",
        maxWidth: "calc(-4px + 100vw)",
        flex: "1 1 0%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          padding: "16px",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={handleClear}
          sx={{
            padding: "2px",
            backgroundColor: "rgba(145, 158, 171, 0.16)",
          }}
        >
          <CloseIcon
            sx={{
              width: "16px",
              height: "16px",
            }}
          />
        </IconButton>

        <Autocomplete
          fullWidth
          options={users}
          value={filter.owner}
          getOptionLabel={(option) =>
            option && option.firstName && option.lastName
              ? `${capitalizeFirstLetter(option.firstName)} ${capitalizeFirstLetter(option.lastName)}`
              : ""
          }
          onChange={(event, newValue) =>
            setFilter({
              ...filter,
              owner: newValue,
            })
          }
          sx={{
            height: 50,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#ddd",
              borderRadius: "8px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#1C252E",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#1C252E",
            },
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              padding: "16px 12px",
              fontSize: "14px",
              background: "white",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
            "& .MuiSelect-icon": {
              color: "#1C252E",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Owner"
              sx={{
                height: 50,
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1C252E",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1C252E",
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: "16px 12px",
                  fontSize: "14px",
                  background: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiSelect-icon": {
                  color: "#1C252E",
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {capitalizeFirstLetter(option.firstName)} {capitalizeFirstLetter(option.lastName)}
            </li>
          )}
        />

        <Autocomplete
          fullWidth
          options={departments}
          value={filter.department}
          getOptionLabel={(option) =>
            option && option.title ? option.title : ""
          }
          onChange={(event, newValue) =>
            setFilter({
              ...filter,
              department: newValue,
            })
          }
          sx={{
            height: 50,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#ddd",
              borderRadius: "8px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#1C252E",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#1C252E",
            },
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              padding: "16px 12px",
              fontSize: "14px",
              background: "white",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
            "& .MuiSelect-icon": {
              color: "#1C252E",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Department"
              sx={{
                height: 50,
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1C252E",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1C252E",
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: "16px 12px",
                  fontSize: "14px",
                  background: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiSelect-icon": {
                  color: "#1C252E",
                },
              }}
            />
          )}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
        />

        <Autocomplete
          fullWidth
          options={businessUnits}
          value={filter.businessUnit}
          getOptionLabel={(option) =>
            option && option.title ? option.title : ""
          }
          onChange={(event, newValue) =>
            setFilter({
              ...filter,
              businessUnit: newValue,
            })
          }
          sx={{
            height: 50,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#ddd",
              borderRadius: "8px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#1C252E",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#1C252E",
            },
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              padding: "16px 12px",
              fontSize: "14px",
              background: "white",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
            "& .MuiSelect-icon": {
              color: "#1C252E",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Business Unit"
              sx={{
                height: 50,
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1C252E",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1C252E",
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: "16px 12px",
                  fontSize: "14px",
                  background: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiSelect-icon": {
                  color: "#1C252E",
                },
              }}
            />
          )}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
        />

        <Autocomplete
          fullWidth
          options={branches}
          value={filter.branch}
          getOptionLabel={(option) =>
            option && option.title ? option.title : ""
          }
          onChange={(event, newValue) =>
            setFilter({
              ...filter,
              branch: newValue,
            })
          }
          sx={{
            height: 50,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#ddd",
              borderRadius: "8px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#1C252E",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#1C252E",
            },
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              padding: "16px 12px",
              fontSize: "14px",
              background: "white",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
            "& .MuiSelect-icon": {
              color: "#1C252E",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Branch"
              sx={{
                height: 50,
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1C252E",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1C252E",
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: "16px 12px",
                  fontSize: "14px",
                  background: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiSelect-icon": {
                  color: "#1C252E",
                },
              }}
            />
          )}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
        />
      </Box>
    </Box>
  );
};

export default GanttFilter;
