import MainCard from "ui-component/cards/MainCard";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  InputAdornment,
  Pagination,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { useState } from "react";
import { pagingApiRequest } from "Helper/ApiRequest";
import moment from "moment/moment";
import AddIcon from "@mui/icons-material/Add";
import UtilitiesCreatePage from "./Components/UtilitiesCreate";
import { handleGetStrength } from "Helper/Tokens";
import { TabContext } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import ReactGA from "react-ga4";
import Bugsnag from "@bugsnag/js";

const UtilitiesPage = () => {
  const [page, setPage] = useState(1);

  // const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [utilities, setUtilities] = useState([]);

  const [filter, setFilter] = useState("Department");

  const handleChangeFilter = (event, value) => {
    setFilter(value);
  };

  const [search, setSearch] = useState("");
  const onChangeHandler = (event) => {
    setSearch(event.target.value);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    fetchUtilities();

    // Google Anayltics
    ReactGA.send({
      hitType: "pageview",
      page: "/utilities",
      title: "Utilities",
    });
  }, [filter, page]);

  useEffect(() => {
    if (!open) {
      fetchUtilities();
    }
  }, [open]);

  useEffect(() => {
    setPage(1);
    fetchUtilities();
  }, [search]);

  const unixToDate = (time) => {
    var day = moment.unix(time);
    return day.format("MMMM Do YYYY, h:mm a");
  };

  async function fetchUtilities() {
    setUtilities([]); // Clear previous data
    let response;

    const queryParams = new URLSearchParams();
    if (search) {
      queryParams.append("search", search);
    }

    // Fetch data based on the selected filter
    switch (filter) {
      case "Department":
        response = await pagingApiRequest({
          api: "ListDepartments",
          params: `?${queryParams.toString()}`,
          page,
        });
        break;
      case "Business Unit":
        response = await pagingApiRequest({
          api: "ListBusinessUnit",
          params: `?${queryParams.toString()}`,
          page,
        });
        break;
      case "Branch":
        response = await pagingApiRequest({
          api: "ListBranch",
          params: `?${queryParams.toString()}`,
          page,
        });
        break;
      default:
        console.log("Invalid filter");
        return;
    }
    // Check for errors and update state with the fetched data
    if (response.error) {
      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
    } else {
      setUtilities(response);
    }
  }

  const canCreateUtilities = () => {
    return handleGetStrength() >= 3;
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <MainCard>
      {/* Create user popup */}
      <React.Fragment>
        <Dialog open={open} onClose={handleClose}>
          <UtilitiesCreatePage setOpen={setOpen} utility={filter} />
        </Dialog>
      </React.Fragment>
      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Utilities
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Dashboard
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
                color="text.primary"
              >
                Utilities
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box sx={{ flexShrink: 0 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                boxSizing: "border-box",
                cursor: "pointer",
                verticalAlign: "middle",
                backgroundColor: "#1C252E",
                textDecoration: "none",
                borderRadius: "8px",
                padding: "6px 12px",
                minWidth: "64px",
                boxShadow: "none",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                "&:hover": {
                  backgroundColor: "#2D3A45",
                  boxShadow: "none",
                },
              }}
              disabled={!canCreateUtilities()}
              onClick={handleClickOpen}
            >
              New utility
            </Button>
          </Box>
        </Box>
      </Stack>
      {/* Table */}
      <Card
        sx={{
          color: "#1C252E",
          backgroundImage: "none",
          position: "relative",
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        {/* Tabs */}
        <TabContext value={filter}>
          <Tabs
            value={filter}
            onChange={handleChangeFilter}
            sx={{
              overflow: "hidden",
              minHeight: "48px",
              display: "flex",
              ml: 1,
              "& .MuiTabs-indicator": {
                // backgroundColor: theme.palette.text.primary, // Indicator color
                background: "black",
                height: "2px", // Indicator height
                width: "130px !important",
                borderRadius: "4px", // Optional: Rounded corners
              },
              "& .MuiTab-root": {
                minWidth: 0, // Adjust tab width if needed
                width: "130px !important",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              },
              "& .Mui-selected": {
                color: `black !important`, // Force selected tab text color
                // fontWeight: 'bold', // Optional: Make selected tab text bold
              },
              "& .MuiTabs-flexContainer": {
                // backgroundColor: theme.palette.text.primary, // Indicator color
                // gap: "20px",
              },
            }}
          >
            <Tab value="Department" label="Department" />
            <Tab value="Business Unit" label="Business Unit" />
            <Tab value="Branch" label="Branch" />
          </Tabs>
        </TabContext>
        {/* Table Toolbar */}
        <Stack
          sx={{
            display: "flex",
            paddingY: "20px",
            alignItems: "center",
            paddingRight: "8px",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignItems: "center",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Search..."
              onChange={onChangeHandler}
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
              }}
            />
          </Stack>
        </Stack>
        {/* Active Search */}
        {search && search.length > 0 && (
          <Box sx={{ padding: "0px 0px 20px" }}>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexWrap: "wrap",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    gap: "8px",
                    display: "flex",
                    padding: "8px",
                    borderRadius: "8px",
                    overflow: "hidden",
                    border: "dashed 1px rgba(145, 158, 171, 0.2)",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      lineHeight: "24px",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Search:
                  </Typography>
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      endIcon={
                        <CancelIcon
                          sx={{
                            cursor: "pointer",
                            width: 16,
                            color: "rgba(28, 37, 46, 0.26)",
                            margin: "0px 4px 0px -4px",
                            mr: 1,
                          }}
                        />
                      }
                      onClick={() => {
                        setSearch("");
                      }}
                      sx={{
                        position: "relative",
                        userSelect: "none",
                        appearance: "none",
                        maxWidth: "100%",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "24px",
                        color: "#1C252E",
                        cursor: "unset",
                        verticalAlign: "middle",
                        boxSizing: "border-box",
                        backgroundColor: "rgba(145, 158, 171, 0.16)",
                        margin: "0px",
                        whiteSpace: "nowrap",
                        transition:
                          "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                        outline: "0px",
                        textDecoration: "none",
                        borderWidth: "0px",
                        borderStyle: "initial",
                        borderColor: "initial",
                        borderImage: "initial",
                        padding: "0px",
                        borderRadius: "8px",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          px: "8px",
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        {search}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                <Button
                  startIcon={<DeleteIcon />}
                  sx={{
                    color: "#FF5630",
                    fontWeight: 700,
                    minWidth: "64px",
                    fontSize: 14,
                    backgroundColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                  onClick={() => {
                    setSearch("");
                  }}
                >
                  Clear
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {/* Content */}
        <Box
          sx={{
            position: "relative",
            overflowX: "auto", // Enable horizontal scrolling
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  color: "#637381",
                  backgroundColor: "#F4F6F8",
                  outline: "0px",
                }}
              >
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Created
                </TableCell>

                <TableCell
                  sx={{
                    width: "48px",
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {utilities.data &&
                utilities.data.map((utility) => (
                  <TableRow key={utility._id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {utility._id}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {utility.title}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {unixToDate(utility.inserted)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {/* Pagination */}
        <Box
          sx={{
            position: "relative",
            alignItems: "flex-start",
          }}
        >
          {utilities.settings && (
            <Pagination
              count={utilities.settings.pages}
              page={page}
              onChange={handleChange}
              sx={{ mt: 4 }}
            />
          )}
        </Box>
      </Card>
    </MainCard>
  );
};

export default UtilitiesPage;
