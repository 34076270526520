import { FormGroup, TextField, Button, Typography, Box } from "@mui/material";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import "assets/scss/user.scss";
import { apiRequest } from "Helper/ApiRequest";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const UtilitiesCreatePage = ({ setOpen, utility }) => {
  const [title, setTitle] = useState("");

  const handleCloseCreate = () => {
    setOpen(false);
  };

  const createUtility = async () => {
    const payload = {
      title: title,
    };

    let response;

    switch (utility) {
      case "Priority":
        response = await apiRequest({
          api: "CreatePriorities",
          body: payload,
        });
        break;
      case "Department":
        response = await apiRequest({
          api: "CreateDepartments",
          body: payload,
        });
        break;
      case "Business Unit":
        response = await apiRequest({
          api: "CreateBusinessUnit",
          body: payload,
        });
        break;
      case "Branch":
        response = await apiRequest({
          api: "CreateBranch",
          body: payload,
        });
        break;
      default:
        enqueueSnackbar("Invalid filter", {
          variant: "error",
        });
        return;
    }

    if (response.error) {
      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
    } else {
      handleCloseCreate();
      enqueueSnackbar(`Created ${utility}`, {
        variant: "success",
      });
    }
  };

  return (
    <Box sx={{ minWidth: 500 }}>
      <DialogTitle
        sx={{
          pb: 0,
          fontSize: 22,
          fontWeight: 600,
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
      >{`Create ${utility}`}</DialogTitle>
      <Typography
        sx={{
          ml: 3,
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
      >
        Enter details for the new {utility}
      </Typography>
      <DialogContent>
        <FormGroup>
          <TextField
            variant="outlined"
            fullWidth
            label="Title"
            onChange={(event) => setTitle(event.target.value)}
            inputProps={{
              style: {
                height: "25px",
                backgroundColor: "white",
              },
            }}
            sx={{
              "& .MuiInputBase-root": {
                backgroundColor: "white",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ddd",
                borderRadius: "8px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
              "& .MuiFormLabel-root": {
                color: "#888", // Change this to the desired color
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#000", // Change this to the desired color when focused
              },
            }}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions className="mb-4 mx-3">
        <Button
          onClick={handleCloseCreate}
          autoFocus
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "transparent",
            color: "black",
            borderColor: "rgba(145, 158, 171, 0.32)",
            borderWidth: "1px",
            borderStyle: "solid",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          Cancel
        </Button>

        <Button
          sx={{
            fontWeight: 700,
            fontSize: 15,
            color: "white",
            minWidth: "64px",
            boxShadow: "none",
            padding: "8px 16px",
            borderRadius: "8px",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            backgroundColor: "#1C252E",
            "&:hover": {
              backgroundColor: "#2D3A45",
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: "#2D3A45",
              boxShadow: "none",
              color: "white",
            },
          }}
          disabled={title.length > 0 ? false : true}
          onClick={() => {
            createUtility();
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Box>
  );
};

export default UtilitiesCreatePage;

UtilitiesCreatePage.propTypes = {
  open: PropTypes.any,
};
