import { apiRequest } from "Helper/ApiRequest";
import { useEffect } from "react";
import { useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Dialog,
  IconButton,
  InputAdornment,
  Link,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Fragment } from "react";
import SubprogramCreate from "./components/SubprogramCreate";
import { handleGetStrength } from "Helper/Tokens";
import SubprogramDelete from "./components/SubprogramDelete";
import { enqueueSnackbar } from "notistack";
import ReactGA from "react-ga4";
import Bugsnag from "@bugsnag/js";

const Subprogram = () => {
  const { id } = useParams();
  const [program, setProgram] = useState(null);
  const [subprograms, setSubprograms] = useState([]);

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const [subprogramBudgets, setSubprogramBudgets] = useState({});

  const [search, setSearch] = useState(null);

  const [selected, setSelected] = useState([]);
  const [editSelected, setEditSelected] = useState(null);

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleCloseDelete = (e) => {
    e.preventDefault();
    setDeleteOpen(false);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (!open || !deleteOpen || !editOpen) {
      fetchProgram();
      fetchSubprogram();
    }
  }, [open, deleteOpen, editOpen, page]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleClickOpen = () => {
    if (canUserCreate()) {
      setOpen(true);
    } else {
      enqueueSnackbar(
        "Error: You don't have permission to create subprograms.",
        { variant: "error" }
      );
    }
  };

  const onChangeHandler = (event) => {
    setSearch(event.target.value);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleClickEditOpen = () => {
    if (canUserCreate()) {
      setEditOpen(true);
    } else {
      enqueueSnackbar("Error: You don't have permission to edit programs.", {
        variant: "error",
      });
    }
  };

  const handleEditClose = (e) => {
    e.preventDefault();
    setEditOpen(false);
  };

  async function fetchProgram() {
    const response = await apiRequest({
      api: "ListPrograms",
      params: `/${id}`,
    });
    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    } else {
      setProgram(response);
    }
  }

  async function fetchSubprogram() {
    try {
      // Conditionally build query parameters
      const queryParams = new URLSearchParams();

      if (search) {
        queryParams.append("search", search);
      }

      if (page) {
        queryParams.append("page", page);
      }

      // Construct the full URL with query parameters
      const response = await apiRequest({
        api: "ListSubPrograms",
        params: `/${id}?${queryParams.toString()}`,
      });

      if (response.error) {
        enqueueSnackbar(response.error, { variant: "error" });

        // Bugsnag error
        Bugsnag.notify(new Error(response.error));
        return;
      } else {
        setSubprograms(response);
      }
    } catch (error) {
      enqueueSnackbar(error.message || "An unexpected error occurred", {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(error));
    }
  }

  async function fetchSubprogramBudget(id) {
    const response = await apiRequest({
      api: "GetSubprogramBudget",
      params: `?subprogram=${id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return 0;
    }

    if (response.length > 0) {
      const totalCost = response.reduce((sum, item) => sum + item.cost, 0);
      return totalCost;
    }
    return 0;
  }

  useEffect(() => {
    fetchProgram();
    fetchSubprogram();

    // Google Anayltics
    ReactGA.send({
      hitType: "pageview",
      page: "/subprograms",
      title: "SubPrograms",
    });
  }, [search]);

  useEffect(() => {
    if (subprograms && subprograms.data) {
      const fetchData = async () => {
        for (let i = 0; i < subprograms.data.length; i++) {
          const subprogramId = subprograms.data[i]._id;
          const total = await fetchSubprogramBudget(subprogramId);
          const payload = {
            [subprogramId]: total,
          };
          setSubprogramBudgets((subprogramBudgets) => ({
            ...subprogramBudgets,
            ...payload,
          }));
        }
      };

      fetchData();
    }
  }, [subprograms]);

  const unixToDate = (time) => {
    var day = moment.unix(time);
    return day.format("MMMM Do YYYY, h:mm a");
  };

  const canUserCreate = () => {
    return handleGetStrength() >= 4;
  };

  return (
    <MainCard>
      {/* Create program popup */}
      <Fragment>
        <Dialog open={open} onClose={handleClose}>
          <SubprogramCreate setOpen={setOpen} program={program} edit={false} />
        </Dialog>
      </Fragment>
      {/* Edit program popup */}
      {program && editSelected && (
        <Fragment>
          <Dialog open={editOpen} onClose={handleEditClose} fullWidth>
            <SubprogramCreate
              setOpen={setEditOpen}
              program={program}
              edit={true}
              subprogramId={editSelected}
            />
          </Dialog>
        </Fragment>
      )}

      {/* Delete program popup */}
      <Fragment>
        <Dialog open={deleteOpen} onClose={handleCloseDelete}>
          <SubprogramDelete
            setOpen={setDeleteOpen}
            selected={selected}
            setSelected={setSelected}
          />
        </Dialog>
      </Fragment>
      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Sub-Program
            </Typography>
            {program && (
              <Breadcrumbs aria-label="breadcrumb">
                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Dashboard
                </Typography>

                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Programs
                </Typography>

                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {program.title}
                </Typography>

                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                  color="text.primary"
                >
                  Sub-Programs
                </Typography>
              </Breadcrumbs>
            )}
          </Box>
          <Box sx={{ flexShrink: 0 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                boxSizing: "border-box",
                cursor: "pointer",
                verticalAlign: "middle",
                backgroundColor: "#1C252E",
                textDecoration: "none",
                borderRadius: "8px",
                padding: "6px 12px",
                minWidth: "64px",
                boxShadow: "none",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                "&:hover": {
                  backgroundColor: "#2D3A45",
                  boxShadow: "none",
                },
              }}
            >
              New sub-program
            </Button>
          </Box>
        </Box>
      </Stack>
      <Card
        sx={{
          color: "#1C252E",
          backgroundImage: "none",
          position: "relative",
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        {/* Table Toolbar */}
        <Stack
          sx={{
            display: "flex",
            paddingY: "20px",
            alignItems: "center",
            paddingRight: "8px",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignItems: "center",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Search..."
              onChange={onChangeHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
              }}
            />
          </Stack>
        </Stack>
        {/* Active Search */}
        {search && search.length > 0 && (
          <Box sx={{ padding: "0px 0px 20px" }}>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexWrap: "wrap",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    gap: "8px",
                    display: "flex",
                    padding: "8px",
                    borderRadius: "8px",
                    overflow: "hidden",
                    border: "dashed 1px rgba(145, 158, 171, 0.2)",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      lineHeight: "24px",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Search:
                  </Typography>
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      endIcon={
                        <CancelIcon
                          sx={{
                            cursor: "pointer",
                            width: 16,
                            color: "rgba(28, 37, 46, 0.26)",
                            margin: "0px 4px 0px -4px",
                            mr: 1,
                          }}
                        />
                      }
                      onClick={() => {
                        setSearch("");
                      }}
                      sx={{
                        position: "relative",
                        userSelect: "none",
                        appearance: "none",
                        maxWidth: "100%",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "24px",
                        color: "#1C252E",
                        cursor: "unset",
                        verticalAlign: "middle",
                        boxSizing: "border-box",
                        backgroundColor: "rgba(145, 158, 171, 0.16)",
                        margin: "0px",
                        whiteSpace: "nowrap",
                        transition:
                          "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                        outline: "0px",
                        textDecoration: "none",
                        borderWidth: "0px",
                        borderStyle: "initial",
                        borderColor: "initial",
                        borderImage: "initial",
                        padding: "0px",
                        borderRadius: "8px",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          px: "8px",
                          whiteSpace: "nowrap",
                          fontWeight: 500,
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        {search}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                <Button
                  startIcon={<DeleteIcon />}
                  sx={{
                    color: "#FF5630",
                    fontWeight: 700,
                    minWidth: "64px",
                    fontSize: 14,
                    backgroundColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                  onClick={() => {
                    setSearch("");
                  }}
                >
                  Clear
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {/* Table */}
        <Box
          sx={{
            position: "relative",
            overflowX: "auto", // Enable horizontal scrolling
          }}
        >
          {/* Selection Overlay */}
          {selected.length > 0 && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                pl: "8px",
                pr: "16px",
                top: "0px",
                left: "0px",
                width: "100%",
                zIndex: 9,
                height: "58px",
                position: "absolute",
                backgroundColor: "#C8FAD6",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  margin: "0px 0px 0px 16px",
                  color: "#00A76F",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  flexGrow: 1,
                }}
              >
                {selected.length} Selected
              </Typography>
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    setDeleteOpen(true);
                  }}
                >
                  <DeleteIcon
                    sx={{ color: "#00A76F", width: "20px", height: "20px" }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  color: "#637381",
                  backgroundColor: "#F4F6F8",
                  outline: "0px",
                }}
              >
                <TableCell
                  sx={{
                    width: "48px",
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Budget
                </TableCell>

                <TableCell
                  sx={{
                    fontWeight: 600,
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Created
                </TableCell>

                <TableCell
                  sx={{
                    width: "48px",
                    borderBottomStyle: "dashed",
                    borderColor: "transparent",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subprograms.data &&
                subprograms.data.map((subprogram) => (
                  <TableRow key={subprogram._id}>
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderBottomStyle: "dashed",
                        width: "48px",
                        color: "#1C252E",
                        padding: "0px 0px 0px 8px",
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isSelected(subprogram._id)}
                        onClick={(event) => handleClick(event, subprogram._id)}
                        sx={{
                          color: "#637381",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {subprogram._id}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      <Link
                        href={`/subprogram/${subprogram._id}`}
                        underline="hover"
                        color="inherit"
                      >
                        {subprogram.title}
                      </Link>
                    </TableCell>
                    {subprogram._id in subprogramBudgets ? (
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottomStyle: "dashed",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        $
                        {subprogramBudgets[subprogram._id].toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 2,
                          }
                        )}
                      </TableCell>
                    ) : (
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottomStyle: "dashed",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        $0
                      </TableCell>
                    )}
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {unixToDate(subprogram.inserted)}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottomStyle: "dashed",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="Edit">
                          <IconButton
                            sx={{ color: "#637381" }}
                            disabled={!canUserCreate()}
                            onClick={() => {
                              setEditSelected(subprogram._id);
                              handleClickEditOpen();
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        {/* Pagination */}
        <Box
          sx={{
            position: "relative",
            alignItems: "flex-start",
          }}
        >
          {subprograms.settings && (
            <Pagination
              count={subprograms.settings.pages}
              page={page}
              onChange={handleChange}
              sx={{ mt: 4 }}
            />
          )}
        </Box>
      </Card>
    </MainCard>
  );
};
export default Subprogram;
