import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useNavigate } from "react-router";

export default function Tasks({ tasks }) {
  const inputRef = useRef([]);
  const indexRef = useRef(null);

  let navigate = useNavigate();

  useEffect(() => {
    if (inputRef.current.length && indexRef.current >= 0) {
      inputRef?.current[indexRef.current]?.focus();
    }
  });

  const statusColours = {
    TBA: {
      colour: "#637381",
      backgroundColor: "rgba(145, 158, 171, 0.16)",
    },
    Monitor: {
      colour: "#B76E00",
      backgroundColor: "rgba(255, 171, 0, 0.16)",
    },
    "On Track": {
      colour: "#118D57",
      backgroundColor: "rgba(34, 197, 94, 0.16)",
    },
    "Action Required": {
      colour: "#B71D18",
      backgroundColor: "rgba(255, 86, 48, 0.16)",
    },
  };

  return (
    <Box id="gantt-grid-container__tasks">
      <Box className="gantt-task-years-row">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
            textAlign: "left",
            width: "100%",
            background: "#f4f5f7",
          }}
        ></Box>
      </Box>
      {tasks &&
        tasks.map((task, i) => (
          <Box
            key={`${i}-${task.id}-${task.name}`}
            className="gantt-task-row"
            style={{
              height: "var(--cell-height)",
              background: i % 2 === 0 ? "#ffffff" : "#f4f5f7", // Alternates background color
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "left",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mx: 0,
                  ml: 2,
                  background: "clear",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/projects/${task.id}`);
                }}
              >
                <Box
                  sx={{
                    fontSize: 13,
                    height: "24px",
                    minWidth: "24px",
                    alignItems: "center",
                    lineHeight: 0,
                    fontWeight: 700,
                    mr: 1,
                    padding: "0px 6px",
                    borderRadius: "6px",
                    color: statusColours[task.status].colour,
                    backgroundColor: statusColours[task.status].backgroundColor,
                    whiteSpace: "nowrap",
                    justifyContent: "center",
                    display: "inline-flex",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  <Tooltip title={task.status}>
                    <AutoAwesomeIcon
                      sx={{
                        width: 16,
                        height: 16,
                      }}
                    />
                  </Tooltip>
                </Box>
                <Typography
                  mr={1}
                  sx={{
                    fontSize: 12,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {task.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
    </Box>
  );
}
