import {
  Badge,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTheme } from "@mui/material/styles";
import { apiRequest } from "Helper/ApiRequest";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import GanttFilter from "../gantt/components/GanttFilter";
import useDropdownStyles from "ui-component/extended/Dropdown";
import { useNavigate } from "react-router";
import moment from "moment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Timeline,
  TimelineConnector,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import ReactGA from "react-ga4";
import Bugsnag from "@bugsnag/js";

const Item = styled(Paper)(({ height, theme }) => ({
  backgroundColor: theme.palette.background.default,
  textAlign: "center",
  height: height || "auto",
  width: "100%", // Ensure Item takes full width of the grid column
  overflow: "hidden", // Prevent overflow
}));

const StyledChart = styled(Chart)(({ theme }) => ({
  width: "100%", // Ensure chart takes full width of the Item
  ".apexcharts-tooltip": {
    background: theme.palette.background.default,
    color: theme.palette.grey[600],
  },
}));

const statusDetails = {
  0: {
    title: "TBA",
    colour: "##00B8D9",
  },
  1: {
    title: "On Track",
    colour: "#00A76F",
  },
  2: {
    title: "Monitor",
    colour: "#FFAB00",
  },
  3: {
    title: "Action Required",
    colour: "#B71D18",
  },
};

const DashboardPage = () => {
  const dropdownClasses = useDropdownStyles("80px");

  const [isLoading, setIsLoading] = useState({
    allocated: true,
    total: true,
    completed: true,
  });

  const [year, setYear] = useState("2024");

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const [filter, setFilter] = useState({
    department: "",
    businessUnit: "",
    branch: "",
    owner: "",
  });

  const [additionalFilter, setAdditionalFilter] = useState({
    lifecycle: null,
    subprogram: null,
    status: null,
  });

  const [dashStats, setDashStats] = useState({
    totalCost: 0,
    total: 0,
    previousYearProjectsCounts: 0,
    differencePercentage: 0,
  });

  let navigate = useNavigate();

  const [yearComplete, setYearComplete] = useState(null);

  const [popularChartData, setPopularChartData] = useState(null);

  const [lifecycleChartData, setLifecycleChartData] = useState(null);

  const [statusChartData, setStatusChartData] = useState(null);

  const [projectGateways, setProjectGateways] = useState(null);

  const theme = useTheme();

  async function handleFetchHealthStats() {
    let params = "";

    if (additionalFilter.lifecycle) {
      params += `&lifecycle=${additionalFilter.lifecycle}`;
    }

    if (additionalFilter.subprogram) {
      params += `&subprogram=${additionalFilter.subprogram}`;
    }

    if (additionalFilter.status) {
      params += `&status=${additionalFilter.status}`;
    }

    if (filter.department) {
      params += `&department=${filter.department._id}`;
    }

    if (filter.businessUnit) {
      params += `&businessUnit=${filter.businessUnit._id}`;
    }

    if (filter.branch) {
      params += `&branch=${filter.branch._id}`;
    }

    if (filter.owner) {
      params += `&owner=${filter.owner._id}`;
    }

    const response = await apiRequest({
      api: "GetStatistics",
      params: `/health?year=${year}${params}`,
    });

    if (response.error) {
      // User not logged in
      if (response.statusCode === 401) {
        navigate("/login");
        return;
      }

      enqueueSnackbar(response.error, { variant: "error" });
      setIsLoading({
        allocated: false,
        total: false,
        completed: false,
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }
    setDashStats(response);
    setIsLoading({
      allocated: false,
      total: false,
      completed: false,
    });
  }

  async function handleFetchPopularStats() {
    let params = "";

    if (additionalFilter.lifecycle) {
      params += `&lifecycle=${additionalFilter.lifecycle}`;
    }

    if (additionalFilter.subprogram) {
      params += `&subprogram=${additionalFilter.subprogram}`;
    }

    if (additionalFilter.status) {
      params += `&status=${additionalFilter.status}`;
    }

    if (filter.department) {
      params += `&department=${filter.department._id}`;
    }

    if (filter.businessUnit) {
      params += `&businessUnit=${filter.businessUnit._id}`;
    }

    if (filter.branch) {
      params += `&branch=${filter.branch._id}`;
    }

    if (filter.owner) {
      params += `&owner=${filter.owner._id}`;
    }

    const response = await apiRequest({
      api: "GetStatistics",
      params: `/popular?year=${year}${params}`,
    });

    if (response.error) {
      // User not logged in
      if (response.statusCode === 401) {
        navigate("/login");
        return;
      }
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    const titles = response.map((item) => item._id);
    const data = response.map((item) => item.totalCost);

    const barChartData = {
      height: 380,
      type: "bar",
      options: {
        chart: {
          height: 380,
          type: "bar",
          toolbar: {
            show: false, // Disable the export button
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const subprogramName = encodeURIComponent(
                String(config.w.config.labels[config.dataPointIndex])
              );
              setAdditionalFilter({
                lifecycle: null,
                status: null,
                subprogram: subprogramName,
              });
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        tooltip: {
          theme: false,
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        grid: {
          borderColor: "rgba(145, 158, 171, 0.3)", // Set the color of the grid lines
          strokeDashArray: 4, // Make the grid lines dashed
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false, // Hide x-axis grid lines
            },
          },
        },
        yaxis: {
          opposite: true, // Move y-axis labels to the right side
          labels: {
            formatter: function (value) {
              if (value) {
                return (
                  `$` +
                  value.toLocaleString(0, {
                    maximumFractionDigits: 2,
                  })
                );
              } else {
                return "$0";
              }
            },
            style: {
              colors: theme.palette.text.secondary,
            },
          },
        },
        labels: titles,
      },
      series: [
        {
          name: "Total",
          data: data,
        },
      ],
    };

    setPopularChartData(barChartData);
  }

  async function handleFetchLifecycleStats() {
    let params = "";

    if (additionalFilter.lifecycle) {
      params += `&lifecycle=${additionalFilter.lifecycle}`;
    }

    if (additionalFilter.subprogram) {
      params += `&subprogram=${additionalFilter.subprogram}`;
    }

    if (additionalFilter.status) {
      params += `&status=${additionalFilter.status}`;
    }

    if (filter.department) {
      params += `&department=${filter.department._id}`;
    }

    if (filter.businessUnit) {
      params += `&businessUnit=${filter.businessUnit._id}`;
    }

    if (filter.branch) {
      params += `&branch=${filter.branch._id}`;
    }

    if (filter.owner) {
      params += `&owner=${filter.owner._id}`;
    }

    const response = await apiRequest({
      api: "GetStatistics",
      params: `/lifecycle?year=${year}${params}`,
    });

    if (response.error) {
      // User not logged in
      if (response.statusCode === 401) {
        navigate("/login");
        return;
      }

      enqueueSnackbar(response.error, { variant: "error" });
      setIsLoading({
        lifecycle: false,
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    const chartData = {
      height: 300,
      type: "donut",
      options: {
        chart: {
          type: "donut",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const labelLowercase = String(
                config.w.config.labels[config.dataPointIndex]
              ).toLowerCase();

              setAdditionalFilter({
                status: null,
                subprogram: null,
                lifecycle: labelLowercase,
              });
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: "straight",
          colors: theme.palette.primary.light,
          width: 2,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          show: false,
        },
        labels: ["New", "Renewal", "Upgrade", "Disposal"],
      },
      series: response,
    };

    setLifecycleChartData(chartData);
    setIsLoading({
      lifecycle: false,
    });
  }

  async function handleFetchStatusData() {
    let params = "";

    if (additionalFilter.lifecycle) {
      params += `&lifecycle=${additionalFilter.lifecycle}`;
    }

    if (additionalFilter.subprogram) {
      params += `&subprogram=${additionalFilter.subprogram}`;
    }

    if (filter.department) {
      params += `&department=${filter.department._id}`;
    }

    if (filter.businessUnit) {
      params += `&businessUnit=${filter.businessUnit._id}`;
    }

    if (filter.branch) {
      params += `&branch=${filter.branch._id}`;
    }

    if (filter.owner) {
      params += `&owner=${filter.owner._id}`;
    }

    const response = await apiRequest({
      api: "GetStatistics",
      params: `/status?year=${year}${params}`,
    });

    if (response.error) {
      // User not logged in
      if (response.statusCode === 401) {
        navigate("/login");
        return;
      }

      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    const statValues = Object.values(response);

    const maxValue = Math.max(...statValues);
    const yAxisMax = maxValue + maxValue * 0.1;

    const statusBarData = {
      height: 330,
      type: "bar",
      series: [
        {
          name: "",
          data: statValues,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false, // Disable the toolbar which includes the export button
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const statusLabel =
                config.w.config.xaxis.categories[config.dataPointIndex];
              setAdditionalFilter({
                lifecycle: null,
                subprogram: null,
                status: statusLabel,
              });
            },
          },
        },
        tooltip: {
          theme: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        grid: {
          borderColor: "rgba(145, 158, 171, 0.3)", // Set the color of the grid lines
          strokeDashArray: 4, // Make the grid lines dashed
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false, // Hide x-axis grid lines
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: [theme.palette.text.secondary],
          },
        },
        xaxis: {
          categories: ["TBA", "On Track", "Monitor", "Action Required"],
          position: "top",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          labels: {
            style: {
              colors: theme.palette.text.secondary,
            },
          },
        },
        yaxis: {
          max: yAxisMax, // Set the maximum value for the y-axis
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      },
    };

    setStatusChartData(statusBarData);
  }

  async function handleFetchGatewaysStats() {
    let params = "";

    if (additionalFilter.lifecycle) {
      params += `&lifecycle=${additionalFilter.lifecycle}`;
    }

    if (additionalFilter.subprogram) {
      params += `&subprogram=${additionalFilter.subprogram}`;
    }

    if (additionalFilter.status) {
      params += `&status=${additionalFilter.status}`;
    }

    if (filter.department) {
      params += `&department=${filter.department._id}`;
    }

    if (filter.businessUnit) {
      params += `&businessUnit=${filter.businessUnit._id}`;
    }

    if (filter.branch) {
      params += `&branch=${filter.branch._id}`;
    }

    if (filter.owner) {
      params += `&owner=${filter.owner._id}`;
    }

    const response = await apiRequest({
      api: "GetStatistics",
      params: `/gateways?year=${year}${params}`,
    });

    if (response.error) {
      // User not logged in
      if (response.statusCode === 401) {
        navigate("/login");
        return;
      }

      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    setProjectGateways(response);
  }

  const gatewaysMap = {
    concept: {
      title: "Concept & Feasibility",
      colour: "#22C55E",
    },
    planning: {
      title: "Planning & Design",
      colour: "#00B8D9",
    },
    procurement: {
      title: "Procurement",
      colour: "#FFAB00",
    },
    delivery: {
      title: "Delivery & Construction",
      colour: "#FF5630",
    },
    finalisation: {
      title: "Finalisation",
      colour: "#8E33FF",
    },
    finished: {
      title: "Finished",
      colour: "#77ED8B",
    },
    notStarted: {
      title: "Not Started",
      colour: "#00A76F",
    },
  };

  useEffect(() => {
    handleFetchHealthStats();
    handleFetchPopularStats();
    handleFetchLifecycleStats();
    handleFetchStatusData();
    handleFetchGatewaysStats();
    calculatePercentageComplete();
  }, [year, filter, additionalFilter]);

  useEffect(() => {
    // Google Anayltics
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Dashboard",
    });
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterBadgeCount = () => {
    const filterArrayValues = Object.values(filter);
    const countNonEmpty = filterArrayValues.filter(
      (item) => item !== null && item.length !== 0
    ).length;
    return countNonEmpty;
  };

  function calculatePercentageComplete() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let financialYearStart = new Date(currentYear, 6, 1); // July 1 (0-based index for month)

    // If current date is before July 1, financial year start is July 1 of the previous year
    if (currentDate < financialYearStart) {
      financialYearStart = new Date(currentYear - 1, 6, 1);
    }

    // Financial year end is June 30 of the next year if start is before July 1, otherwise June 30 of the same year
    const financialYearEnd = new Date(
      financialYearStart.getFullYear() + 1,
      5,
      30
    );

    const totalDaysInYear = Math.ceil(
      (financialYearEnd - financialYearStart) / (1000 * 60 * 60 * 24)
    );
    const daysElapsed = Math.ceil(
      (currentDate - financialYearStart) / (1000 * 60 * 60 * 24)
    );

    const percentageComplete = (daysElapsed / totalDaysInYear) * 100;
    setYearComplete(percentageComplete.toFixed(0)); // Round to 0 decimal places
  }

  const statusTotal = () => {
    const data = statusChartData.series[0].data;
    const total = data.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return total;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100% * 12 / var(12))",
            minWidth: "0px",
            boxSizing: "border-box",
            padding: "calc(var(24px) / 2) calc(var(24px) / 2)",
          }}
        >
          <Card
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 3,
              py: 1,
              height: 150,
            }}
          >
            {isLoading.allocated ? (
              <Box
                sx={{
                  flexGrow: 1,
                  my: 1,
                  display: "flex", // Add this to enable flexbox layout
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ flexGrow: 1, my: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 15,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Total Allocated
                </Typography>
                <Typography
                  sx={{
                    fontSize: 30,
                    fontWeight: 700,
                    mt: "12px",
                    lineHeight: 1.5,
                    mb: "8px",
                    fontFamily:
                      'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  $
                  {dashStats.totalCost.toLocaleString(0, {
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {dashStats.differencePercentage > 0 ? (
                    <ArrowDropUpIcon
                      style={{ color: "#22C55E", fontSize: 32 }}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      style={{ color: "#FF5630", fontSize: 32 }}
                    />
                  )}
                  <Box sx={{ display: "flex", gap: "4px" }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 16,
                        fontFamily:
                          'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {dashStats.differencePercentage
                        ? dashStats.differencePercentage.toLocaleString(0, {
                            maximumFractionDigits: 2,
                          })
                        : 0}
                      %
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 16,
                        color: "#919EAB",
                        fontFamily:
                          'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      last year
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100% * 12 / var(12))",
            minWidth: "0px",
            boxSizing: "border-box",
            padding: "calc(var(24px) / 2) calc(var(24px) / 2)",
          }}
        >
          <Card
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 3,
              py: 1,
              height: 150,
            }}
          >
            {isLoading.total ? (
              <Box
                sx={{
                  flexGrow: 1,
                  my: 1,
                  display: "flex", // Add this to enable flexbox layout
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ flexGrow: 1, my: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 15,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Total Projects
                </Typography>
                <Typography
                  sx={{
                    fontSize: 30,
                    fontWeight: 700,
                    mt: "12px",
                    lineHeight: 1.5,
                    mb: "8px",
                    fontFamily:
                      'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {dashStats.total.toLocaleString(0, {
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", gap: "4px" }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 16,
                        fontFamily:
                          'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {(
                        dashStats?.previousYearProjectsCounts ?? 0
                      ).toLocaleString(0, {
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: 16,
                        color: "#919EAB",
                        fontFamily:
                          'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      last year
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            flexGrow: 0,
            flexBasis: "auto",
            width: "calc(100% * 12 / var(12))",
            minWidth: "0px",
            boxSizing: "border-box",
            padding: "calc(var(24px) / 2) calc(var(24px) / 2)",
          }}
        >
          <Card
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 3,
              py: 1,
              height: 150,
            }}
          >
            {isLoading.completed ? (
              <Box
                sx={{
                  flexGrow: 1,
                  my: 1,
                  display: "flex", // Add this to enable flexbox layout
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ flexGrow: 1, my: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 15,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Year Complete
                </Typography>
                <Typography
                  sx={{
                    fontSize: 30,
                    fontWeight: 700,
                    mt: "12px",
                    lineHeight: 1.5,
                    mb: "8px",
                    fontFamily:
                      'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  {yearComplete}%
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: 16,
                      color: "#919EAB",
                      fontFamily:
                        'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    {moment().format("YYYY")}
                  </Typography>
                </Box>
              </Box>
            )}
          </Card>
        </Grid>
        {/* Repeat the same structure for other grid items */}
        <Grid item md={8} sm={12}>
          <Item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              px: 3,
              py: 1,
            }}
          >
            <Box sx={{ textAlign: "left" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "12px 12px 0px",
                }}
              >
                <Box sx={{ flex: "1 1 auto" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 20,
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Sub-Programs Overview
                      </Typography>
                      <Typography
                        sx={{
                          color: "#919EAB",
                          fontWeight: 400,
                          fontSize: 14,
                          margin: "2px 0px 0px",
                          lineHeight: 1.5,
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Projects by their sub-program
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FormControl size="small">
                        <Select
                          value={year}
                          onChange={handleChangeYear}
                          sx={{
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            borderRadius: 20,
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 2,
                            },
                          }}
                          classes={{
                            select: dropdownClasses.select,
                          }}
                        >
                          {/* Generate years in the past */}
                          {Array.from({ length: 4 }, (_, index) => {
                            const currentYear = new Date().getFullYear();
                            const yearValue = currentYear - index;
                            const yearLabel = `${yearValue}/${yearValue + 1}`;
                            return (
                              <MenuItem
                                key={`past_${index}`}
                                value={yearValue.toString()}
                              >
                                {yearLabel}
                              </MenuItem>
                            );
                          }).reverse()}
                          {/* Generate years in the future */}
                          {Array.from({ length: 11 }, (_, index) => {
                            const currentYear = new Date().getFullYear();
                            const yearValue = currentYear + index + 1; // Add 1 to skip the current year
                            const yearLabel = `${yearValue}/${yearValue + 1}`;
                            return (
                              <MenuItem
                                key={`future_${index}`}
                                value={yearValue.toString()}
                              >
                                {yearLabel}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <GanttFilter filter={filter} setFilter={setFilter} />
                      </Menu>
                      <Badge
                        badgeContent={filterBadgeCount()}
                        color="primary"
                        invisible={filterBadgeCount() < 0}
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<FilterListIcon style={{ width: 17 }} />}
                          onClick={handleClick}
                          sx={{
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            ml: 1, // Added marginRight to remove margin to the right
                            color: theme.palette.text.primary,
                            borderColor: theme.palette.background.border,
                            background: theme.palette.background.default,
                            borderRadius: 2,
                            height: "40px",
                            "&:hover": {
                              borderColor: theme.palette.background.border,
                              color: theme.palette.text.primary,
                            },
                          }}
                        >
                          Filter
                        </Button>
                      </Badge>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {popularChartData && <StyledChart {...popularChartData} />}
            </Box>
          </Item>
        </Grid>
        <Grid item md={4} sx={{ display: "flex", justifyContent: "center" }}>
          <Item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <Box sx={{ textAlign: "left" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "24px 24px 0px",
                }}
              >
                <Box sx={{ flex: "1 1 auto" }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 20,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Lifecycle
                  </Typography>
                  <Typography
                    sx={{
                      color: "#919EAB",
                      fontWeight: 400,
                      fontSize: 14,
                      margin: "2px 0px 0px",
                      lineHeight: 1.5,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Projects by their lifecycle
                  </Typography>
                </Box>
              </Box>

              {lifecycleChartData && (
                <Chart
                  {...lifecycleChartData}
                  style={{
                    padding: "2rem",
                    paddingBottom: "2rem",
                    paddingTop: "1rem",
                  }}
                />
              )}

              <Divider
                sx={{
                  borderWidth: "0px 0px thin",
                  borderColor: "rgba(145 158 171 / 0.3)",
                  borderStyle: "dashed",
                }}
              />
              <Stack
                sx={{
                  display: "flex",
                  flexFlow: "wrap",
                  gap: "16px",
                  padding: "24px",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      gap: "6px",
                      alignItems: "center",
                      display: "inline-flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        width: "12px",
                        height: "12px",
                        flexShrink: 0,
                        display: "flex",
                        borderRadius: "50%",
                        position: "relative",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "currentColor",
                        color: "rgb(0, 143, 251)",
                      }}
                    ></Box>
                    <Typography sx={{ fontSize: 14 }}>New</Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      gap: "6px",
                      alignItems: "center",
                      display: "inline-flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        width: "12px",
                        height: "12px",
                        flexShrink: 0,
                        display: "flex",
                        borderRadius: "50%",
                        position: "relative",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "currentColor",
                        color: "rgb(91, 228, 155)",
                      }}
                    ></Box>
                    <Typography sx={{ fontSize: 14 }}>Renewal</Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      gap: "6px",
                      alignItems: "center",
                      display: "inline-flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        width: "12px",
                        height: "12px",
                        flexShrink: 0,
                        display: "flex",
                        borderRadius: "50%",
                        position: "relative",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "currentColor",
                        color: "rgb(254, 176, 25)",
                      }}
                    ></Box>
                    <Typography sx={{ fontSize: 14 }}>Upgrade</Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      gap: "6px",
                      alignItems: "center",
                      display: "inline-flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        width: "12px",
                        height: "12px",
                        flexShrink: 0,
                        display: "flex",
                        borderRadius: "50%",
                        position: "relative",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "currentColor",
                        color: "rgb(255, 69, 96)",
                      }}
                    ></Box>
                    <Typography sx={{ fontSize: 14 }}>Disposal</Typography>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Item>
        </Grid>
        {/* Far Bottom */}

        <Grid item xs={12} md={8} sm={12}>
          <Item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0, // Remove padding from the Item
            }}
          >
            <Box sx={{ textAlign: "left", width: "100%", p: 0 }}>
              <Box sx={{ display: "flex", alignItems: "center", p: 0 }}>
                <Box
                  sx={{
                    alignItems: "center",
                    mx: 5,
                    mb: 5,
                    mt: 3,
                    width: "100%", // Ensure full width
                  }}
                >
                  <Typography
                    fontSize={16}
                    sx={{
                      fontWeight: 600,
                      fontSize: 20,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Projects Status
                  </Typography>

                  <Typography
                    sx={{
                      color: "#919EAB",
                      fontWeight: 400,
                      fontSize: 14,
                      margin: "2px 0px 0px",
                      lineHeight: 1.5,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Projects by their status
                  </Typography>
                  {statusChartData &&
                    statusChartData.series.length > 0 &&
                    statusChartData.series[0].data.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        onClick={() => {
                          console.log(
                            statusTotal() === 0 && item === 0
                              ? 0
                              : item / statusTotal()
                          );
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              mt: 4,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily:
                                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                fontWeight: 600,
                                fontSize: 17,
                                lineHeight: 1.5,
                              }}
                            >
                              {statusDetails[index].title}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                  fontWeight: 600,
                                  fontSize: 17,
                                  lineHeight: 1.5,
                                }}
                              >
                                {item}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                  fontWeight: 400,
                                  fontSize: 16,
                                  lineHeight: 1.5,
                                  color: "#637381",
                                }}
                              >
                                {`(${(
                                  (statusTotal() === 0 && item === 0
                                    ? 0
                                    : item / statusTotal()) * 100
                                ).toLocaleString(0, {
                                  maximumFractionDigits: 2,
                                })}%)`}
                              </Typography>
                            </Box>
                          </Box>
                          <LinearProgress
                            variant="determinate"
                            value={
                              (statusTotal() === 0 && item === 0
                                ? 0
                                : item / statusTotal()) * 100
                            }
                            sx={{
                              height: "8px",
                              borderRadius: 20,
                              backgroundColor: "rgba(145, 158, 171, 0.12)", // Corrected RGBA syntax
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: statusDetails[index].colour, // Change this to your desired color: ;
                              },
                              mt: 1,
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
              <Divider
                sx={{
                  borderWidth: "0px 0px thin",
                  borderColor: "rgba(145 158 171 / 0.3)",
                  borderStyle: "dashed",
                }}
              />
              <Box
                sx={{
                  textAlign: "right",
                  padding: "16px",
                }}
              >
                <Button
                  sx={{
                    fontWeight: 700,
                    fontSize: 13,
                    minWidth: "64px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                  endIcon={<ChevronRightIcon />}
                  onClick={() => {
                    navigate("projects");
                  }}
                >
                  View all
                </Button>
              </Box>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card
            sx={{
              transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              backgroundImage: "none",
              overflow: "hidden",
              position: "relative",
              zIndex: 0,
              padding: "24px",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                ml: 2,
              }}
            >
              <Box>
                <Typography
                  fontSize={16}
                  sx={{
                    fontWeight: 600,
                    fontSize: 20,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Project Gateways
                </Typography>

                <Typography
                  sx={{
                    color: "#919EAB",
                    fontWeight: 400,
                    fontSize: 14,
                    margin: "2px 0px 0px",
                    lineHeight: 1.5,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                >
                  Number of projects in gateway
                </Typography>
              </Box>
              <Timeline
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 0,
                  flexGrow: 1,
                  padding: "6px 0px",
                  mt: 2,
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {projectGateways &&
                  Object.keys(projectGateways).map((item, index) => (
                    <TimelineItem
                      key={index}
                      sx={{
                        listStyle: "none",
                        display: "flex",
                        position: "relative",
                        minHeight: "70px",
                      }}
                    >
                      <TimelineSeparator
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flex: "0 1 0%",
                          alignItems: "center",
                        }}
                      >
                        <TimelineDot
                          sx={{
                            display: "flex",
                            alignSelf: "baseline",
                            borderStyle: "solid",
                            borderWidth: "2px",
                            padding: "4px",
                            borderRadius: "50%",
                            margin: "11.5px 0px",

                            boxShadow: "none",
                            backgroundColor: `${gatewaysMap[item].colour}`,
                          }}
                        />
                        {item !== "finished" && (
                          <TimelineConnector
                            sx={{
                              width: "2px",
                              flexGrow: 1,
                              backgroundColor: "rgba(145 158 171 / 0.2)",
                            }}
                          />
                        )}
                      </TimelineSeparator>
                      <Box
                        sx={{
                          margin: 0,
                          padding: "6px 16px",
                          textAlign: "left",
                          flex: "1 1 0%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {gatewaysMap[item].title}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          {projectGateways[item]}
                        </Typography>
                      </Box>
                    </TimelineItem>
                  ))}
              </Timeline>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardPage;
