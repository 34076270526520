import MainCard from "ui-component/cards/MainCard";
import GanttChart from "./components/GanttChart";
import "assets/scss/gantt.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Button,
  Typography,
  Divider,
  IconButton,
  Stack,
  Breadcrumbs,
  Card,
} from "@mui/material";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import GanttFilter from "./components/GanttFilter";
import dayjs from "dayjs";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const GanttPage = () => {
  const [selectedToday, setSelectedToday] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectedYear, setSelectedYear] = useState(dayjs().year() - 3);

  const [filter, setFilter] = useState({
    department: "",
    businessUnit: "",
    branch: "",
    owner: "",
  });

  useEffect(() => {
    setSelectedToday(true);

    // Google Anayltics
    ReactGA.send({
      hitType: "pageview",
      page: "/timeline",
      title: "Timeline",
    });
  }, []);

  return (
    <MainCard>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <GanttFilter filter={filter} setFilter={setFilter} />
      </Menu>

      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Gantt
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Dashboard
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
                color="text.primary"
              >
                Gantt
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Stack>

      <Card
        sx={{
          color: "#1C252E",
          backgroundImage: "none",
          position: "relative",
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            pb: 2,
            position: "relative",
          }}
        >
          {/* Time Selector */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                setSelectedYear(selectedYear - 1);
              }}
            >
              <ChevronLeftIcon fontSize="inherit" />
            </IconButton>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 22,
                mx: 1,
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              {`${selectedYear} - ${selectedYear + 4}`}
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                setSelectedYear(selectedYear + 1);
              }}
            >
              <ChevronRightIcon fontSize="inherit" />
            </IconButton>
          </Stack>

          {/* Filter */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                setSelectedToday(true);
              }}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                boxSizing: "border-box",
                cursor: "pointer",
                fontSize: "0.8125rem",
                fontWeight: 700,
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                color: "#FFFFFF",
                backgroundColor: "#FF5630",
                minWidth: "64px",
                height: "30px",
                padding: "4px 8px",
                borderRadius: "8px",
                borderColor: "initial",
                borderWidth: "0px",
                "&:hover": {
                  backgroundColor: "#FF5630",
                  borderRadius: "8px",
                  borderColor: "initial",
                  borderWidth: "0px",
                },
                "&:disabled": {
                  color: "white",
                  backgroundColor: "#FF5630",
                  borderRadius: "8px",
                  borderColor: "initial",
                  borderWidth: "0px",
                },
              }}
            >
              Today
            </Button>
            <IconButton onClick={handleClick}>
              <FilterListIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <GanttChart
          filter={filter}
          selectedToday={selectedToday}
          setSelectedToday={setSelectedToday}
          selectedYear={selectedYear}
        />
      </Card>
    </MainCard>
  );
};

export default GanttPage;
