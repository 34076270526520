import React, { useState, useEffect, useRef } from "react";
import { IconSearch } from "@tabler/icons";
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import DocumentationSection from "./DocumentationSection";
import DocumentationSearch from "./DocumentationSearch";
import sections from "./DocumentationData";
import ReactGA from "react-ga4";

const DocumentationPage = () => {
  const [activeArticle, setActiveArticle] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSearch("");
    setOpen(false);
  };

  // Refs for scrolling to articles
  const articleRefs = useRef({});

  useEffect(() => {
    // Google Anayltics
    ReactGA.send({
      hitType: "pageview",
      page: "/documentation",
      title: "Documentation",
    });
  }, []);

  useEffect(() => {
    // Function to handle hash change
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1); // Get hash without '#'
      const sectionIndex = sections.findIndex((section) =>
        section.articles.some((article) => article.id === hash)
      );

      if (sectionIndex !== -1) {
        const articleIndex = sections[sectionIndex].articles.findIndex(
          (article) => article.id === hash
        );
        scrollToArticle(sectionIndex, articleIndex); // Scroll to the article
      }
    };

    // Check hash on initial load
    handleHashChange();

    // Listen for hash change events
    window.addEventListener("hashchange", handleHashChange);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []); // Empty dependency array to run only once on mount

  const scrollToArticle = (sectionIndex, articleIndex) => {
    const sectionId = sections[sectionIndex].id;
    const articleId = sections[sectionIndex].articles[articleIndex].id;

    if (
      articleRefs.current[sectionId] &&
      articleRefs.current[sectionId][articleId]
    ) {
      const yOffset = 0; // Adjust this offset if necessary
      const y =
        articleRefs.current[sectionId][articleId].getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const handleItemClick = (article, sectionIndex, articleIndex) => {
    // Scroll to the article
    scrollToArticle(sectionIndex, articleIndex);

    // Update URL hash
    const hash = article.id;
    if (window.location.hash !== `#${hash}`) {
      window.history.pushState(null, "", `#${hash}`);
    }

    // Optionally, close the search modal if it's open
    setOpen(false);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    let foundActive = false;

    // Check each section and article to determine active article
    sections.forEach((section) => {
      section.articles.forEach((article) => {
        const ref = articleRefs.current[section.id]?.[article.id];
        if (ref) {
          const top = ref.getBoundingClientRect().top + window.pageYOffset;
          if (!foundActive && top <= scrollPosition + 100) {
            setActiveArticle(article);
          }
        }
      });
    });
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [search, setSearch] = useState("");
  const [filteredArticles, setFilteredArticles] = useState([]);

  useEffect(() => {
    if (search.trim() === "") {
      setFilteredArticles([]);
      return;
    }

    const filtered = sections.flatMap((section) =>
      section.articles.filter((article) =>
        article.name.toLowerCase().includes(search.toLowerCase())
      )
    );

    setFilteredArticles(filtered);
  }, [search]);

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        minHeight: "100vh", // Ensure the component takes full viewport height
      }}
    >
      {/* Search Modal */}
      <Modal open={open} onClose={handleClose}>
        <DocumentationSearch
          open={open}
          search={search}
          setSearch={setSearch}
          handleItemClick={handleItemClick}
          sections={sections}
          filteredArticles={filteredArticles}
          handleClose={handleClose}
        />
      </Modal>

      {/* Sidebar */}
      <Box
        sx={{
          flexDirection: "column",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          width: "250px",
          borderRight: "1px solid #e3e8ef",
          zIndex: 1100,
          pt: 2,
          overflowY: "auto", // Ensure sidebar content can scroll
        }}
      >
        <Link href="/" title="Project Pit" sx={{ ml: 2 }}>
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/transparent-word-logo.png`}
            alt="Project Pit"
            sx={{ width: 100, height: "auto" }}
          />
        </Link>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "16px",
          }}
          onClick={handleOpen}
        >
          <Box
            sx={{
              padding: 0.75,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              border: "1px solid #ccc",
              borderRadius: 2,
            }}
          >
            <IconSearch style={{ marginRight: "5px" }} stroke={2} size={14} />
            <Typography fontSize={14}>Find anything</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            overflowY: "auto", // Ensure the sidebar list can scroll
            padding: "16px",
            paddingTop: "0px",
            scrollbarWidth: "none",
          }}
        >
          <Paper>
            {sections.map((section, sectionIndex) => (
              <List
                key={section.id}
                component="nav"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {section.name}
                  </ListSubheader>
                }
              >
                {section.articles.map((article, articleIndex) => (
                  <ListItem
                    key={article.id}
                    button
                    selected={article.id === activeArticle?.id}
                    onClick={() =>
                      handleItemClick(article, sectionIndex, articleIndex)
                    }
                  >
                    <ListItemText primary={article.name} />
                  </ListItem>
                ))}
              </List>
            ))}
          </Paper>
        </Box>
      </Box>

      {/* Content Sections */}
      {sections.map((section, sectionIndex) => (
        <Box
          sx={{
            marginLeft: "250px", // Adjust based on sidebar width
          }}
          key={sectionIndex}
          id={section.id} // Add an id to the Box container for scrolling
        >
          {section.articles.map((article, articleIndex) => (
            <Box
              key={articleIndex}
              ref={(el) => {
                if (!articleRefs.current[section.id]) {
                  articleRefs.current[section.id] = {};
                }
                articleRefs.current[section.id][article.id] = el;
              }}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DocumentationSection article={article} />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default DocumentationPage;
