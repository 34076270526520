/* eslint-disable */
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BackupIcon from "@mui/icons-material/Backup";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Breadcrumbs,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import mapboxgl from "mapbox-gl";
import { useState } from "react";
import {
  apiRequest,
  pagingApiRequest,
  uploadFormData,
} from "Helper/ApiRequest";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { getUserId, handleGetStrength } from "Helper/Tokens";
import MainCard from "ui-component/cards/MainCard";
import { enqueueSnackbar } from "notistack";
import { useRef } from "react";
import { LoadingButton } from "@mui/lab";
import Bugsnag from "@bugsnag/js";

const ProjectCreate = ({ edit }) => {
  const { id } = useParams();

  let navigate = useNavigate();

  const canUserCreate = () => {
    return handleGetStrength() >= 4;
  };

  const [projectDetails, setProjectDetails] = useState({
    title: "",
    details: "",
    currentGateway: "notStarted",
    lifecycle: "",
    subprogram: null,
    businessUnit: "",
    department: "",
    branch: "",
    already_existing_project: false,
    existing_project_id: null,
    address: "",
    latitude: null,
    longitude: null,
    road_name: "",
    facility_id: "",
    facility_name: "",
    startDate: null,
    endDate: null,
    owner: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [missingError, setMissingError] = useState({
    name: false,
    description: false,
    lifecycle: false,
    subprogram: false,
  });

  const [editBudgetItems, setEditBudgetItems] = useState([]);
  const [budgetItems, setBudgetItems] = useState([
    {
      amount: "",
      year: "",
      reprovision: "",
      breakdown: [],
    },
  ]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [editFiles, setEditFiles] = useState([]);

  // Uploaded Files
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [subprograms, setSubprograms] = useState([]);
  const [search, setSearch] = useState(null);

  const onChangeHandler = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    handleFetchSubprograms();
    handleFetchDepartments();
    handleListBusinessUnit();
    handleListBranches();
    fetchProjectOwner();
  }, []);

  useEffect(() => {
    handleFetchSubprograms();
  }, [search]);

  useEffect(() => {
    if (edit) {
      if (
        projectDetails &&
        projectDetails.files &&
        projectDetails.files.length > 0
      ) {
        setEditFiles([]);
        handleFetchFiles(projectDetails.files);
      }
    }
  }, [projectDetails]);

  async function handleFetchFiles(files) {
    for (let i = 0; i < files.length; i++) {
      const fileId = files[i];

      // Fetch the file data
      const response = await apiRequest({
        api: "GetFile",
        params: `?file=${fileId}`,
      });

      if (response.error) {
        enqueueSnackbar(response.error, {
          variant: "error",
        });

        // Bugsnag error
        Bugsnag.notify(new Error(response.error));
        continue; // Skip this file ID if there was an error
      }

      // Define the payload
      const payload = {
        name: fileId,
        url: response.url,
      };

      // Update the state while checking for duplicates
      setEditFiles((editFiles) => {
        // Check if the file is already in the state
        if (editFiles.some((file) => file.name === fileId)) {
          return editFiles; // Return the existing state if file is already present
        }

        // Add the new file if it's not already present
        return [...editFiles, payload];
      });
    }
  }

  const handleRemoveFile = async (file) => {
    const updatedEditFiles = editFiles.filter(
      (editFile) => editFile.name !== file.name
    );

    setEditFiles(updatedEditFiles);

    const payload = {
      files: updatedEditFiles.map((editFile) => editFile.name),
    };

    const response = await apiRequest({
      api: "UpdateProject",
      params: `/${id}`,
      body: payload,
    });

    if (response.error) {
      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    enqueueSnackbar("Removed File!", {
      variant: "success",
    });
  };

  async function handleFetchSubprograms() {
    const response = await apiRequest({
      api: "GetSubProgram",
      params: search === null ? null : `?search=${search}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    setSubprograms(response);
  }

  const handleTextChange = (field, value) => {
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleGatewayChange = (phase, field, value) => {
    setGateways((prevDates) => ({
      ...prevDates,
      [phase]: {
        ...prevDates[phase],
        [field]: value,
      },
    }));
  };

  const [gateways, setGateways] = useState({
    concept: {
      start: null,
      end: null,
    },
    planning: {
      start: null,
      end: null,
    },
    procurement: {
      start: null,
      end: null,
    },
    delivery: {
      start: null,
      end: null,
    },
    finalisation: {
      start: null,
      end: null,
    },
  });

  const {
    title,
    details,
    currentGateway,
    lifecycle,
    startDate,
    endDate,
    subprogram,
    department,
    businessUnit,
    branch,
    latitude,
    longitude,
    address,
    road_name,
    facility_id,
    facility_name,
    existing_project_id,
    owner,
  } = projectDetails;

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 2;
  const years = Array.from({ length: 15 }, (_, index) => {
    const year = startYear + index;
    return `${year}/${year + 1}`;
  });

  const isYearSelected = (year, currentIndex) => {
    return budgetItems.some(
      (item, index) => index !== currentIndex && item.year === year
    );
  };

  const [searchLocation, setSearchLocation] = useState("");
  const [suggestionLabels, setSuggestionsLabels] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [branches, setBranches] = useState([]);

  const [forecastChecked, setForecastChecked] = useState(true);

  const handleChangeForecast = (event) => {
    setForecastChecked(event.target.checked);
  };

  async function handleFetchDepartments() {
    try {
      let allDepartments = [];
      let currentPage = 1;
      let totalPages;

      do {
        const response = await pagingApiRequest({
          api: "ListDepartments",
          page: currentPage,
        });

        if (response.error) {
          throw new Error(response.error);
        }

        allDepartments = allDepartments.concat(response.data);
        totalPages = response.settings.pages;
        currentPage += 1;
      } while (currentPage <= totalPages);

      setDepartments(allDepartments);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(error.message));
    }
  }

  async function handleListBusinessUnit() {
    try {
      let allBusinessUnit = [];
      let currentPage = 1;
      let totalPages;

      do {
        const response = await pagingApiRequest({
          api: "ListBusinessUnit",
          page: currentPage,
        });

        if (response.error) {
          throw new Error(response.error);
        }

        allBusinessUnit = allBusinessUnit.concat(response.data);
        totalPages = response.settings.pages;
        currentPage += 1;
      } while (currentPage <= totalPages);

      setBusinessUnits(allBusinessUnit);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(error.message));
    }
  }

  async function handleListBranches() {
    try {
      let allBranch = [];
      let currentPage = 1;
      let totalPages;

      do {
        const response = await pagingApiRequest({
          api: "ListBranch",
          page: currentPage,
        });

        if (response.error) {
          throw new Error(response.error);
        }

        allBranch = allBranch.concat(response.data);
        totalPages = response.settings.pages;
        currentPage += 1;
      } while (currentPage <= totalPages);

      setBranches(allBranch);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(error.message));
    }
  }

  useEffect(() => {
    if (searchLocation) {
      getSuggestions(searchLocation);
    }
  }, [searchLocation]);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_KEY;
  }, []);

  const getSuggestions = async (value) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${mapboxgl.accessToken}`
      );
      const data = await response.json();
      const suggestions = data.features.map((feature) => ({
        name: feature.place_name,
        coordinates: feature.center,
      }));
      const suggestionLabels = data.features.map(
        (feature) => feature.place_name
      );
      setSuggestionsLabels(suggestionLabels);
      setSuggestions(suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleTextLocationChange = (field, value) => {
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  async function handleFetchEditSubprogram(editProject) {
    const response = await apiRequest({
      api: "GetSubProgram",
      params: `${editProject.subprogram._id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    handleTextChange("subprogram", response);
  }

  useEffect(() => {
    if (canUserCreate()) {
      if (edit) {
        handleFetchEditData();
        handleFetchBudget();
        return;
      }
      fetchProjectOwner();
    } else {
      navigate("/unauthorised");
    }
  }, []);

  const fetchProjectOwner = async () => {
    const userId = getUserId();
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      owner: userId,
    }));
  };

  async function handleFetchEditData() {
    const response = await apiRequest({
      api: "ListProjects",
      params: `/${id}`,
    });

    setGateways(response.gateways);

    handleFetchEditSubprogram(response);

    setSearchLocation(response.address);

    setProjectDetails({
      ...response,
      subprogram: response.subprogram ? response.subprogram._id : null,
      department: response.department ? response.department._id : null,
      businessUnit: response.businessUnit ? response.businessUnit._id : null,
      branch: response.branch ? response.branch._id : null,
    });
  }

  async function handleFetchBudget() {
    const response = await apiRequest({
      api: "GetPrioritiedProjects",
      params: `/${id}`,
    });

    setEditBudgetItems(response);

    if (response.length > 0) {
      setBudgetItems([]);
      for (let i = 0; i < response.length; i++) {
        const budgetYear = response[i];
        const cost = budgetYear.cost;
        const year = budgetYear.year;
        const breakdown = budgetYear.breakdown
        const reprovision = Number(budgetYear.reprovision ?? 0);

        const payload = {
          amount: cost,
          year: year,
          reprovision,
          breakdown
        };

        setBudgetItems((prevBudgets) => [...prevBudgets, payload]);
      }
    }
  }

  async function handleCreateProject() {
    setIsLoading(true);
    // Checking missing values
    const missingValues = {
      name: projectDetails.title.length > 0 ? false : true,
      description: projectDetails.details.length > 0 ? false : true,
      lifecycle: projectDetails.lifecycle.length > 0 ? false : true,
      subprogram: projectDetails.subprogram === null,
    };

    setMissingError(missingValues);

    // Showing missing fields error.
    if (Object.values(missingValues).includes(true)) {
      enqueueSnackbar("Error: Required fields are missing. ", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }

    const payload = {
      title,
      details,
      currentGateway,
      lifecycle: lifecycle.toLowerCase(),
      subprogram: subprogram._id,
      department: department ? department : null,
      businessUnit: businessUnit ? businessUnit : null,
      branch: branch ? branch : null,
      startDate: startDate ? Number(new Date(startDate)) : null,
      endDate: endDate ? Number(new Date(endDate)) : null,
      address: address ? address : null,
      latitude: latitude ? Number(latitude) : null,
      longitude: longitude ? Number(longitude) : null,
      road_name: road_name ? road_name : null,
      facility_id: facility_id ? facility_id : null,
      facility_name: facility_name ? facility_name : null,
      already_existing_project: existing_project_id ? true : false,
      existing_project_id: existing_project_id ? existing_project_id : null,
      files: uploadedFiles,
      status: "TBA",
      owner: owner,
      gateways: gateways,
    };

    const response = await apiRequest({
      api: "CreateProjects",
      body: payload,
    });

    if (response.error) {
      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      setIsLoading(false);
      return;
    }

    const project = response._id;
    const hasBudget = budgetItems.some((item) =>
      Object.values(item).some((value) => value.length > 0)
    );
    if (hasBudget) {
      for (let i = 0; i < budgetItems.length; i++) {
        const budget = budgetItems[i];

        const priorityPayload = {
          cost: Number(budget.amount),
          year: budget.year,
          strength: 0,
          subprogram: subprogram,
          breakdown: budget.breakdown,
          project: project,
          reprovision: budget.reprovision ? Number(budget.reprovision) : null,
        };
        const priorityResponse = await apiRequest({
          api: "CreateProjectPriority",
          body: priorityPayload,
        });

        if (priorityResponse.error) {
          enqueueSnackbar(priorityResponse.error, {
            variant: "error",
          });

          // Bugsnag error
          Bugsnag.notify(new Error(priorityResponse.error));
          setIsLoading(false);
          return;
        }
      }
    }

    enqueueSnackbar("Created Project!", {
      variant: "success",
    });
    setIsLoading(false);

    if (forecastChecked) {
      navigate(`/projects/${response._id}/forecast`);
    } else {
      handleReset();
    }
  }

  async function handleEditProject() {
    setIsLoading(true);
    // Checking missing values
    const missingValues = {
      name: projectDetails.title.length > 0 ? false : true,
      description: projectDetails.details.length > 0 ? false : true,
      lifecycle: projectDetails.lifecycle.length > 0 ? false : true,
      subprogram: projectDetails.subprogram === null,
    };

    setMissingError(missingValues);

    // Showing missing fields error.
    if (Object.values(missingValues).includes(true)) {
      enqueueSnackbar("Error: Required fields are missing. ", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }

    const payload = {
      title,
      details,
      currentGateway,
      lifecycle: lifecycle.toLowerCase(),
      subprogram: subprogram._id,
      department: department ? department : null,
      businessUnit: businessUnit ? businessUnit : null,
      branch: branch ? branch : null,
      startDate: startDate ? Number(new Date(startDate)) : null,
      endDate: endDate ? Number(new Date(endDate)) : null,
      address: address ? address : null,
      latitude: latitude ? Number(latitude) : null,
      longitude: longitude ? Number(longitude) : null,
      road_name: road_name ? road_name : null,
      facility_id: facility_id ? facility_id : null,
      facility_name: facility_name ? facility_name : null,
      already_existing_project: existing_project_id ? true : false,
      existing_project_id: existing_project_id ? existing_project_id : null,
      files: uploadedFiles,
      owner: owner,
      gateways: gateways,
    };

    const response = await apiRequest({
      api: "UpdateProject",
      params: `/${id}`,
      body: payload,
    });
    if (response.error) {
      setIsLoading(false);
      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }
    const hasBudget = budgetItems.some((item) =>
      Object.values(item).some((value) => value.length > 0)
    );
    if (hasBudget) {
      for (let i = 0; i < budgetItems.length; i++) {
        const budget = budgetItems[i];
        const existingYear = editBudgetItems.filter(
          (item) => item.year === budget.year
        );
        if (existingYear.length > 0) {
          // Update existing budget & priority
          const existingPriority = existingYear[0];

          const budgetObject = budgetItems.find(
            (item) => item.year === budget.year
          );
          const priorityPayload = {
            cost: Number(budgetObject.amount),
            year: budgetObject.year,
            strength: 0,
            subprogram: subprogram,
            breakdown: budgetObject.breakdown,
            project: budget.project,
            reprovision: budgetObject.reprovision
              ? Number(budgetObject.reprovision)
              : null,
          };
          const updateResponse = await apiRequest({
            api: "UpdateProjectPriority",
            params: `/${existingPriority._id}`,
            body: priorityPayload,
          });
          if (updateResponse.error) {
            setIsLoading(false);
            enqueueSnackbar(updateResponse.error, {
              variant: "error",
            });

            // Bugsnag error
            Bugsnag.notify(new Error(updateResponse.error));
            return;
          }
        } else {
          const projectResponse = await apiRequest({
            api: "ListProjects",
            params: `/${id}`,
          });
          const priorityPayload = {
            cost: Number(budget.amount),
            year: budget.year,
            strength: 0,
            subprogram: subprogram,
            breakdown: budget.breakdown,
            project: projectResponse._id,
          };
          const priorityResponse = await apiRequest({
            api: "CreateProjectPriority",
            body: priorityPayload,
          });
          if (priorityResponse.error) {
            setIsLoading(false);
            enqueueSnackbar(priorityResponse.error, {
              variant: "error",
            });

            // Bugsnag error
            Bugsnag.notify(new Error(priorityResponse.error));
            return;
          }
        }
      }
    }

    enqueueSnackbar("Updated Project!", {
      variant: "success",
    });
    setIsLoading(false);
    if (forecastChecked) {
      navigate(`/projects/${response._id}/forecast`);
    } else {
      handleReset();
    }
  }

  const totalAmount = budgetItems.reduce(
    (total, item) => total + item.amount,
    0
  );

  const totalReprovisions = budgetItems.reduce(
    (total, item) => total + (item.reprovision ? item.reprovision : 0),
    0
  );

  // Create a ref for the file input
  const fileInputRef = useRef(null);

  // Function to trigger the file input click
  const handleBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveSelectedFile = (fileIndex) => {
    setSelectedFiles((prevItems) =>
      prevItems.filter((item, index) => index !== fileIndex)
    );
  };

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB example limit
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to array

    // Check file size before adding to selectedFiles
    const validFiles = files.filter((file) => file.size <= MAX_FILE_SIZE);

    if (validFiles.length < files.length) {
      enqueueSnackbar("Each file can't exceed 10 MB.", {
        variant: "error",
      });
    } else {
      setSelectedFiles(validFiles);
    }

    // Reset the input value to allow re-selecting the same file
    event.target.value = "";
  };

  const handleFieldChange = (itemIndex, breakdownIndex, field, value) => {
    setBudgetItems((prevBudgetItems) =>
      prevBudgetItems.map((item, idx) =>
        idx === itemIndex
          ? {
              ...item,
              breakdown: item.breakdown.map((breakdownItem, bIdx) =>
                bIdx === breakdownIndex
                  ? { ...breakdownItem, [field]: value }
                  : breakdownItem
              ),
            }
          : item
      )
    );
  };

  const handleAddFundingSourceClick = (index) => {
    // Create the new breakdown item
    const emptyBreakdownItem = { source: "", value: 0 };

    // Update the state with the new breakdown item added
    setBudgetItems((prevBudgetItems) =>
      prevBudgetItems.map((item, idx) =>
        idx === index
          ? {
              ...item,
              breakdown: [...item.breakdown, emptyBreakdownItem],
            }
          : item
      )
    );
  };

  const handleAddClick = () => {
    // Create a new budget item with the current year and an empty amount
    const newBudgetItem = { year: "", amount: "", breakdown: [] };
    // Update the state with the new budget item
    setBudgetItems([...budgetItems, newBudgetItem]);
  };

  const handleRemoveClick = (yearToRemove) => {
    // Filter out the item with the matching year
    const updatedBudgetItems = budgetItems.filter(
      (item) => item.year !== yearToRemove
    );
    // Update the state with the filtered budget items
    setBudgetItems(updatedBudgetItems);
  };

  const handleAmountChange = (index, value) => {
    // Update the amount for the specified budget item
    const updatedBudgetItems = [...budgetItems];
    updatedBudgetItems[index].amount = Number(value);
    setBudgetItems(updatedBudgetItems);
  };

  const handleReprovisionChange = (index, value) => {
    // Update the amount for the specified budget item
    const updatedBudgetItems = [...budgetItems];
    updatedBudgetItems[index].reprovision = Number(value);
    setBudgetItems(updatedBudgetItems);
  };

  const handleBudgetYearChange = (index, event) => {
    // Update the year for the specified budget item
    const updatedBudgetItems = [...budgetItems];
    updatedBudgetItems[index].year = event.target.value;
    setBudgetItems(updatedBudgetItems);
  };

  const handleReset = () => {
    setProjectDetails({
      title: "",
      details: "",
      currentGateway: "",
      lifecycle: "",
      startDate: "",
      endDate: "",
      subprogram: null,
      businessUnit: "",
      department: "",
      branch: "",
      already_existing_project: false,
      existing_project_id: null,
      address: null,
      latitude: null,
      longitude: null,
      road_name: null,
      facility_id: null,
      facility_name: null,
      files: [],
    });
    setSelectedFiles([]);
    setUploadedFiles([]);
    setBudgetItems([
      {
        amount: "",
        year: "",
        reprovision: "",
      },
    ]);
    setGateways({
      concept: {
        start: null,
        end: null,
      },
      planning: {
        start: null,
        end: null,
      },
      procurement: {
        start: null,
        end: null,
      },
      delivery: {
        start: null,
        end: null,
      },
      finalisation: {
        start: null,
        end: null,
      },
    });

    fetchProjectOwner();
  };

  async function handleUploadFiles() {
    setUploadLoading(true);
    var filesArr = [];
    if (selectedFiles.length > 0) {
      var formdata = new FormData();

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        formdata.append("file", file);
      }
      const fileResponse = await uploadFormData({
        api: "UploadFiles",
        formData: formdata,
      });

      if (fileResponse.error) {
        enqueueSnackbar("Error: Uploading files.", {
          variant: "error",
        });

        // Bugsnag error
        Bugsnag.notify(new Error(fileResponse.error));

        return;
      }

      // Extracting an array of file names
      const fileNames = fileResponse.files.map((file) => file.fileName);
      filesArr = fileNames;
      enqueueSnackbar("Uploaded Files.", {
        variant: "success",
      });
    }
    if (filesArr.length > 0) {
      setUploadedFiles(filesArr);
    }
    setUploadLoading(false);
  }

  // Function to format number with commas
  const formatNumberWithCommas = (value) => {
    if (value === undefined || value === null || value === "") return "";
    return new Intl.NumberFormat().format(value);
  };

  // Function to parse the number removing commas
  const parseNumberFromString = (value) => {
    return parseFloat(value.replace(/,/g, "")) || 0;
  };

  return (
    <MainCard>
      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              {edit ? `Update Project` : "Create a new project"}
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Dashboard
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Projects
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
                color="text.primary"
              >
                {edit ? "Edit Project" : "New Project"}
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Stack>
      {/* Content */}
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          mx: "auto",
          gap: "40px",
          maxWidth: "720px",
        }}
      >
        {/* First Details */}
        <Card
          sx={{
            backgroundImage: "none",
            position: "relative",
            zIndex: 0,
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "24px 24px 0px",
              mb: "24px",
            }}
          >
            <Box sx={{ flex: "1 1 auto" }}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Details
              </Typography>
              <Typography
                sx={{
                  color: "#637381",
                  m: "4px 0px 0px",
                  fontSize: 14,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Title, description, lifecycle, gateway
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              m: 0,
              borderWidth: "0px 0px thin",
              borderStyle: "dashed",
              borderColor: "rgba(145 158 171 / 0.2)",
            }}
          />
          {/* First Detials Fields */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: "24px",
            }}
          >
            {/* Titles Field */}
            <TextField
              variant="outlined"
              fullWidth
              error={missingError.name}
              helperText={missingError.name ? "Name is required!" : ""}
              value={projectDetails.title}
              onChange={(e) => handleTextChange("title", e.target.value)}
              label="Project name"
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiFormHelperText-root": {
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiInputBase-root": {
                  height: 52,
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            />

            {/* Details Field */}
            <TextField
              variant="outlined"
              fullWidth
              label="Project description"
              multiline
              error={missingError.description}
              helperText={
                missingError.description ? "Description is required!" : ""
              }
              value={projectDetails.details}
              onChange={(e) => handleTextChange("details", e.target.value)}
              minRows={5} // Set the minimum number of lines
              maxRows={5} // Set the maximum number of lines (optional)
              inputProps={{
                style: {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            />

            {/* Lifecycle */}
            <FormControl
              fullWidth
              sx={{
                display: "flex", // Use flex to align items vertically
                flexDirection: "column",
                justifyContent: "center", // Center content vertically
                verticalAlign: "top",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              }}
            >
              <InputLabel
                sx={{
                  "&.MuiInputLabel-root.Mui-focused": {
                    color: "#1C252E",
                    fontWeight: 600,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  alignSelf: "center", // Center the label horizontally in its container
                }}
              >
                Lifecycle
              </InputLabel>
              <Select
                label="Lifecycle"
                fullWidth
                error={missingError.lifecycle}
                value={projectDetails.lifecycle}
                onChange={(e) => handleTextChange("lifecycle", e.target.value)}
                sx={{
                  height: 50,
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1C252E",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1C252E",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    padding: "16px 12px",
                    fontSize: "14px",
                    background: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiSelect-icon": {
                    color: "#1C252E",
                  },
                }}
              >
                <MenuItem value={"new"}>
                  <ListItemText primary={"New"} />
                </MenuItem>

                <MenuItem value={"renewal"}>
                  <ListItemText primary={"Renewal"} />
                </MenuItem>

                <MenuItem value={"upgrade"}>
                  <ListItemText primary={"Upgrade"} />
                </MenuItem>

                <MenuItem value={"disposal"}>
                  <ListItemText primary={"Disposal"} />
                </MenuItem>
              </Select>
              {/* Error Helper Text */}
              {missingError.lifecycle && (
                <FormHelperText
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    color: "#f44336",
                  }}
                >
                  Lifecycle is required!
                </FormHelperText>
              )}
            </FormControl>

            {/* Current Gateway */}
            <FormControl
              fullWidth
              sx={{
                display: "flex", // Use flex to align items vertically
                flexDirection: "column",
                justifyContent: "center", // Center content vertically
                verticalAlign: "top",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "8px",
                },
              }}
            >
              <InputLabel
                sx={{
                  "&.MuiInputLabel-root.Mui-focused": {
                    color: "#1C252E",
                    fontWeight: 600,
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  alignSelf: "center",
                }}
              >
                Gateway
              </InputLabel>
              <Select
                label="Lifecycle"
                fullWidth
                value={projectDetails.currentGateway || "notStarted"}
                onChange={(e) =>
                  handleTextChange("currentGateway", e.target.value)
                }
                sx={{
                  height: 50,
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1C252E",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1C252E",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    padding: "16px 12px",
                    fontSize: "14px",
                    background: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiSelect-icon": {
                    color: "#1C252E",
                  },
                }}
              >
                <MenuItem value="notStarted">
                  <em>Not Started</em>
                </MenuItem>
                <MenuItem value={"concept"}>
                  <ListItemText primary={"Concept & Feasibility"} />
                </MenuItem>
                <MenuItem value={"planning"}>
                  <ListItemText primary={"Planning & Design"} />
                </MenuItem>
                <MenuItem value={"procurement"}>
                  <ListItemText primary={"Procurement"} />
                </MenuItem>
                <MenuItem value={"delivery"}>
                  <ListItemText primary={"Delivery & Construction"} />
                </MenuItem>
                <MenuItem value={"finalisation"}>
                  <ListItemText primary={"Finalisation"} />
                </MenuItem>
                <MenuItem value={"finished"}>
                  <ListItemText primary={"Finished"} />
                </MenuItem>
              </Select>
            </FormControl>

            {/* Start Date */}
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                format="DD/MM/YYYY"
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiInputBase-input": {
                    backgroundColor: "white",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#000",
                      borderRadius: "8px",
                    },
                  "& .MuiFormLabel-root": {
                    color: "#888", // Change this to the desired color
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#000", // Change this to the desired color when focused
                  },
                }}
                value={
                  projectDetails.startDate
                    ? dayjs(new Date(projectDetails.startDate))
                    : null
                }
                onChange={(newValue) => {
                  const date = newValue.$d;
                  handleTextChange("startDate", date);
                }}
              />
            </LocalizationProvider>

            {/* End Date */}
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                format="DD/MM/YYYY"
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiInputBase-input": {
                    backgroundColor: "white",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#000",
                      borderRadius: "8px",
                    },
                  "& .MuiFormLabel-root": {
                    color: "#888", // Change this to the desired color
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#000", // Change this to the desired color when focused
                  },
                }}
                value={
                  projectDetails.endDate
                    ? dayjs(new Date(projectDetails.endDate))
                    : null
                }
                onChange={(newValue) => {
                  const date = newValue.$d;
                  handleTextChange("endDate", date);
                }}
              />
            </LocalizationProvider>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Files
              </Typography>
              <Box sx={{ width: "100%", position: "relative" }}>
                <Box
                  role="presentation"
                  tabIndex="0"
                  sx={{
                    cursor: "pointer",
                    padding: "40px",
                    outline: "none",
                    borderRadius: "8px",
                    overflow: "hidden",
                    border: "1px dashed rgba(145, 158, 171, 0.2)",
                    transition:
                      "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1), padding 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                  }}
                  onClick={handleBoxClick} // Attach click handler to the Box
                >
                  <input
                    id="fileInput"
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    ref={fileInputRef} // Attach ref to the input
                    onChange={handleFileChange}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 18,
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Select files
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: 14,
                          color: "#637381",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Click here to browse through your local files.
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
                {selectedFiles.length > 0 && (
                  <List
                    sx={{
                      gap: "8px",
                      display: "flex",
                      flexFlow: "wrap",
                      my: "24px",
                    }}
                  >
                    {selectedFiles.map((file, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          border: 1,
                          borderRadius: 2,
                          borderColor: "#DADADA",
                          borderStyle: "solid",
                          marginTop: 1,
                          py: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography>{file.name}</Typography>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              handleRemoveSelectedFile(index);
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                )}
                {/* Files Options */}
                {selectedFiles.length > 0 && (
                  <Stack
                    sx={{
                      display: "flex",
                      gap: "12px",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxSizing: "border-box",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        fontWeight: 700,
                        fontSize: "0.8125rem",
                        padding: "3px 8px",
                        borderRadius: "8px",
                        height: "30px",
                        backgroundColor: "transparent",
                        color: "#000",
                        borderColor: "rgba(145, 158, 171, 0.32)",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        "&:hover": {
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        },
                      }}
                      disabled={uploadLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedFiles([]);
                      }}
                    >
                      Remove all
                    </Button>
                    <LoadingButton
                      startIcon={<BackupIcon />}
                      loading={uploadLoading}
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        boxSizing: "border-box",
                        cursor: "pointer",
                        userSelect: "none",
                        verticalAlign: "center",
                        fontWeight: 700,
                        fontSize: "0.8125rem",
                        minWidth: "64px",
                        height: "30px",
                        color: "#FFFFFF",
                        padding: "4px 8px",
                        backgroundColor: "#1C252E",
                        borderRadius: "8px",
                        "&:hover": {
                          backgroundColor: "#2D3A45",
                          boxShadow: "none",
                        },
                        "& .MuiLoadingButton-loadingIndicator": {
                          color: "white",
                        },
                      }}
                      onClick={handleUploadFiles}
                    >
                      Upload
                    </LoadingButton>
                  </Stack>
                )}

                {selectedFiles.length > 0 && (
                  <Divider
                    sx={{
                      m: 0,
                      mt: 2,
                      borderWidth: "0px 0px thin",
                      borderStyle: "dashed",
                      borderColor: "rgba(145 158 171 / 0.2)",
                    }}
                  />
                )}
                {/* Edit Files */}
                {editFiles.length > 0 && (
                  <List
                    sx={{
                      gap: "8px",
                      display: "flex",
                      flexFlow: "wrap",
                      my: "24px",
                    }}
                  >
                    {editFiles.map((file, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          border: 1,
                          borderRadius: 2,
                          borderColor: "#DADADA",
                          borderStyle: "solid",
                          marginTop: 1,
                          py: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography>{file.name}</Typography>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              handleRemoveFile(file);
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            </Stack>
          </Stack>
        </Card>
        {/* Second Details */}
        <Card
          sx={{
            backgroundImage: "none",
            position: "relative",
            zIndex: 0,
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "24px 24px 0px",
              mb: "24px",
            }}
          >
            <Box sx={{ flex: "1 1 auto" }}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Properties
              </Typography>
              <Typography
                sx={{
                  color: "#637381",
                  m: "4px 0px 0px",
                  fontSize: 14,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Additional attributes and details
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              m: 0,
              borderWidth: "0px 0px thin",
              borderStyle: "dashed",
              borderColor: "rgba(145 158 171 / 0.2)",
            }}
          />
          {/* First Properties Fields */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: "24px",
            }}
          >
            <Box
              sx={{
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "24px 16px",
                display: "grid",
              }}
            >
              {/* Business Unit */}
              {businessUnits && (
                <FormControl
                  fullWidth
                  sx={{
                    display: "flex", // Use flex to align items vertically
                    flexDirection: "column",
                    justifyContent: "center", // Center content vertically
                    verticalAlign: "top",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                    },
                  }}
                >
                  <InputLabel
                    sx={{
                      "&.MuiInputLabel-root.Mui-focused": {
                        color: "#1C252E",
                        fontWeight: 600,
                        alignSelf: "center",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                    }}
                  >
                    Business Unit
                  </InputLabel>
                  <Select
                    label="Business Unit"
                    fullWidth
                    sx={{
                      height: 50,
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1C252E",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1C252E",
                      },
                      "& .MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                        padding: "16px 12px",
                        fontSize: "14px",
                        background: "white",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        alignSelf: "center", // Center the label horizontally in its container
                      },
                      "& .MuiSelect-icon": {
                        color: "#1C252E",
                      },
                    }}
                    value={projectDetails.businessUnit}
                    onChange={(event) => {
                      const selectedId = event.target.value;
                      handleTextChange("businessUnit", selectedId);
                    }}
                  >
                    {businessUnits.map((businessUnit) => (
                      <MenuItem key={businessUnit._id} value={businessUnit._id}>
                        <ListItemText primary={businessUnit.title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* Department */}
              {departments && (
                <FormControl
                  fullWidth
                  sx={{
                    display: "flex", // Use flex to align items vertically
                    flexDirection: "column",
                    justifyContent: "center", // Center content vertically
                    verticalAlign: "top",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                    },
                  }}
                >
                  <InputLabel
                    sx={{
                      "&.MuiInputLabel-root.Mui-focused": {
                        color: "#1C252E",
                        fontWeight: 600,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        alignSelf: "center", // Center the label horizontally in its container
                      },
                    }}
                  >
                    Department
                  </InputLabel>
                  <Select
                    label="Department"
                    fullWidth
                    sx={{
                      height: 50,
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1C252E",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1C252E",
                      },
                      "& .MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                        padding: "16px 12px",
                        fontSize: "14px",
                        background: "white",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiSelect-icon": {
                        color: "#1C252E",
                      },
                    }}
                    value={projectDetails.department}
                    onChange={(event) => {
                      const selectedId = event.target.value;
                      handleTextChange("department", selectedId);
                    }}
                  >
                    {departments.map((department) => (
                      <MenuItem key={department._id} value={department._id}>
                        <ListItemText primary={department.title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* Branch */}
              {branches && (
                <FormControl
                  fullWidth
                  sx={{
                    display: "flex", // Use flex to align items vertically
                    flexDirection: "column",
                    justifyContent: "center", // Center content vertically
                    verticalAlign: "top",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "8px",
                    },
                  }}
                >
                  <InputLabel
                    sx={{
                      "&.MuiInputLabel-root.Mui-focused": {
                        color: "#1C252E",
                        fontWeight: 600,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        alignSelf: "center", // Center the label horizontally in its container
                      },
                    }}
                  >
                    Branch
                  </InputLabel>
                  <Select
                    label="Branch"
                    fullWidth
                    sx={{
                      height: 50,
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1C252E",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1C252E",
                      },
                      "& .MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                        padding: "16px 12px",
                        fontSize: "14px",
                        background: "white",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiSelect-icon": {
                        color: "#1C252E",
                      },
                    }}
                    value={projectDetails.branch}
                    onChange={(event) => {
                      const selectedId = event.target.value;
                      handleTextChange("branch", selectedId);
                    }}
                  >
                    {branches.map((branch) => (
                      <MenuItem key={branch._id} value={branch._id}>
                        <ListItemText primary={branch.title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* Existing Project ID Field */}
              <TextField
                variant="outlined"
                fullWidth
                label="Existing Project ID"
                onChange={(e) =>
                  handleTextChange("existing_project_id", e.target.value)
                }
                inputProps={{
                  style: {
                    backgroundColor: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#000",
                      borderRadius: "8px",
                    },
                  "& .MuiFormLabel-root": {
                    color: "#888", // Change this to the desired color
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#000", // Change this to the desired color when focused
                  },
                }}
              />
            </Box>

            {/* Subprogram Field */}
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Subprogram
              </Typography>
              <Box>
                <TextField
                  variant="outlined"
                  fullWidth
                  error={missingError.subprogram}
                  helperText={
                    missingError.subprogram ? "Subprogram is required!" : ""
                  }
                  label="Search Subprogram..."
                  onChange={onChangeHandler}
                  inputProps={{
                    style: {
                      backgroundColor: "white",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ddd",
                      borderRadius: "8px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#000",
                      borderRadius: "8px",
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                    "& .MuiFormLabel-root": {
                      color: "#888", // Change this to the desired color
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#000", // Change this to the desired color when focused
                    },
                  }}
                />

                {/* Table */}
                <Box
                  sx={{
                    position: "relative",
                    overflowX: "auto", // Enable horizontal scrolling
                    mt: 3,
                    maxHeight: 380,
                  }}
                >
                  {/* Selection Overlay */}
                  {projectDetails.subprogram && (
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        pl: "8px",
                        pr: "16px",
                        top: "0px",
                        left: "0px",
                        width: "100%",
                        zIndex: 9,
                        height: "58px",
                        position: "absolute",
                        backgroundColor: "#C8FAD6",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          margin: "0px 0px 0px 16px",
                          color: "#00A76F",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          flexGrow: 1,
                        }}
                      >
                        {projectDetails.subprogram.title} Selected
                      </Typography>
                    </Stack>
                  )}
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          color: "#637381",
                          backgroundColor: "#F4F6F8",
                          outline: "0px",
                        }}
                      >
                        <TableCell
                          sx={{
                            width: "48px",
                            borderBottomStyle: "dashed",
                            borderColor: "transparent",
                          }}
                        ></TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            borderBottomStyle: "dashed",
                            borderColor: "transparent",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          ID
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            borderBottomStyle: "dashed",
                            borderColor: "transparent",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          Name
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subprograms.data &&
                        subprograms.data.map((item) => {
                          const isItemSelected = projectDetails.subprogram
                            ? projectDetails.subprogram._id === item._id
                            : false;
                          return (
                            <TableRow key={item._id}>
                              <TableCell
                                padding="checkbox"
                                sx={{
                                  borderBottomStyle: "dashed",
                                  width: "48px",
                                  color: "#1C252E",
                                  padding: "0px 0px 0px 8px",
                                }}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onClick={() => {
                                    if (isItemSelected) {
                                      handleTextChange("subprogram", null);
                                    } else {
                                      handleTextChange("subprogram", item);
                                    }
                                  }}
                                  sx={{
                                    color: "#637381",
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderBottomStyle: "dashed",
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                }}
                              >
                                {item._id}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  borderBottomStyle: "dashed",
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                }}
                              >
                                {item.title}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Card>
        {/* Third Details */}
        <Card
          sx={{
            backgroundImage: "none",
            position: "relative",
            zIndex: 0,
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "24px 24px 0px",
              mb: "24px",
            }}
          >
            <Box sx={{ flex: "1 1 auto" }}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Location
              </Typography>
              <Typography
                sx={{
                  color: "#637381",
                  m: "4px 0px 0px",
                  fontSize: 14,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Optional location information
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              m: 0,
              borderWidth: "0px 0px thin",
              borderStyle: "dashed",
              borderColor: "rgba(145 158 171 / 0.2)",
            }}
          />
          {/* First Properties Fields */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: "24px",
            }}
          >
            <Box
              sx={{
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "24px 16px",
                display: "grid",
              }}
            >
              {/* Facility ID Field */}
              <TextField
                variant="outlined"
                fullWidth
                label="Facility ID"
                value={projectDetails.facility_id}
                onChange={(e) =>
                  handleTextChange("facility_id", e.target.value)
                }
                inputProps={{
                  style: {
                    backgroundColor: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#000",
                      borderRadius: "8px",
                    },
                  "& .MuiFormLabel-root": {
                    color: "#888", // Change this to the desired color
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#000", // Change this to the desired color when focused
                  },
                }}
              />

              {/* Facility Name Field */}
              <TextField
                variant="outlined"
                fullWidth
                label="Facility Name"
                value={projectDetails.facility_name}
                onChange={(e) =>
                  handleTextChange("facility_name", e.target.value)
                }
                inputProps={{
                  style: {
                    backgroundColor: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ddd",
                    borderRadius: "8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#000",
                      borderRadius: "8px",
                    },
                  "& .MuiFormLabel-root": {
                    color: "#888", // Change this to the desired color
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#000", // Change this to the desired color when focused
                  },
                }}
              />
            </Box>

            {/* Road Name Field */}
            <TextField
              variant="outlined"
              fullWidth
              label="Road Name"
              value={projectDetails.road_name}
              onChange={(e) => handleTextChange("road_name", e.target.value)}
              inputProps={{
                style: {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            />

            {/* Location Autocomplete */}
            <Box
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Autocomplete
                value={searchLocation}
                forcePopupIcon={false}
                onChange={(event, newValue) => {
                  const locationCoords = suggestions.find(
                    (loc) => loc.name === newValue
                  );
                  setSearchLocation(newValue);
                  handleTextLocationChange("address", newValue);
                  handleTextLocationChange(
                    "latitude",
                    locationCoords.coordinates[1]
                  );
                  handleTextLocationChange(
                    "longitude",
                    locationCoords.coordinates[0]
                  );
                }}
                disableClearable
                options={suggestionLabels}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search location"
                    onChange={(e) => {
                      setSearchLocation(e.target.value);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                  />
                )}
                sx={{ m: 0, mb: 0, width: "100%" }}
              />
            </Box>
          </Stack>
        </Card>
        {/* Gateways Details */}
        <Card
          sx={{
            backgroundImage: "none",
            position: "relative",
            zIndex: 0,
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "24px 24px 0px",
              mb: "24px",
            }}
          >
            <Box sx={{ flex: "1 1 auto" }}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Gateways
              </Typography>
              <Typography
                sx={{
                  color: "#637381",
                  m: "4px 0px 0px",
                  fontSize: 14,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Optional gateway information
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              m: 0,
              borderWidth: "0px 0px thin",
              borderStyle: "dashed",
              borderColor: "rgba(145 158 171 / 0.2)",
            }}
          />
          {/* First Properties Fields */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: "24px",
            }}
          >
            {/* Concept & Feasibility */}
            <Box>
              <Typography
                sx={{
                  mb: 2,
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Concept & Feasibility
              </Typography>
              <Box
                sx={{
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "24px 16px",
                  display: "grid",
                }}
              >
                {/* Start Date */}
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    format="DD/MM/YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                    value={
                      gateways.concept.start
                        ? dayjs(new Date(gateways.concept.start))
                        : null
                    }
                    onChange={(val) => {
                      handleGatewayChange("concept", "start", val.$d);
                    }}
                  />
                </LocalizationProvider>
                {/* End Date */}
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    format="DD/MM/YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                    value={
                      gateways.concept.end
                        ? dayjs(new Date(gateways.concept.end))
                        : null
                    }
                    onChange={(val) => {
                      handleGatewayChange("concept", "end", val.$d);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            {/* Planning & Design */}
            <Box>
              <Typography
                sx={{
                  mb: 2,
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Planning & Design
              </Typography>
              <Box
                sx={{
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "24px 16px",
                  display: "grid",
                }}
              >
                {/* Start Date */}
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    format="DD/MM/YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                    value={
                      gateways.planning.start
                        ? dayjs(new Date(gateways.planning.start))
                        : null
                    }
                    onChange={(val) => {
                      handleGatewayChange("planning", "start", val.$d);
                    }}
                  />
                </LocalizationProvider>
                {/* End Date */}
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    format="DD/MM/YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                    value={
                      gateways.planning.end
                        ? dayjs(new Date(gateways.planning.end))
                        : null
                    }
                    onChange={(val) => {
                      handleGatewayChange("planning", "end", val.$d);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            {/* Procurement */}
            <Box>
              <Typography
                sx={{
                  mb: 2,
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Procurement
              </Typography>
              <Box
                sx={{
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "24px 16px",
                  display: "grid",
                }}
              >
                {/* Start Date */}
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    format="DD/MM/YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                    value={
                      gateways.procurement.start
                        ? dayjs(new Date(gateways.procurement.start))
                        : null
                    }
                    onChange={(val) => {
                      handleGatewayChange("procurement", "start", val.$d);
                    }}
                  />
                </LocalizationProvider>
                {/* End Date */}
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    format="DD/MM/YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                    value={
                      gateways.procurement.end
                        ? dayjs(new Date(gateways.procurement.end))
                        : null
                    }
                    onChange={(val) => {
                      handleGatewayChange("procurement", "end", val.$d);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            {/* Delivery & Construction */}
            <Box>
              <Typography
                sx={{
                  mb: 2,
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Delivery & Construction
              </Typography>
              <Box
                sx={{
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "24px 16px",
                  display: "grid",
                }}
              >
                {/* Start Date */}
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    format="DD/MM/YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                    value={
                      gateways.delivery.start
                        ? dayjs(new Date(gateways.delivery.start))
                        : null
                    }
                    onChange={(val) => {
                      handleGatewayChange("delivery", "start", val.$d);
                    }}
                  />
                </LocalizationProvider>
                {/* End Date */}
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    format="DD/MM/YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                    value={
                      gateways.delivery.end
                        ? dayjs(new Date(gateways.delivery.end))
                        : null
                    }
                    onChange={(val) => {
                      handleGatewayChange("delivery", "end", val.$d);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            {/* Finalisation */}
            <Box>
              <Typography
                sx={{
                  mb: 2,
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Finalisation
              </Typography>
              <Box
                sx={{
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "24px 16px",
                  display: "grid",
                }}
              >
                {/* Start Date */}
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    format="DD/MM/YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                    value={
                      gateways.finalisation.start
                        ? dayjs(new Date(gateways.finalisation.start))
                        : null
                    }
                    onChange={(val) => {
                      handleGatewayChange("finalisation", "start", val.$d);
                    }}
                  />
                </LocalizationProvider>
                {/* End Date */}
                <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    format="DD/MM/YYYY"
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888", // Change this to the desired color
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000", // Change this to the desired color when focused
                      },
                    }}
                    value={
                      gateways.finalisation.end
                        ? dayjs(new Date(gateways.finalisation.end))
                        : null
                    }
                    onChange={(val) => {
                      handleGatewayChange("finalisation", "end", val.$d);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Stack>
        </Card>
        {/* Budget Details */}
        <Card
          sx={{
            backgroundImage: "none",
            position: "relative",
            zIndex: 0,
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "24px 24px 0px",
              mb: "24px",
            }}
          >
            <Box sx={{ flex: "1 1 auto" }}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Budget
              </Typography>
              <Typography
                sx={{
                  color: "#637381",
                  m: "4px 0px 0px",
                  fontSize: 14,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Optional budget information
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              m: 0,
              mb: 2,
              borderWidth: "0px 0px thin",
              borderStyle: "dashed",
              borderColor: "rgba(145 158 171 / 0.2)",
            }}
          />
          {/* Budget Fields */}
          <Box sx={{ padding: "24px" }}>
            {/* Year & Budget Fields */}

            {budgetItems.map((item, index) => {
              const currentValue = item.amount;
              const formattedValue = formatNumberWithCommas(currentValue);

              const currentRepoValue = item.reprovision;
              const formattedRepoValue =
                formatNumberWithCommas(currentRepoValue);
              return (
                <Stack
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      mt: 1,
                    }}
                  >
                    {/* Regular Year Selector */}
                    <Stack
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        gap: "16px",
                      }}
                    >
                      {/* Year Selector */}
                      <FormControl
                        fullWidth
                        sx={{
                          display: "inline-flex",
                          verticalAlign: "top",
                          flexDirection: "column",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "8px",
                          },
                        }}
                      >
                        <InputLabel
                          sx={{
                            "&.MuiInputLabel-root.Mui-focused": {
                              color: "#1C252E",
                              fontWeight: 600,
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                          }}
                        >
                          Year
                        </InputLabel>
                        <Select
                          label="Year"
                          fullWidth
                          value={item.year}
                          onChange={(event) =>
                            handleBudgetYearChange(index, event)
                          }
                          sx={{
                            height: 50,
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#ddd",
                              borderRadius: "8px",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#1C252E",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#1C252E",
                            },
                            "& .MuiSelect-select": {
                              display: "flex",
                              alignItems: "center",
                              padding: "16px 12px",
                              fontSize: "14px",
                              background: "white",
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiSelect-icon": {
                              color: "#1C252E",
                            },
                          }}
                        >
                          {years.map((year) => (
                            <MenuItem
                              key={year}
                              value={year}
                              disabled={isYearSelected(year, index)}
                            >
                              <ListItemText primary={year} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {/* Total */}
                      <FormControl fullWidth>
                        <InputLabel
                          sx={{
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            color: "#888", // Default color
                            "&.Mui-focused": {
                              color: "#000", // Color when focused
                            },
                          }}
                        >
                          Total
                        </InputLabel>
                        <OutlinedInput
                          type="text"
                          placeholder="0.00"
                          value={formattedValue}
                          onChange={(event) => {
                            const parsedValue = parseNumberFromString(
                              event.target.value
                            );
                            handleAmountChange(index, parsedValue);
                          }}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          label="Total"
                          sx={{
                            maxHeight: "50px",
                            background: "white",
                            input: {
                              backgroundColor: "white",
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiInputBase-root": {
                              backgroundColor: "white",
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#ddd",
                              borderRadius: "8px",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#000",
                              borderRadius: "8px",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#000",
                                borderRadius: "8px",
                              },
                            "& .MuiFormLabel-root": {
                              color: "#888", // Change this to the desired color
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "#000", // Change this to the desired color when focused
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#000",
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#000",
                              },
                          }}
                        />
                      </FormControl>

                      {/* Reprovision */}
                      <FormControl fullWidth>
                        <InputLabel
                          sx={{
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            color: "#888", // Default color
                            "&.Mui-focused": {
                              color: "#000", // Color when focused
                            },
                          }}
                        >
                          Reprovision
                        </InputLabel>
                        <OutlinedInput
                          type="text"
                          placeholder="0.00"
                          value={formattedRepoValue}
                          onChange={(event) => {
                            const parsedValue = parseNumberFromString(
                              event.target.value
                            );
                            handleReprovisionChange(index, parsedValue);
                          }}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          label="Reprovision"
                          sx={{
                            maxHeight: "50px",
                            background: "white",
                            input: {
                              backgroundColor: "white",
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiInputBase-root": {
                              backgroundColor: "white",
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#ddd",
                              borderRadius: "8px",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#000",
                              borderRadius: "8px",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#000",
                                borderRadius: "8px",
                              },
                            "& .MuiFormLabel-root": {
                              color: "#888", // Change this to the desired color
                              fontFamily:
                                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "#000", // Change this to the desired color when focused
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#000",
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#000",
                              },
                          }}
                        />
                      </FormControl>
                    </Stack>

                    {item.breakdown.map((breakdownItem, breakdownIndex) => {
                      const breakdownCurrentValue = breakdownItem.value;
                      const formattedBreakdownValue = formatNumberWithCommas(
                        breakdownCurrentValue
                      );
                      return (
                        <Stack
                          sx={{
                            mt: 2,
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            gap: "16px",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {/* Funding Source */}
                          <FormControl fullWidth>
                            <InputLabel
                              sx={{
                                fontFamily:
                                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                color: "#888", // Default color
                                "&.Mui-focused": {
                                  color: "#000", // Color when focused
                                },
                              }}
                            >
                              Funding Source
                            </InputLabel>
                            <OutlinedInput
                              type="text"
                              value={breakdownItem.source}
                              onChange={(event) => {
                                handleFieldChange(
                                  index,
                                  breakdownIndex,
                                  "source",
                                  event.target.value
                                );
                              }}
                              label="Funding Source"
                              sx={{
                                background: "white",
                                input: {
                                  backgroundColor: "white",
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                },
                                "& .MuiInputBase-root": {
                                  backgroundColor: "white",
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#ddd",
                                  borderRadius: "8px",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#000",
                                  borderRadius: "8px",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#000",
                                    borderRadius: "8px",
                                  },
                                "& .MuiFormLabel-root": {
                                  color: "#888", // Change this to the desired color
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                },
                                "& .MuiFormLabel-root.Mui-focused": {
                                  color: "#000", // Change this to the desired color when focused
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#000",
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#000",
                                  },
                              }}
                            />
                          </FormControl>

                          {/* Source Total */}
                          <FormControl fullWidth>
                            <InputLabel
                              sx={{
                                fontFamily:
                                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                color: "#888", // Default color
                                "&.Mui-focused": {
                                  color: "#000", // Color when focused
                                },
                              }}
                            >
                              Source Total
                            </InputLabel>
                            <OutlinedInput
                              type="text"
                              placeholder="0.00"
                              value={formattedBreakdownValue}
                              onChange={(event) => {
                                const parsedValue = parseNumberFromString(
                                  event.target.value
                                );
                                handleFieldChange(
                                  index,
                                  breakdownIndex,
                                  "value",
                                  parsedValue
                                );
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                              label="Source Total"
                              sx={{
                                maxHeight: "50px",
                                background: "white",
                                input: {
                                  backgroundColor: "white",
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                },
                                "& .MuiInputBase-root": {
                                  backgroundColor: "white",
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#ddd",
                                  borderRadius: "8px",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#000",
                                  borderRadius: "8px",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#000",
                                    borderRadius: "8px",
                                  },
                                "& .MuiFormLabel-root": {
                                  color: "#888", // Change this to the desired color
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                },
                                "& .MuiFormLabel-root.Mui-focused": {
                                  color: "#000", // Change this to the desired color when focused
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#000",
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#000",
                                  },
                              }}
                            />
                          </FormControl>

                          {/* Empty Form */}
                          <FormControl fullWidth></FormControl>
                        </Stack>
                      );
                    })}

                    {/* Add source btn */}
                    <Stack
                      sx={{
                        mb: 2,
                        width: "100%",
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      {/* Add New Source BTN */}
                      <Button
                        startIcon={<AddIcon />}
                        disabled={!item.year.length > 0}
                        sx={{
                          mt: 1,
                          fontWeight: 700,
                          fontSize: 13,
                          minWidth: "64px",
                          height: "30px",
                          verticalAlign: "middle",
                          color: "#00A76F",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                        onClick={() => {
                          handleAddFundingSourceClick(index);
                        }}
                      >
                        Add Source
                      </Button>

                      {/* Remove Year Btn */}
                      <Button
                        startIcon={<DeleteIcon />}
                        sx={{
                          mt: 1,
                          fontWeight: 700,
                          fontSize: 13,
                          minWidth: "64px",
                          height: "30px",
                          verticalAlign: "middle",
                          color: "#FF5630",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemoveClick(item.year);
                        }}
                      >
                        Remove
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}

            <Divider
              sx={{
                mt: 4,
                borderWidth: "0px 0px thin",
                borderStyle: "dashed",
                borderColor: "rgba(145 158 171 / 0.2)",
              }}
            />
            {/* Total Breakdown */}
            <Stack>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "24px",
                  my: 2,
                }}
              >
                <Button
                  startIcon={<AddIcon />}
                  sx={{
                    fontWeight: 700,
                    fontSize: 13,
                    minWidth: "64px",
                    height: "30px",
                    verticalAlign: "middle",
                    color: "#00A76F",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  }}
                  onClick={handleAddClick}
                >
                  Add Year
                </Button>
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  alignItems: "flex-end",
                  textAlign: "right",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  fontWeight: 400,
                  fontSize: 13,
                  lineHeight: 1.5,
                }}
              >
                {/* Years total, loop */}
                {budgetItems.map((item, index) => (
                  <Stack
                    key={index}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <Typography
                      sx={{
                        color: "#637381",
                      }}
                    >
                      {item.year && item.year.length > 0 ? item.year : "Year"}
                    </Typography>
                    <Typography
                      sx={{
                        width: "160px",
                        fontWeight: 600,
                        lineHeight: 1.5,
                      }}
                      onClick={() => {
                        console.log(item);
                      }}
                    >
                      $
                      {item.amount.toLocaleString(0, {
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Stack>
                ))}
                <Stack sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    Reprovisions
                  </Typography>
                  <Typography
                    sx={{
                      width: "160px",
                      fontWeight: 600,
                      fontSize: "1rem",
                      lineHeight: 1.5,
                    }}
                  >
                    $
                    {totalReprovisions.toLocaleString(0, {
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Stack>
                <Stack sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      width: "160px",
                      fontWeight: 600,
                      fontSize: "1rem",
                      lineHeight: 1.5,
                    }}
                  >
                    $
                    {(
                      Number(totalAmount) + Number(totalReprovisions)
                    ).toLocaleString(0, {
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Card>
        {/* Buttons */}
        <Stack
          sx={{
            display: "flex",
            flexFlow: "wrap",
            gap: "24px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={forecastChecked}
                  onChange={handleChangeForecast}
                  defaultChecked={false}
                />
              }
              label={
                <Typography
                  sx={{
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: 1.5,
                  }}
                >
                  Forecast Project
                </Typography>
              }
            />
          </FormGroup>
          <LoadingButton
            variant="contained"
            onClick={() => {
              if (edit) {
                handleEditProject();
              } else {
                handleCreateProject();
              }
            }}
            loading={isLoading}
            sx={{
              fontWeight: 700,
              fontSize: 15,
              minWidth: "64px",
              boxShadow: "none",
              height: "48px",
              padding: "8px 16px",
              borderRadius: "8px",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              backgroundColor: "#1C252E",
              "&:hover": {
                backgroundColor: "#2D3A45",
                boxShadow: "none",
              },
            }}
          >
            {edit ? "Update Project" : "Create project"}
          </LoadingButton>
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default ProjectCreate;
