import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import Routes from "routes";
import themes from "themes";
import NavigationScroll from "layout/NavigationScroll";
import { useEffect } from "react";
import { SET_MODE } from "store/actions";
import { apiRequest } from "Helper/ApiRequest";
import { handleGetAccessToken } from "Helper/Tokens";
import { useNavigate } from "react-router";
import { SnackbarProvider } from "notistack";

// Google Analytics
import ReactGA from "react-ga4";

const App = () => {
  // Google Analytics
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const exemptRoutes = [
    "/request-reset",
    "/change-password",
    "/home",
    "/documentation",
  ];

  useEffect(() => {
    const path = location.pathname;

    const isExemptRoute = exemptRoutes.some((exemptRoute) =>
      path.includes(exemptRoute)
    );

    const hostname = window.location.hostname;
    if (hostname !== "localhost") {
      if (!isExemptRoute) {
        const subdomain = hostname.includes("project-pit")
          ? hostname.split("project-pit")[0]
          : null;
        if (!subdomain) {
          navigate("/home");
          return;
        }
      }
    }

    async function fetchState() {
      const accessToken = await handleGetAccessToken();
      if (accessToken) {
        try {
          const response = await apiRequest({
            api: "GetProfile",
          });

          dispatch({ type: SET_MODE, navType: response.data.themeMode });
        } catch (error) {
          navigate("/login");
          return;
        }
      } else {
        if (!isExemptRoute) {
          navigate("/login");
        }
      }
    }

    fetchState();
  }, []);

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={3000} preventDuplicate>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </SnackbarProvider>
  );
};

export default App;
