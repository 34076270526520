import React, { useState } from "react";
import { Box, Breadcrumbs, Stack, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import SettingsGeneralPage from "./components/SettingsGeneral";
import { apiRequest } from "Helper/ApiRequest";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import ReactGA from "react-ga4";
import Bugsnag from "@bugsnag/js";

const Settings = () => {
  const [generalSettings, setGeneralSettings] = useState({
    id: "",
    title: "",
    subtitle: "",
  });

  async function fetchNotificationSettings() {
    const response = await apiRequest({
      api: "GetSettings",
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    setGeneralSettings({
      id: response._id,
      title: response.title,
      subtitle: response.subtitle,
    });
  }

  useEffect(() => {
    fetchNotificationSettings();

    // Google Anayltics
    ReactGA.send({
      hitType: "pageview",
      page: "/settings",
      title: "Settings",
    });
  }, []);

  return (
    <MainCard>
      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              Settings
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Dashboard
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
                color="text.primary"
              >
                Settings
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Stack>
      {/* Settings Content */}
      <Box sx={{ flexGrow: 1 }}>
        <SettingsGeneralPage
          generalSettings={generalSettings}
          setGeneralSettings={setGeneralSettings}
        />
      </Box>
    </MainCard>
  );
};

export default Settings;
