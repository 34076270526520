import {
  FormControlLabel,
  FormGroup,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
} from "@mui/material";
import { useState } from "react";
import { apiRequest } from "../../../../Helper/ApiRequest";
import PropTypes from "prop-types";
import "assets/scss/user.scss";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const UserCreatePage = ({ setOpen }) => {
  const [{ email, fname, lname, password }, setUser] = useState({
    email: "",
    fname: "",
    lname: "",
    password: "",
  });

  const [roles, setRoles] = useState([]);

  const rolesEnum = {
    "Super Administrator": "65caf9528148afb86963701f",
    Administrator: "655bddb8b556fc36b40f1e8d",
    Moderator: "655bddacb556fc36b40f1e8c",
    Special: "655bdda0b556fc36b40f1e8b",
    User: "655bdd94b556fc36b40f1e8a",
    Guest: "655bdd64b556fc36b40f1e89",
  };

  const handleCloseCreate = () => {
    setOpen(false);
  };

  const removeItem = (item) => {
    setRoles((prevState) => prevState.filter((prevItem) => prevItem !== item));
  };

  const handleChange = (newValue) => {
    setRoles((prevState) => [...prevState, newValue]);
  };

  const createUser = async () => {
    const payload = {
      email,
      firstName: fname,
      lastName: lname,
      password,
      roles,
      themeMode: "light",
    };
    const response = await apiRequest({
      api: "CreateUser",
      body: payload,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
    } else {
      enqueueSnackbar("Successfully created user!", { variant: "success" });
      setOpen(false);
    }
  };

  return (
    <Box>
      <DialogTitle
        sx={{
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
        className="h2 mb-0 pb-2 mt-3"
      >
        {"Create User"}
      </DialogTitle>
      <Typography
        sx={{
          mb: 1,
          fontFamily:
            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        }}
        className="mx-4"
      >
        Enter details for the new user. {open}
      </Typography>
      <DialogContent>
        <FormGroup>
          <TextField
            variant="outlined"
            fullWidth
            label="Email Address"
            onChange={(event) =>
              setUser({
                email: event.target.value,
                fname: fname,
                lname: lname,
                password: password,
              })
            }
            inputProps={{
              style: {
                height: "25px",
                backgroundColor: "white",
              },
            }}
            sx={{
              "& .MuiInputBase-root": {
                backgroundColor: "white",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ddd",
                borderRadius: "8px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
              "& .MuiFormLabel-root": {
                color: "#888", // Change this to the desired color
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#000", // Change this to the desired color when focused
              },
            }}
          />
          <div className="d-flex mt-4 justify-content-center gap-3">
            <TextField
              variant="outlined"
              fullWidth
              label="First Name"
              onChange={(event) =>
                setUser({
                  email: email,
                  fname: event.target.value,
                  lname: lname,
                  password: password,
                })
              }
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Last Name"
              onChange={(event) =>
                setUser({
                  email: email,
                  fname: fname,
                  lname: event.target.value,
                  password: password,
                })
              }
              inputProps={{
                style: {
                  height: "25px",
                  backgroundColor: "white",
                },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            />
          </div>
          <TextField
            variant="outlined"
            fullWidth
            label="Password"
            onChange={(event) =>
              setUser({
                email: email,
                fname: fname,
                lname: lname,
                password: event.target.value,
              })
            }
            inputProps={{
              style: {
                height: "25px",
                backgroundColor: "white",
              },
            }}
            sx={{
              mt: 3,
              "& .MuiInputBase-root": {
                backgroundColor: "white",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ddd",
                borderRadius: "8px",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
              "& .MuiFormLabel-root": {
                color: "#888", // Change this to the desired color
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#000", // Change this to the desired color when focused
              },
            }}
          />
          <div className="mt-4">
            <p>Roles</p>
            {Object.keys(rolesEnum).map((key) => (
              <FormControlLabel
                control={<Checkbox />}
                label={key}
                key={key}
                onChange={() => {
                  const value = rolesEnum[key];
                  if (roles.includes(value)) {
                    removeItem(value);
                  } else {
                    handleChange(value);
                  }
                }}
              />
            ))}
          </div>
        </FormGroup>
      </DialogContent>
      <DialogActions className="mb-4 mx-3">
        <Button
          onClick={handleCloseCreate}
          autoFocus
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "transparent",
            color: "black",
            borderColor: "rgba(145, 158, 171, 0.32)",
            borderWidth: "1px",
            borderStyle: "solid",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={
            roles.length > 0 &&
            email.length > 0 &&
            fname.length > 0 &&
            lname.length > 0
              ? false
              : true
          }
          sx={{
            fontWeight: 700,
            fontSize: 15,
            color: "white",
            minWidth: "64px",
            boxShadow: "none",
            padding: "8px 16px",
            borderRadius: "8px",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            backgroundColor: "#1C252E",
            "&:hover": {
              backgroundColor: "#2D3A45",
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: "#2D3A45",
              boxShadow: "none",
              color: "white",
            },
          }}
          onClick={() => {
            createUser();
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Box>
  );
};

export default UserCreatePage;

UserCreatePage.propTypes = {
  setOpen: PropTypes.func.isRequired, // setOpen should be a function and requiredd
};
