import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import ResetPassword from "views/pages/authentication/authentication/ResetPassword";
import ChangePassword from "views/pages/authentication/authentication/ChangePassword";
import PublicHomePage from "views/pages/public/PublicHomePage";
import NotFoundPage from "ui-component/cards/NotFoundPage";
import DocumentationPage from "views/pages/documentation/Documentation";
import TermsOfServicePage from "views/pages/public/TermsOfService";
import PrivacyPolicyPage from "views/pages/public/PrivacyPolicy";

const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication/Login"))
);

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    // 404 catch-all route
    {
      path: "*", // Catch-all route for unmatched paths
      element: <NotFoundPage />,
    },
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/home",
      element: <PublicHomePage />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfServicePage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "/request-reset",
      element: <ResetPassword />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/documentation",
      element: <DocumentationPage />,
    },
  ],
};

export default AuthenticationRoutes;
