import { Box, Button, Chip, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import MainCard from "ui-component/cards/MainCard";

const NotFoundPage = () => {
  let navigate = useNavigate();

  return (
    <MainCard>
      <Box
        sx={{
          display: "flex", // Enable flexbox
          flexDirection: "column", // Arrange children vertically
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          height: "100%", // Make the box full height (to center vertically)
        }}
      >
        <Chip
          size="small"
          label="404 error"
          color="primary"
          sx={{
            my: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        />
        <Typography
          variant="h1"
          sx={{
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          {"We've lost this page"}
        </Typography>
        <Typography
          sx={{
            mb: 2,
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          {
            "Sorry, the page you're looking for doesn't exist or has been moved."
          }
        </Typography>

        <Button
          onClick={() => {
            navigate("/");
          }}
          autoFocus
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            fontWeight: 700,
            fontSize: 15,
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "transparent",
            color: "black",
            borderColor: "rgba(145, 158, 171, 0.32)",
            borderWidth: "1px",
            borderStyle: "solid",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          Back Home
        </Button>
      </Box>
    </MainCard>
  );
};

export default NotFoundPage;
