// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

import useScriptRef from "hooks/useScriptRef";
import AnimateButton from "ui-component/extended/AnimateButton";
import { useState } from "react";
import { apiRequest } from "Helper/ApiRequest";
import { enqueueSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";

const AuthChangePassword = ({ code }) => {
  const theme = useTheme();
  const scriptedRef = useScriptRef();

  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState("");

  const changePassword = (value) => {
    setPassword(value);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    const payload = {
      code: code,
      newPassword: password,
    };

    const response = await apiRequest({
      api: "ChangePasswordReset",
      body: payload,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));

      setIsLoading(false);
      return;
    }
    enqueueSnackbar("Updated Password!", { variant: "success" });
    setIsLoading(false);
  }

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleChange, touched }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={{
                ...theme.typography.customInput,
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
              }}
            >
              <InputLabel htmlFor="outlined-adornment-password-register">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-register"
                type={"text"}
                value={password}
                name="password"
                label="Password"
                onChange={(e) => {
                  handleChange(e);
                  changePassword(e.target.value);
                }}
                inputProps={{}}
                sx={{
                  input: {
                    color: theme.palette.grey[600],
                    background: theme.palette.background.default,
                  },
                }}
              />
              {touched.password && errors.password && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-register"
                >
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isLoading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  height="55px"
                  sx={{
                    fontWeight: 700,
                    fontSize: 15,
                    color: "white",
                    minWidth: "64px",
                    boxShadow: "none",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    backgroundColor: "#1C252E",
                    "&:hover": {
                      backgroundColor: "#2D3A45",
                      boxShadow: "none",
                    },
                    "&:disabled": {
                      backgroundColor: "#2D3A45",
                      boxShadow: "none",
                      color: "white",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30px", // Ensure the Box fills the height of the Button
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress sx={{ p: 1 }} />
                    ) : (
                      <Typography p={0.5} fontWeight={500}>
                        Change Password
                      </Typography>
                    )}
                  </Box>
                </Button>
              </AnimateButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthChangePassword;
