import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  Menu,
  Button,
  Badge,
  Card,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { IconArrowDownRight, IconArrowUpLeft } from "@tabler/icons";
import Chart from "react-apexcharts";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { apiRequest } from "Helper/ApiRequest";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useDropdownStyles from "ui-component/extended/Dropdown";
import GanttFilter from "../gantt/components/GanttFilter";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import MainCard from "ui-component/cards/MainCard";
import { enqueueSnackbar } from "notistack";
import ReactGA from "react-ga4";
import Bugsnag from "@bugsnag/js";
import { useNavigate } from "react-router";

const AnalyticsPage = () => {
  // Google Anayltics
  ReactGA.send({
    hitType: "pageview",
    page: "/analytics",
    title: "Analytics",
  });

  const dropdownClasses = useDropdownStyles("80px");
  const theme = useTheme();

  let navigate = useNavigate()

  const [actualsCardData, setActualsCardData] = useState(null);
  const [forecastsCardData, setForecastsCardData] = useState(null);
  const [differenceData, setDifferenceData] = useState(null);
  const [unforecastedTotal, setUnforecastedTotal] = useState(null);

  const [forecastSeries, setForecastSeries] = useState(null);
  const [actualsSeries, setActualsSeries] = useState(null);
  const [cumulativeSeries, setCumulativeSeries] = useState(null);

  const [forecastCumulativeTotal, setForecastCumulativeTotal] = useState(0);
  const [actualsCumulativeTotal, setActualsCumulativeTotal] = useState(0);

  const [year, setYear] = useState("2024");

  const [filter, setFilter] = useState({
    department: null,
    businessUnit: null,
    branch: null,
    owner: null,
  });

  useEffect(() => {
    handleFetchBarData();
  }, [filter]);

  async function handleFetchCardData(forecastTotal) {
    let params = "";

    if (filter.department) {
      params += `&department=${filter.department._id}`;
    }

    if (filter.businessUnit) {
      params += `&businessUnit=${filter.businessUnit._id}`;
    }

    if (filter.branch) {
      params += `&branch=${filter.branch._id}`;
    }

    if (filter.owner) {
      params += `&owner=${filter.owner._id}`;
    }

    const response = await apiRequest({
      api: "GetAnalytics",
      params: `/card?year=${year}${params}`,
    });

    const totalBudgetResponse = response.totalAllocation;
    const totalBudgetAllocated = totalBudgetResponse.find((item) =>
      item._id.startsWith(year)
    );
    if (totalBudgetAllocated) {
      setUnforecastedTotal(totalBudgetAllocated.totalCost - forecastTotal);
    } else {
      setUnforecastedTotal(0);
    }

    if (response.error) {
      // User not logged in
      if (response.statusCode === 401) {
        navigate("/login");
        return;
      }

      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    const nextYear = Number(year) + 1;
    const financialYear = `${Number(year)}/${nextYear}`;
    const previousYear = `${Number(year) - 1}/${Number(year)}`;

    const totalActualsData = response.totalActuals.find(
      (budget) => budget._id === financialYear
    ) || { totalCost: 0 };
    const totalPreviousActualsData = response.totalActuals.find(
      (budget) => budget._id === previousYear
    ) || { totalCost: 0 };

    const budgetPercentage = calculatePercentageChange(
      totalPreviousActualsData.totalCost,
      totalActualsData.totalCost
    );

    const payload = {
      currentYear: totalActualsData,
      previousYear: totalPreviousActualsData,
      percentage: Math.abs(budgetPercentage),
      isDown: budgetPercentage > 0 ? false : true,
    };

    setActualsCardData(payload);

    const totalForecastsData = response.totalForecasts.find(
      (budget) => budget._id === financialYear
    ) || { totalCost: 0 };

    const totalPreviousForecastsData = response.totalForecasts.find(
      (budget) => budget._id === previousYear
    ) || { totalCost: 0 };

    const budgetForecastPercentage = calculatePercentageChange(
      totalPreviousForecastsData.totalCost,
      totalForecastsData.totalCost
    );

    const forecastPayload = {
      currentYear: totalForecastsData,
      previousYear: totalPreviousForecastsData,
      percentage: Math.abs(budgetForecastPercentage),
      isDown: budgetForecastPercentage > 0 ? false : true,
    };

    setForecastsCardData(forecastPayload);

    const currentDifference =
      totalForecastsData.totalCost - totalActualsData.totalCost;
    const previousDifference =
      totalPreviousForecastsData.totalCost - totalPreviousActualsData.totalCost;
    const budgetDifferencePercentage = calculatePercentageChange(
      previousDifference,
      currentDifference
    );

    const differencePayload = {
      currentYear: totalForecastsData.totalCost - totalActualsData.totalCost,
      previousYear:
        totalPreviousForecastsData.totalCost -
        totalPreviousActualsData.totalCost,
      percentage: Math.abs(budgetDifferencePercentage),
      isDown: budgetDifferencePercentage > 0 ? false : true,
    };
    setDifferenceData(differencePayload);
  }

  async function handleFetchBarData() {
    let params = "";

    if (filter.department) {
      params += `&department=${filter.department._id}`;
    }

    if (filter.businessUnit) {
      params += `&businessUnit=${filter.businessUnit._id}`;
    }

    if (filter.branch) {
      params += `&branch=${filter.branch._id}`;
    }

    if (filter.owner) {
      params += `&owner=${filter.owner._id}`;
    }

    const response = await apiRequest({
      api: "GetAnalytics",
      params: `/budget?year=${year}${params}`,
    });

    if (response.error) {
      // User not logged in
      if (response.statusCode === 401) {
        navigate("/login");
        return;
      }

      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    const forecastSeries = response.find((obj) => obj.name === "Forecast");
    const forecastLineChartDummyData = {
      series: [
        {
          name: "Forecast",
          data: forecastSeries.data, // Example data, replace with your actual data
        },
      ],
      options: {
        chart: {
          height: 320,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#004B50"],
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "rgba(145, 158, 171, 0.3)",
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: "rgba(145, 158, 171, 1)",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
          },
          axisBorder: {
            show: false,
          },
          categories: [
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
          ],
          axisTicks: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              if (value) {
                return (
                  `$` +
                  value.toLocaleString(0, {
                    maximumFractionDigits: 2,
                  })
                );
              } else {
                return "$0";
              }
            },
            style: {
              colors: "rgba(145, 158, 171, 1)",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex, w }) {
            // Get the data for the specific point
            var data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            // Return custom HTML
            return `<div style="padding: 10px; background: #333; color: #fff; border-radius: 5px;">
                      $${data.toLocaleString(0, {
                        maximumFractionDigits: 2,
                      })}
                    </div>`;
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };

    setForecastSeries(forecastLineChartDummyData);

    const actualsSeries = response.find((obj) => obj.name === "Actuals");
    const actualLineChartDummyData = {
      series: [
        {
          name: "Actuals",
          data: actualsSeries.data, // Example data, replace with your actual data
        },
      ],
      options: {
        chart: {
          height: 320,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#7A4100"], // Change this array to set the line color(s)
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "rgba(145, 158, 171, 0.3)", // Set the color of the grid lines
          strokeDashArray: 4, // Make the grid lines dashed
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false, // Hide x-axis grid lines
            },
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: "rgba(145, 158, 171, 1)",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
          },
          axisBorder: {
            show: false,
          },
          categories: [
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
          ],
          axisTicks: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              if (value) {
                return (
                  `$` +
                  value.toLocaleString(0, {
                    maximumFractionDigits: 2,
                  })
                );
              } else {
                return "$0";
              }
            },
            style: {
              colors: "rgba(145, 158, 171, 1)", // Set y-axis label color
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"', // Set y-axis label font family
            },
          },
        },
        tooltip: {
          theme: false,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
    setActualsSeries(actualLineChartDummyData);

    const forecastCumulativeSeries = response.find(
      (obj) => obj.name === "Forecast (Cumulative)"
    );
    const actualsCumulativeSeries = response.find(
      (obj) => obj.name === "Actuals (Cumulative)"
    );
    const cumulativeSeriesChart = {
      series: [
        {
          name: "Forecast (Cumulative)",
          data: forecastCumulativeSeries.data,
        },
        {
          name: "Actuals (Cumulative)",
          data: actualsCumulativeSeries.data,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        grid: {
          borderColor: "rgba(145, 158, 171, 0.3)", // Set the color of the grid lines
          strokeDashArray: 4, // Make the grid lines dashed
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false, // Hide x-axis grid lines
            },
          },
        },

        xaxis: {
          labels: {
            style: {
              colors: "rgba(145, 158, 171, 1)",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
          },
          axisBorder: {
            show: false,
          },
          categories: [
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
          ],
          axisTicks: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              if (value) {
                return (
                  `$` +
                  value.toLocaleString(0, {
                    maximumFractionDigits: 2,
                  })
                );
              } else {
                return "$0";
              }
            },
            style: {
              colors: "rgba(145, 158, 171, 1)", // Set y-axis label color
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"', // Set y-axis label font family
            },
          },
        },
        fill: {
          opacity: 1,
          colors: ["rgb(0, 120, 103)", "rgb(255, 171, 0)"],
        },
        legend: {
          show: false, // Remove the legend
        },
        zoom: {
          enabled: false,
        },
      },
    };

    // Forecast Cumulative Total
    if (forecastCumulativeSeries.data.length > 0) {
      setForecastCumulativeTotal(
        forecastCumulativeSeries.data[forecastCumulativeSeries.data.length - 1]
      );
    } else {
      setForecastCumulativeTotal(0);
    }

    // Actuals Cumulative Total
    if (actualsCumulativeSeries.data.length > 0) {
      setActualsCumulativeTotal(
        actualsCumulativeSeries.data[actualsCumulativeSeries.data.length - 1]
      );
    } else {
      setActualsCumulativeTotal(0);
    }

    setCumulativeSeries(cumulativeSeriesChart);

    handleFetchCardData(
      forecastCumulativeSeries.data.length > 0
        ? forecastCumulativeSeries.data[
            forecastCumulativeSeries.data.length - 1
          ]
        : 0
    );
  }

  function calculatePercentageChange(oldValue, newValue) {
    if (oldValue === 0 || newValue === 0) {
      return 100;
    }
    const percentageChange = ((newValue - oldValue) / Math.abs(oldValue)) * 100;
    const roundedPercentageChange = Math.round(percentageChange);
    return roundedPercentageChange;
  }

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    handleFetchBarData();
  }, []);

  useEffect(() => {
    handleFetchBarData();
  }, [year]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterBadgeCount = () => {
    const filterArrayValues = Object.values(filter);
    const countNonEmpty = filterArrayValues.filter(
      (item) => item !== null && item.length !== 0
    ).length;
    return countNonEmpty;
  };

  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const mode = useSelector((state) => state.customization.navType);

  return (
    <MainCard>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Grid
          container
          sx={{
            flexFlow: "wrap",
            minWidth: 0,
            boxSizing: "border-box",
            display: "flex",
            margin: "calc(var(24px) / -2) calc(var(24px) / -2)",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              direction: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                gap: "24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Card
                sx={{
                  backgroundImage: "none",
                  overflow: "hidden",
                  position: "relative",
                  borderRadius: "16px",
                  zIndex: 0,
                  padding: "24px",
                }}
              >
                <Box
                  sx={{
                    gap: "16px",
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 15,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      {/* Total Surplus */}
                      {value === 1 ? "Total Unforecasted" : "Total Surplus"}
                    </Typography>
                    {value === 1 ? (
                      <Typography
                        sx={{
                          fontSize: 32,
                          fontWeight: 700,
                          mt: 1,
                          fontFamily:
                            'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        $
                        {(unforecastedTotal ?? 0).toLocaleString(0, {
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: 32,
                          fontWeight: 700,
                          mt: 1,
                          fontFamily:
                            'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        $
                        {differenceData.currentYear.toLocaleString(0, {
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    sx={{
                      minWidth: 120,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                      <GanttFilter filter={filter} setFilter={setFilter} />
                    </Menu>

                    <Badge
                      badgeContent={filterBadgeCount()}
                      color="primary"
                      invisible={filterBadgeCount() < 0}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<FilterListIcon style={{ width: 17 }} />}
                        onClick={handleClick}
                        sx={{
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          color: theme.palette.text.primary,
                          borderColor: theme.palette.background.border,
                          borderRadius: 2,
                          height: "40px",
                          "&:hover": {
                            borderColor: theme.palette.background.border,
                            color: theme.palette.text.primary,
                          },
                        }}
                      >
                        Filter
                      </Button>
                    </Badge>

                    <FormControl
                      sx={{ ml: 1.5, my: 1, minWidth: 120 }}
                      size="small"
                    >
                      <Select
                        value={year}
                        onChange={handleChangeYear}
                        sx={{
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          borderRadius: 20, // Adjust the border radius here
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2, // Ensure the border radius applies to the outline
                          },
                        }}
                        classes={{
                          select: dropdownClasses.select,
                        }}
                      >
                        {/* Generate years in the past */}
                        {Array.from({ length: 4 }, (_, index) => {
                          const currentYear = new Date().getFullYear();
                          const yearValue = currentYear - index;
                          const yearLabel = `${yearValue}/${yearValue + 1}`;
                          return (
                            <MenuItem
                              key={`past_${index}`}
                              value={yearValue.toString()}
                            >
                              {yearLabel}
                            </MenuItem>
                          );
                        }).reverse()}
                        {/* Generate years in the future */}
                        {Array.from({ length: 11 }, (_, index) => {
                          const currentYear = new Date().getFullYear();
                          const yearValue = currentYear + index + 1; // Add 1 to skip the current year
                          const yearLabel = `${yearValue}/${yearValue + 1}`;
                          return (
                            <MenuItem
                              key={`future_${index}`}
                              value={yearValue.toString()}
                            >
                              {yearLabel}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  sx={{
                    "& .MuiTabs-indicator": {
                      position: "absolute",
                      height: "100%",
                      transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      borderRadius: "16px",
                      backgroundColor: mode === "dark" ? "#242424" : "#fff",
                    },
                    overflow: "hidden",
                    display: "flex",
                    padding: "14px",
                    minHeight: "38px",
                    alignItems: "center",
                    borderRadius: "16px",
                    backgroundColor: mode === "dark" ? "#1c1c1c" : "#F4F6F8",
                    marginTop: "24px",
                    marginBottom: "24px",
                    "& .MuiTabs-scroller": {
                      paddingY: "8px",
                    },
                  }}
                >
                  <Tab
                    value={1}
                    sx={{
                      maxWidth: "none",
                      flex: 1,
                      color: theme.palette.text.primary,
                      background: "transparent",
                      fontWeight: 600,
                      zIndex: 1,
                      minHeight: "auto",
                      padding: "14px",
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                      outline: "0px",
                      border: "0px",
                      margin: "0px",
                      borderRadius: "16px",
                      cursor: "pointer",
                      verticalAlign: "middle",
                      appearance: "none",
                      textDecoration: "none",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      transition:
                        "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      "&.Mui-selected": {
                        color: mode === "dark" ? "#141A21" : "#fff",
                      },
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          gap: "20px",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          width: "100%", // Ensures content fills the tab width
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              width: "48px",
                              height: "48px",
                              flexShrink: 0,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#004B50",
                              mr: 2,
                              color: "white",
                            }}
                          >
                            <IconArrowUpLeft
                              style={{
                                width: "24px",
                                height: "24px",
                                display: "inline-flex",
                                flexShrink: 0,
                              }}
                            />
                          </Box>

                          <Box sx={{ textAlign: "start " }}>
                            <Typography
                              sx={{
                                color: theme.palette.text.primary,
                                fontWeight: 600,
                                fontSize: 14,
                                fontFamily:
                                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                              }}
                            >
                              Forecasts
                            </Typography>
                            {forecastsCardData && (
                              <Typography
                                sx={{
                                  color: theme.palette.text.primary,
                                  fontWeight: 700,
                                  fontSize: 24,
                                  mt: 0.5,
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                }}
                              >
                                $
                                {forecastsCardData.currentYear.totalCost.toLocaleString(
                                  0,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {forecastsCardData && (
                          <Box
                            sx={{
                              height: "24px",
                              minWidth: "24px",
                              cursor: "default",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                              display: "inline-flex",
                              justifyContent: "center",
                              padding: "0px 6px",
                              fontWeight: 700,
                              borderRadius: "6px",
                              transition:
                                "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                              color: forecastsCardData.isDown
                                ? "#B71D18"
                                : "#118D57",
                              backgroundColor: forecastsCardData.isDown
                                ? "rgba(255, 86, 48, 0.16)"
                                : "rgba(34, 197, 94, 0.16)",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                mr: "6px",
                                alignItems: "center",
                              }}
                            >
                              {forecastsCardData.isDown ? (
                                <ArrowDownwardIcon
                                  style={{
                                    marginRight: 2,
                                    objectFit: "cover",
                                    height: "15px",
                                    width: "15px",
                                    color: forecastsCardData.isDown
                                      ? "#B71D18"
                                      : "#118D57",
                                  }}
                                />
                              ) : (
                                <ArrowUpwardIcon
                                  style={{
                                    marginRight: 2,
                                    objectFit: "cover",
                                    height: "15px",
                                    width: "15px",
                                    color: forecastsCardData.isDown
                                      ? "#B71D18"
                                      : "#118D57",
                                  }}
                                />
                              )}
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  whiteSpace: "nowrap",
                                  color: forecastsCardData.isDown
                                    ? "#B71D18"
                                    : "#118D57",
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                }}
                              >
                                {forecastsCardData.percentage}%
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    }
                    tabIndex={0}
                    aria-selected={true}
                  />
                  <Tab
                    value={2}
                    sx={{
                      maxWidth: "none",
                      flex: 1,
                      color: theme.palette.text.primary,
                      background: "transparent",
                      fontWeight: 600,
                      zIndex: 1,
                      minHeight: "auto",
                      padding: "14px",
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                      outline: "0px",
                      border: "0px",
                      margin: "0px",
                      borderRadius: "16px",
                      cursor: "pointer",
                      verticalAlign: "middle",
                      appearance: "none",
                      textDecoration: "none",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      transition:
                        "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      "&.Mui-selected": {
                        color: mode === "dark" ? "#141A21" : "#fff",
                      },
                    }}
                    label={
                      <Box
                        sx={{
                          gap: "20px",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          width: "100%", // Ensures content fills the tab width
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              width: "48px",
                              height: "48px",
                              flexShrink: 0,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#7A4100",
                              mr: 2,
                              color: "#fff",
                            }}
                          >
                            <IconArrowDownRight
                              style={{
                                width: "24px",
                                height: "24px",
                                display: "inline-flex",
                                flexShrink: 0,
                              }}
                            />
                          </Box>

                          <Box sx={{ textAlign: "start " }}>
                            <Typography
                              sx={{
                                color: theme.palette.text.primary,
                                fontWeight: 600,
                                fontSize: 14,
                                fontFamily:
                                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                              }}
                            >
                              Actuals
                            </Typography>
                            {actualsCardData && (
                              <Typography
                                sx={{
                                  color: theme.palette.text.primary,
                                  fontWeight: 700,
                                  fontSize: 24,
                                  mt: 0.5,
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                }}
                              >
                                $
                                {actualsCardData.currentYear.totalCost.toLocaleString(
                                  0,
                                  {
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {actualsCardData && (
                          <Box
                            sx={{
                              height: "24px",
                              minWidth: "24px",
                              cursor: "default",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                              display: "inline-flex",
                              justifyContent: "center",
                              padding: "0px 6px",
                              fontWeight: 700,
                              borderRadius: "6px",
                              transition:
                                "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                              color: actualsCardData.isDown
                                ? "#B71D18"
                                : "#118D57",
                              backgroundColor: actualsCardData.isDown
                                ? "rgba(255, 86, 48, 0.16)"
                                : "rgba(34, 197, 94, 0.16)",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                mr: "6px",
                                alignItems: "center",
                                color: "#B71D18",
                              }}
                            >
                              {actualsCardData.isDown ? (
                                <ArrowDownwardIcon
                                  style={{
                                    marginRight: 2,
                                    objectFit: "cover",
                                    height: "15px",
                                    width: "15px",
                                    color: actualsCardData.isDown
                                      ? "#B71D18"
                                      : "#118D57",
                                  }}
                                />
                              ) : (
                                <ArrowUpwardIcon
                                  style={{
                                    marginRight: 2,
                                    objectFit: "cover",
                                    height: "15px",
                                    width: "15px",
                                    color: actualsCardData.isDown
                                      ? "#B71D18"
                                      : "#118D57",
                                  }}
                                />
                              )}
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  whiteSpace: "nowrap",
                                  color: actualsCardData.isDown
                                    ? "#B71D18"
                                    : "#118D57",
                                  fontFamily:
                                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                }}
                              >
                                {actualsCardData.percentage}%
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    }
                    tabIndex={0}
                    aria-selected={true}
                  />
                </Tabs>
                {/* Forecast / Actuals chart */}
                <Box
                  sx={{
                    width: "100%",
                    height: "270px",
                    borderRadius: "12px",
                    position: "relative",
                  }}
                >
                  {forecastSeries && actualsSeries && (
                    <Chart
                      options={
                        value === 1
                          ? forecastSeries.options
                          : actualsSeries.options
                      }
                      series={
                        value === 1
                          ? forecastSeries.series
                          : actualsSeries.series
                      }
                      type="line"
                      height={270}
                    />
                  )}
                </Box>
              </Card>
              {/* Second Card */}
              <Card
                sx={{
                  backgroundImage: "none",
                  overflow: "hidden",
                  position: "relative",
                  borderRadius: "16px",
                  zIndex: 0,
                  padding: "24px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ flex: "1 1 auto" }}>
                    <Typography
                      sx={{
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    >
                      Cumulative Statistics
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        fontWeight: 400,
                        fontSize: 14,
                        color: "#637381",
                      }}
                    >
                      Statistics on forecasts & actuals
                    </Typography>
                  </Box>
                </Box>
                {/* Legend */}
                <Stack
                  sx={{
                    display: "flex",
                    flexFlow: "wrap",
                    mt: 4,
                    gap: "24px",
                  }}
                >
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          mr: 1,
                          width: "12px",
                          height: "12px",
                          flexShrink: 0,
                          display: "flex",
                          borderRadius: "50%",
                          position: "relative",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "currentColor",
                          color: "rgb(0, 120, 103)",
                        }}
                      ></Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Forecast (Cumulative)
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 20,
                        mt: 1,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      $
                      {forecastCumulativeTotal.toLocaleString(0, {
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Box>
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          mr: 1,
                          width: "12px",
                          height: "12px",
                          flexShrink: 0,
                          display: "flex",
                          borderRadius: "50%",
                          position: "relative",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "currentColor",
                          color: "rgb(255, 171, 0)",
                        }}
                      ></Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Actuals (Cumulative)
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 20,
                        mt: 1,
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      $
                      {actualsCumulativeTotal.toLocaleString(0, {
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </Box>
                </Stack>
                {/* Bar Chart */}
                <Box
                  sx={{
                    width: "100%",
                    height: "320px",
                    borderRadius: "12px",
                    position: "relative",
                    pt: 3,
                    mb: 2,
                  }}
                >
                  {cumulativeSeries && (
                    <Chart
                      options={cumulativeSeries.options}
                      series={cumulativeSeries.series}
                      type="bar"
                      height={320}
                    />
                  )}
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  );
};

export default AnalyticsPage;
