import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { apiRequest } from "Helper/ApiRequest";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import MainCard from "ui-component/cards/MainCard";
import { enqueueSnackbar } from "notistack";

const monthsMap = {
  0: "July",
  1: "August",
  2: "September",
  3: "October",
  4: "November",
  5: "December",
  6: "January",
  7: "February",
  8: "March",
  9: "April",
  10: "May",
  11: "June",
};

const AddUpdateForecastActuals = ({ isActuals }) => {
  let navigate = useNavigate();

  const { id } = useParams();

  const [currentBudget, setCurrentBudget] = useState(null);
  const [totalReprovisions, setTotalReprovisions] = useState(0);

  const [openAlert, setOpenAlert] = useState(false);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const [actualObject, setActualObject] = useState(null);
  const [forecasts, setForecasts] = useState(null);
  const [dataPayloads, setDataPayloads] = useState({
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
  });

  const [year, setYear] = useState("2024/2025");

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 2;
  const years = Array.from({ length: 15 }, (_, index) => {
    const year = startYear + index;
    return `${year}/${year + 1}`;
  });

  async function handleFetchActuals() {
    const response = await apiRequest({
      api: "ListActuals",
      params: `?project=${id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });
      return;
    }

    const filteredData = response.filter((item) => item.year === year);
    if (filteredData.length > 0) {
      setActualObject(filteredData[0]);
      const actualsData = filteredData[0].actuals;
      if (actualsData) {
        const updatedActuals = { ...dataPayloads }; // Make a copy of the state
        actualsData.forEach((actual) => {
          const monthName = monthsMap[actual.month]; // Get month name from monthsMap
          updatedActuals[monthName] = actual.cost; // Update actuals state with cost
        });
        setDataPayloads(updatedActuals); // Update the state
      }
    }
  }

  async function handleFetchForecasts() {
    const response = await apiRequest({
      api: "ListForecasts",
      params: `?project=${id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });
      return;
    }

    const filteredData = response.filter(
      (item) => item.year === year && item.isActive === true
    );

    setForecasts(filteredData);
    if (filteredData.length > 0) {
      const forecastsData = filteredData[0].forecasts;
      if (forecastsData) {
        const updatedForecasts = { ...dataPayloads }; // Make a copy of the state
        forecastsData.forEach((forecast) => {
          const monthName = monthsMap[forecast.month]; // Get month name from monthsMap
          updatedForecasts[monthName] = forecast.cost; // Update actuals state with cost
        });
        setDataPayloads(updatedForecasts); // Update the state
      }
    }
  }

  async function handleResetPayloads() {
    setDataPayloads({
      July: 0,
      August: 0,
      September: 0,
      October: 0,
      November: 0,
      December: 0,
      January: 0,
      February: 0,
      March: 0,
      April: 0,
      May: 0,
      June: 0,
    });
    setActualObject(null);
    setForecasts(null);
  }

  useEffect(() => {
    handleFetchBudget();
    if (isActuals) {
      handleFetchActuals();
      return;
    } else {
      handleFetchForecasts();
      return;
    }
  }, []);

  useEffect(() => {
    handleFetchBudget();
    if (isActuals) {
      handleResetPayloads();
      handleFetchActuals();
      return;
    } else {
      handleResetPayloads();
      handleFetchForecasts();
      return;
    }
  }, [year]);

  const handleMonthCostChange = (month, cost) => {
    // Ensure cost is a valid number
    if (!isNaN(cost)) {
      // Make a copy of the current state
      const updatedActuals = { ...dataPayloads };
      // Update the cost for the specified month
      updatedActuals[month] = parseFloat(cost);
      // Set the updated state
      setDataPayloads(updatedActuals);
    }
  };

  const handleUpdateBaseForecastBool = async (forecast) => {
    const changePayload = {
      isActive: false,
    };

    const changeUpdateResponse = await apiRequest({
      api: "UpdateForecasts",
      params: `/${forecast._id}`,
      body: changePayload,
    });

    if (changeUpdateResponse.error) {
      enqueueSnackbar(changeUpdateResponse.error, { variant: "error" });
      return;
    }

    enqueueSnackbar("Updated Forecast!", { variant: "success" });
  };

  async function handleUpdateForecasts() {
    const forecastsArray = Object.keys(dataPayloads).map((key) => ({
      month: getMonthIndex(key), // Get the index of the month from monthsMap
      cost: dataPayloads[key],
    }));

    if (forecasts && forecasts.length > 0) {
      if (forecasts.length > 1) {
        const updateForecast = forecasts[1];
        const payload = {
          forecasts: forecastsArray,
          isActive: true,
        };

        const updateResponse = await apiRequest({
          api: "UpdateForecasts",
          params: `/${updateForecast._id}`,
          body: payload,
        });

        if (updateResponse.error) {
          enqueueSnackbar(updateResponse.error, { variant: "error" });
          return;
        }

        const changeActiveForecast = forecasts[0];
        await handleUpdateBaseForecastBool(changeActiveForecast);

        const projectID = updateForecast.project._id;
        enqueueSnackbar("Updated Forecast!", { variant: "success" });
        navigate(`/projects/${projectID}`);
      } else {
        const payload = {
          project: id,
          year: year,
          forecasts: forecastsArray,
          isActive: true,
        };

        const createResponse = await apiRequest({
          api: "CreateForecasts",
          body: payload,
        });

        if (createResponse.error) {
          enqueueSnackbar(createResponse.error, { variant: "error" });
          return;
        }

        const changeActiveForecast = forecasts[0];
        await handleUpdateBaseForecastBool(changeActiveForecast);

        const projectID = createResponse.project;
        enqueueSnackbar("Updated Forecast!", { variant: "success" });
        navigate(`/projects/${projectID}`);
      }
    } else {
      const forecastsArray = Object.keys(dataPayloads).map((key) => ({
        month: getMonthIndex(key), // Get the index of the month from monthsMap
        cost: dataPayloads[key],
      }));

      const payload = {
        project: id,
        year: year,
        forecasts: forecastsArray,
        isActive: true,
      };

      const createResponse = await apiRequest({
        api: "CreateForecasts",
        body: payload,
      });

      if (createResponse.error) {
        enqueueSnackbar(createResponse.error, { variant: "error" });
        return;
      }

      const projectID = createResponse.project;
      enqueueSnackbar("Updated Forecast!", { variant: "success" });
      navigate(`/projects/${projectID}`);
    }
  }

  async function handleFetchProjectBudget(actuals) {
    const response = await apiRequest({
      api: "GetPrioritiedProjects",
      params: `/${id}`,
    });

    if (response.error) {
      console.log(response.error);
      return;
    }

    const budgets = response.filter((budget) => budget.year === year);
    const totalActualsCost = actuals.reduce(
      (accumulator, currentValue) => accumulator + currentValue.cost,
      0
    );

    if (budgets.length > 0) {
      const budget = budgets[0];
      const totalBudgetCost = budget.cost + Number(budget.reprovision ?? 0);
      if (totalBudgetCost > totalActualsCost) {
        const budgetReprovisions = totalBudgetCost - totalActualsCost;
        setTotalReprovisions(budgetReprovisions);
        return budgetReprovisions;
      }
    }
    return 0;
  }

  async function handleFetchBudget() {
    const response = await apiRequest({
      api: "GetPrioritiedProjects",
      params: `/${id}`,
    });

    if (response.error) {
      console.log(response.error);
      return;
    }

    const budgets = response.filter((budget) => budget.year === year);
    if (budgets.length > 0) {
      setCurrentBudget(budgets[0]);
    } else {
      setCurrentBudget(null);
    }
  }

  const [reproChecked, setReproChecked] = useState(false);

  const handleChangeRepro = (event) => {
    setReproChecked(event.target.checked);
  };

  async function handleUpdateActuals(noSelected) {
    if (actualObject) {
      const actualsArray = Object.keys(dataPayloads).map((key) => ({
        month: getMonthIndex(key), // Get the index of the month from monthsMap
        cost: dataPayloads[key],
      }));

      const payload = {
        actuals: actualsArray,
      };
      if (!noSelected && reproChecked === true) {
        const remainingBudget = await handleFetchProjectBudget(actualsArray);

        if (remainingBudget > 0) {
          setOpenAlert(true);
          return;
        }
      }

      const updateResponse = await apiRequest({
        api: "UpdateActuals",
        params: `/${actualObject._id}`,
        body: payload,
      });

      if (updateResponse.error) {
        enqueueSnackbar(updateResponse.error, { variant: "error" });
        return;
      }

      const projectID = actualObject.project._id;
      enqueueSnackbar("Updated Actuals!", { variant: "success" });
      navigate(`/projects/${projectID}`);
    } else {
      const actualsArray = Object.keys(dataPayloads).map((key) => ({
        month: getMonthIndex(key), // Get the index of the month from monthsMap
        cost: dataPayloads[key],
      }));

      const payload = {
        project: id,
        year: year,
        reprovision: 0,
        unallocated: 0,
        actuals: actualsArray,
      };

      if (!noSelected && reproChecked === true) {
        const remainingBudget = await handleFetchProjectBudget(actualsArray);
        console.log(remainingBudget);

        if (remainingBudget > 0) {
          setOpenAlert(true);
          return;
        }
      }

      const createResponse = await apiRequest({
        api: "CreateActuals",
        body: payload,
      });

      if (createResponse.error) {
        enqueueSnackbar(createResponse.error, { variant: "error" });
        return;
      }
      const projectID = createResponse.project;
      enqueueSnackbar("Updated Actuals!", { variant: "success" });
      navigate(`/projects/${projectID}`);
    }
  }

  function getMonthIndex(monthName) {
    for (const [index, name] of Object.entries(monthsMap)) {
      if (name === monthName) {
        return parseInt(index);
      }
    }
    return -1; // Return -1 if the month name is not found
  }

  async function handleCreateUpdateBudgetReprovision(nextYear) {
    const response = await apiRequest({
      api: "GetPrioritiedProjects",
      params: `/${id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });
      return;
    }

    const projectObj = await apiRequest({
      api: "ListProjects",
      params: `/${id}`,
    });

    if (!projectObj) {
      enqueueSnackbar("Error: Project not found.", { variant: "error" });
      return;
    }

    const budgets = response.filter((budget) => budget.year === nextYear);
    if (budgets.length > 0) {
      const updateBudget = budgets[0];
      const updateBudgetPayload = {
        reprovision: Number(totalReprovisions),
      };

      const updateResponse = await apiRequest({
        api: "UpdateProjectPriority",
        params: `/${updateBudget._id}`,
        body: updateBudgetPayload,
      });

      if (updateResponse.error) {
        enqueueSnackbar(updateResponse.error, { variant: "error" });
        return;
      }
    } else {
      const budgetPayload = {
        cost: Number(0),
        year: nextYear,
        strength: Number(0),
        reprovision: Number(totalReprovisions),
        subprogram: projectObj.subprogram._id,
        project: id,
      };

      const priorityResponse = await apiRequest({
        api: "CreateProjectPriority",
        body: budgetPayload,
      });

      if (priorityResponse.error) {
        enqueueSnackbar(priorityResponse.error, { variant: "error" });
        return;
      }
      console.log("CREATE : ", priorityResponse);
    }
  }

  async function handleUpdateActualsReprovision() {
    const stringSplitYear = year.split("/");
    const nextYearOne = Number(stringSplitYear[0]) + 1;
    const nextYearTwo = Number(stringSplitYear[1]) + 1;
    const nextYear = `${nextYearOne}/${nextYearTwo}`;

    await handleCreateUpdateBudgetReprovision(nextYear);

    const actualsArray = Object.keys(dataPayloads).map((key) => ({
      month: getMonthIndex(key), // Get the index of the month from monthsMap
      cost: dataPayloads[key],
    }));

    if (actualObject) {
      const payload = {
        actuals: actualsArray,
      };

      const updateResponse = await apiRequest({
        api: "UpdateActuals",
        params: `/${actualObject._id}`,
        body: payload,
      });

      if (updateResponse.error) {
        enqueueSnackbar(updateResponse.error, { variant: "error" });
        return;
      }

      const projectID = actualObject.project._id;
      enqueueSnackbar("Updated Actuals!", { variant: "success" });
      navigate(`/projects/${projectID}`);
    } else {
      const payload = {
        project: id,
        year: year,
        reprovision: 0,
        unallocated: totalReprovisions,
        actuals: actualsArray,
      };

      const createResponse = await apiRequest({
        api: "CreateActuals",
        body: payload,
      });

      if (createResponse.error) {
        enqueueSnackbar(createResponse.error, { variant: "error" });
        return;
      }
      const projectID = createResponse.project;
      enqueueSnackbar("Updated Actuals!", { variant: "success" });
      navigate(`/projects/${projectID}`);
    }
  }

  const totalBudget = () => {
    if (currentBudget) {
      return Number(currentBudget.cost ?? 0);
    } else {
      return 0;
    }
  };

  const totalRepoBudget = () => {
    if (currentBudget) {
      return currentBudget.cost + Number(currentBudget.reprovision ?? 0);
    }
    return 0;
  };

  const unallocatedTotal = () => {
    const totalsPayload = Object.values(dataPayloads).reduce(
      (sum, current) => sum + current,
      0
    );
    if (currentBudget) {
      return Number(
        currentBudget.cost +
          Number(currentBudget.reprovision ?? 0) -
          totalsPayload
      ).toLocaleString(0, {
        maximumFractionDigits: 2,
      });
    }
    return totalsPayload;
  };

  // Function to format number with commas
  const formatNumberWithCommas = (value) => {
    if (value === undefined || value === null || value === "") return "";
    return new Intl.NumberFormat().format(value);
  };

  // Function to parse the number removing commas
  const parseNumberFromString = (value) => {
    return parseFloat(value.replace(/,/g, "")) || 0;
  };

  return (
    <MainCard>
      <Dialog open={openAlert} onClose={handleCloseAlert}>
        <DialogTitle>
          <Typography
            fontSize={23}
            sx={{
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            }}
          >
            Remaining Budget - $
            {totalReprovisions.toLocaleString(0, {
              maximumFractionDigits: 2,
            })}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            }}
          >
            The remaining budget for {year} is $
            {totalReprovisions.toLocaleString(0, { maximumFractionDigits: 2 })}.
            Would you like to carry this over to the next year?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseAlert}
            autoFocus
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              boxSizing: "border-box",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              fontWeight: 700,
              fontSize: 15,
              height: "35px",
              padding: "6px 14px",
              borderRadius: "8px",
              backgroundColor: "transparent",
              color: "#1C252E",
              borderColor: "rgba(145, 158, 171, 0.32)",
              borderWidth: "1px",
              borderStyle: "solid",
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            No
          </Button>
          <Button
            onClick={handleUpdateActualsReprovision}
            variant="contained"
            size="small"
            sx={{
              fontWeight: 700,
              fontSize: 13,
              mr: 4,
              my: 2,
              minWidth: "64px",
              boxShadow: "none",
              height: "35px",
              padding: "6px 14px",
              borderRadius: "8px",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              backgroundColor: "#1C252E",
              "&:hover": {
                backgroundColor: "#2D3A45",
                boxShadow: "none",
              },
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* Header */}
      <Stack
        sx={{
          mb: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                margin: "0px 0px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              {isActuals ? "Actuals" : "Forecast"}
            </Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Dashboard
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Projects
              </Typography>

              <Typography
                sx={{
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
                color="text.primary"
              >
                {isActuals ? "Actuals" : "Forecast"}
              </Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Stack>
      {/* Content */}
      <Card
        sx={{
          backgroundImage: "none",
          position: "relative",
          boxShadow:
            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
          zIndex: 0,
          overflow: "hidden",
          borderRadius: "16px",
        }}
      >
        <Box
          sx={{
            m: 3,
            display: "flex",
            gap: "20px",
          }}
        >
          {/* Year Selector */}
          <FormControl
            fullWidth
            sx={{
              display: "inline-flex",
              verticalAlign: "top",
              flexDirection: "column",
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
              },
            }}
          >
            <InputLabel
              sx={{
                "&.MuiInputLabel-root.Mui-focused": {
                  color: "#1C252E",
                  fontWeight: 600,
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
              }}
            >
              Year
            </InputLabel>
            <Select
              label="Year"
              fullWidth
              value={year}
              onChange={handleChangeYear}
              sx={{
                height: 50,
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1C252E",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1C252E",
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  padding: "16px 12px",
                  fontSize: "14px",
                  background: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiSelect-icon": {
                  color: "#1C252E",
                },
              }}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  <ListItemText primary={year} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Total */}
          <FormControl fullWidth>
            <OutlinedInput
              type="text" // Change type to "text" to accommodate formatted numbers
              placeholder="0.00"
              value={formatNumberWithCommas(totalBudget())}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              disabled={true}
              sx={{
                maxHeight: "50px",
                background: "white",
                input: {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                    borderRadius: "8px",
                  },
                "& .MuiFormLabel-root": {
                  color: "#888", // Change this to the desired color
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#000", // Change this to the desired color when focused
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#000",
                  },
              }}
            />
          </FormControl>
        </Box>

        <Divider
          sx={{
            my: 2,
            borderWidth: "0px 0px thin",
            borderStyle: "dashed",
            borderColor: "rgba(145 158 171 / 0.2)",
          }}
        />

        {/* Months Fields */}
        <Grid container spacing={2} padding={3}>
          {/* Adjust xs and md values based on your design */}
          {Object.keys(monthsMap).map((index) => {
            const currentValue = dataPayloads[monthsMap[index]] ?? 0;
            const formattedValue = formatNumberWithCommas(currentValue);

            return (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <FormControl fullWidth>
                  <OutlinedInput
                    type="text" // Change type to "text" to handle formatted numbers
                    value={formattedValue}
                    onChange={(event) => {
                      const parsedValue = parseNumberFromString(
                        event.target.value
                      );
                      handleMonthCostChange(monthsMap[index], parsedValue);
                    }}
                    placeholder="$0.00"
                    startAdornment={
                      <InputAdornment position="start">
                        {monthsMap[index]}:
                      </InputAdornment>
                    }
                    sx={{
                      maxHeight: "50px",
                      background: "white",
                      input: {
                        backgroundColor: "white",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ddd",
                        borderRadius: "8px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#000",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                          borderRadius: "8px",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#888",
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#000",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                        },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#000",
                        },
                    }}
                  />
                </FormControl>
              </Grid>
            );
          })}
        </Grid>

        <Divider
          sx={{
            my: 2,
            borderWidth: "0px 0px thin",
            borderStyle: "dashed",
            borderColor: "rgba(145 158 171 / 0.2)",
          }}
        />
        {/* Total Breakdown */}
        <Stack sx={{ mr: 4, mt: 4, mb: 2 }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              alignItems: "flex-end",
              textAlign: "right",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              fontWeight: 400,
              fontSize: 13,
              lineHeight: 1.5,
            }}
          >
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                }}
              >
                Unforecasted
              </Typography>
              <Typography
                sx={{
                  width: "160px",
                  fontWeight: 600,
                  fontSize: "1rem",
                  lineHeight: 1.5,
                }}
              >
                $
                {unallocatedTotal().toLocaleString(0, {
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                }}
              >
                Reprovisions
              </Typography>
              <Typography
                sx={{
                  width: "160px",
                  fontWeight: 600,
                  fontSize: "1rem",
                  lineHeight: 1.5,
                }}
              >
                $
                {currentBudget &&
                  Number(currentBudget.reprovision ?? 0).toLocaleString(0, {
                    maximumFractionDigits: 2,
                  })}
              </Typography>
            </Stack>
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                Total
              </Typography>
              <Typography
                sx={{
                  width: "160px",
                  fontWeight: 600,
                  fontSize: "1rem",
                  lineHeight: 1.5,
                }}
              >
                $
                {totalRepoBudget().toLocaleString(0, {
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>

            {isActuals && (
              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: 400,
                  }}
                >
                  Reprovide Unallocated
                </Typography>
                <Box
                  sx={{
                    width: "160px",
                    fontWeight: 600,
                    fontSize: "1rem",
                    lineHeight: 1.5,
                  }}
                >
                  <Switch
                    checked={reproChecked}
                    onChange={handleChangeRepro}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Divider
          sx={{
            mt: 4,
            mb: 1,
            borderWidth: "0px 0px thin",
            borderStyle: "dashed",
            borderColor: "rgba(145 158 171 / 0.2)",
          }}
        />

        <Stack
          sx={{
            display: "flex",
            flexFlow: "wrap",
            gap: "24px",
            alignItems: "center",
            justifyContent: "flex-end", // Align items to the right: ;
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              if (isActuals) {
                handleUpdateActuals(false);
              } else {
                handleUpdateForecasts();
              }
            }}
            sx={{
              fontWeight: 700,
              fontSize: 13,
              mr: 4,
              my: 2,
              minWidth: "64px",
              boxShadow: "none",
              height: "40px",
              padding: "6px 16px",
              borderRadius: "8px",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              backgroundColor: "#1C252E",
              "&:hover": {
                backgroundColor: "#2D3A45",
                boxShadow: "none",
              },
            }}
          >
            Update
          </Button>
        </Stack>
      </Card>
    </MainCard>
  );
};

export default AddUpdateForecastActuals;
