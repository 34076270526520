import { TabContext, TabPanel } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { apiRequest } from "Helper/ApiRequest";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { useTheme } from "@mui/material/styles";
import { handleGetStrength } from "Helper/Tokens";
import { Fragment } from "react";
import MainCard from "ui-component/cards/MainCard";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import ProjectInformationDetails from "./components/details/ProjectInformationDetails";
import ProjectFinancialsDetails from "./components/details/ProjectFinancialsDetails";
import Bugsnag from "@bugsnag/js";

const ProjectDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [files, setFiles] = useState([]);

  let navigate = useNavigate();

  const [projectPriority, setProjectPriority] = useState([]);

  let theme = useTheme();

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function fetchProjectPriority() {
    const response = await apiRequest({
      api: "GetPrioritiedProjects",
      params: `/${id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    } else {
      setProjectPriority(response);
    }
  }

  const [view, setView] = useState("forecast");

  const handleChangeView = (event) => {
    setView(event.target.value);
  };

  const [actuals, setActuals] = useState(null);
  const [forecasts, setForecasts] = useState(null);

  async function handleFetchActuals(projectResponse) {
    const response = await apiRequest({
      api: "ListActuals",
      params: `?project=${projectResponse._id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    const sortedDataByYear = response.reduce((acc, item) => {
      const year = item.year;
      if (!acc[year]) {
        acc[year] = item;
      }
      return acc;
    }, {});

    const actualsArray = Object.entries(sortedDataByYear);

    // Step 2: Sort the array by year (ascending)
    actualsArray.sort((a, b) => {
      // Assuming year format "YYYY/YYYY", split and convert to numbers for comparison
      const yearA = parseInt(a[0].split("/")[0]);
      const yearB = parseInt(b[0].split("/")[0]);
      return yearA - yearB;
    });

    // Step 3: Convert sorted array back to object format if needed
    const sortedActuals = {};
    actualsArray.forEach(([key, value]) => {
      sortedActuals[key] = value;
    });

    handleFetchProjectBudget(sortedActuals);

    setActuals(sortedActuals);
  }

  async function handleFetchForecasts() {
    const response = await apiRequest({
      api: "ListForecasts",
      params: `?project=${project._id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    const activeForecasts = response.filter((item) => item.isActive === true);

    activeForecasts.sort((a, b) => {
      // Extract the numeric year part (assumes year format is "YYYY/YYYY")
      const yearA = parseInt(a.year.split("/")[0], 10);
      const yearB = parseInt(b.year.split("/")[0], 10);
      return yearA - yearB;
    });

    setForecasts(activeForecasts);
  }

  async function handleFetchProjectBudget(actuals) {
    const response = await apiRequest({
      api: "GetPrioritiedProjects",
      params: `/${project._id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    const totalDifferences = {};
    const totalListCost = {};
    for (let key in actuals) {
      const actualsArray = actuals[key];
      const totalCost = actualsArray.actuals.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.cost;
        },
        0
      );
      totalListCost[key] = totalCost;

      // Calculate difference, defaulting to 0 if either totalCost or budget is undefined
      const difference =
        typeof totalCost === "number" && typeof budget === "number"
          ? totalCost
          : 0;
      totalDifferences[key] = difference;
    }
  }

  async function fetchProject() {
    const response = await apiRequest({
      api: "ListProjects",
      params: `/${id}`,
    });
    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));

      return;
    } else {
      setProject(response);
      fetchProjectPriority();
      handleFetchFile(response);
    }
  }
  const [status, setStatus] = useState("");
  const [gateway, setGateway] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    fetchProject();
  }, []);

  useEffect(() => {
    if (project) {
      handleFetchActuals(project);
      handleFetchForecasts(project);
    }
  }, [project]);

  useEffect(() => {
    if (project) {
      setStatus(project.status);
      setGateway(
        project.currentGateway ? project.currentGateway : "notStarted"
      );
      setNotes(project.notes ? project.notes : "");
    }
  }, [project]);

  useEffect(() => {
    if (status && status.length > 0 && status !== project.status) {
      hanldeUpdateStatus();
    }
  }, [status]);

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeNotes = (event) => {
    setNotes(event.target.value);
  };

  async function handlePostComment() {
    console.log("Update comment... ", notes);
    const response = await apiRequest({
      api: "UpdateProject",
      params: `/${project._id}`,
      body: {
        notes: notes,
      },
    });
    if (response.error) {
      enqueueSnackbar(response.error, {
        variant: "error",
      });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    enqueueSnackbar("Comment Updated!", {
      variant: "success",
    });
  }

  useEffect(() => {
    if (gateway && gateway.length > 0 && gateway !== project.currentGateway) {
      hanldeUpdateGateway();
    }
  }, [gateway]);

  const handleChangeGateway = (event) => {
    setGateway(event.target.value);
  };

  async function hanldeUpdateGateway() {
    const payload = {
      currentGateway: gateway ? gateway : "notStarted",
    };

    const response = await apiRequest({
      api: "UpdateProject",
      params: `/${project._id}`,
      body: payload,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    setProject({
      ...project,
      currentGateway: gateway,
    });
    enqueueSnackbar("Updated Gateway!", { variant: "success" });
  }

  async function hanldeUpdateStatus() {
    const payload = {
      status: status ? status : "TBA",
    };

    const response = await apiRequest({
      api: "UpdateProject",
      params: `/${project._id}`,
      body: payload,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    setProject({
      ...project,
      status,
    });
    enqueueSnackbar("Updated Status!", { variant: "success" });
  }

  const canUserCreate = () => {
    return handleGetStrength() >= 4;
  };

  async function handleDeleteProject() {
    await handleDeleteForecasts();
    await handleDeleteActuals();
    await handleDeleteBudget();
    await handleDeletePriority();
    const response = await apiRequest({
      api: "DeleteProjects",
      params: `/${id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    enqueueSnackbar("Deleted", { variant: "success" });
    navigate("/projects");
  }

  async function handleDeleteForecasts() {
    const response = await apiRequest({
      api: "ListForecasts",
      params: `?project=${id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    for (let i = 0; i < response.length; i++) {
      const forecast = response[i];
      const forecastDeleteResponse = await apiRequest({
        api: "DeleteForecasts",
        params: `/${forecast._id}`,
      });

      if (forecastDeleteResponse.error) {
        enqueueSnackbar(forecastDeleteResponse.error, { variant: "error" });

        // Bugsnag error
        Bugsnag.notify(new Error(forecastDeleteResponse.error));
        return;
      }
    }
  }

  async function handleDeleteActuals() {
    const response = await apiRequest({
      api: "ListActuals",
      params: `?project=${id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    for (let i = 0; i < response.length; i++) {
      const actuals = response[i];
      const actualstDeleteResponse = await apiRequest({
        api: "DeleteActuals",
        params: `/${actuals._id}`,
      });

      if (actualstDeleteResponse.error) {
        enqueueSnackbar(actualstDeleteResponse.error, { variant: "error" });

        // Bugsnag error
        Bugsnag.notify(new Error(actualstDeleteResponse.error));
        return;
      }
    }
  }

  async function handleDeletePriority() {
    for (let i = 0; i < projectPriority.length; i++) {
      const deletePriority = projectPriority[i];
      const response = await apiRequest({
        api: "DeleteProjectPriority",
        params: `/${deletePriority._id}`,
      });

      if (response.error) {
        enqueueSnackbar(response.error, { variant: "error" });

        // Bugsnag error
        Bugsnag.notify(new Error(response.error));
        return;
      }
    }
  }

  async function handleDeleteBudget() {
    const response = await apiRequest({
      api: "GetProjectBudgets",
      params: `?project=${id}`,
    });

    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });

      // Bugsnag error
      Bugsnag.notify(new Error(response.error));
      return;
    }

    for (let i = 0; i < response.length; i++) {
      const budget = response[i];
      const deleteResponse = await apiRequest({
        api: "DeleteBudget",
        params: `/${budget._id}`,
      });

      if (deleteResponse.error) {
        enqueueSnackbar(deleteResponse.error, { variant: "error" });

        // Bugsnag error
        Bugsnag.notify(new Error(deleteResponse.error));
        return;
      }
    }
  }

  async function handleFetchFile(projectResponse) {
    setFiles([]);
    if (projectResponse.files.length > 0) {
      const newFiles = [];
      for (let i = 0; i < projectResponse.files.length; i++) {
        const file = projectResponse.files[i];
        const response = await apiRequest({
          api: "GetFile",
          params: `?file=${file}`,
        });

        if (response.error) {
          enqueueSnackbar(response.error, { variant: "error" });

          // Bugsnag error
          Bugsnag.notify(new Error(response.error));
          return;
        }

        const payload = {
          id: file,
          url: response.url,
        };

        // Assuming response.url contains the file URL, you can update this part accordingly
        newFiles.push(payload);
      }

      // Update the state with the new files
      setFiles(newFiles);
    }
  }

  const statusColours = {
    TBA: {
      colour: "#637381",
      backgroundColor: "rgba(145, 158, 171, 0.16)",
    },
    Monitor: {
      colour: "#B76E00",
      backgroundColor: "rgba(255, 171, 0, 0.16)",
    },
    "On Track": {
      colour: "#118D57",
      backgroundColor: "rgba(34, 197, 94, 0.16)",
    },
    "Action Required": {
      colour: "#B71D18",
      backgroundColor: "rgba(255, 86, 48, 0.16)",
    },
  };

  const budgetTotal = (item) => {
    if (item.reprovision) {
      return Number(item.reprovision + item.cost);
    }
    return item.cost;
  };

  const unixToDate = (time) => {
    var day = moment.unix(time);
    return day.format("MMMM Do YYYY, h:mm a");
  };

  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleClickDeleteOpen = () => {
    if (canUserCreate()) {
      setDeleteOpen(true);
    } else {
      setSnackBarText("Error: You don't have permission to delete projects.");
    }
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  return (
    <MainCard>
      {/* Delete Popup */}
      <Fragment>
        <Dialog open={deleteOpen} onClose={handleDeleteClose}>
          <Box
            sx={{
              width: 475,
            }}
          >
            <DialogTitle
              sx={{
                margin: 0,
                fontSize: 19,
                fontWeight: 500,
                flex: "0 0 auto",
                padding: "24px 24px 16px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              {"Delete"}
            </DialogTitle>
            <DialogContent
              sx={{
                flex: "1 1 auto",
                overflowY: "auto",
                padding: "0px 24px",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                fontWeight: 400,
              }}
            >
              <DialogContentText
                sx={{
                  color: "#1C252E",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                }}
              >
                Are you sure want to delete the project?
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                flex: "0 0 auto",
                padding: "24px",
              }}
            >
              <Button
                onClick={handleDeleteProject}
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  fontWeight: 700,
                  fontSize: 15,
                  padding: "6px 12px",
                  borderRadius: "8px",
                  backgroundColor: "#FF5630",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#FF5630",
                    boxShadow: "none",
                  },
                }}
              >
                Delete
              </Button>
              <Button
                onClick={handleDeleteClose}
                autoFocus
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                  fontFamily:
                    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                  fontWeight: 700,
                  fontSize: 15,
                  padding: "6px 12px",
                  borderRadius: "8px",
                  backgroundColor: "transparent",
                  color: theme.palette.text.primary,
                  borderColor: "rgba(145, 158, 171, 0.32)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  },
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Fragment>
      {/* Header */}
      <Stack
        sx={{
          mb: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {project && (
          <Stack sx={{ mb: "40px", display: "flex" }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "flex-start",
              }}
            >
              <IconButton
                sx={{ color: "#637381" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/projects");
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center", // Vertically center items
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 22,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      fontWeight: 700,
                      m: 0,
                    }}
                  >
                    {project.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      height: "24px",
                      minWidth: "24px",
                      alignItems: "center",
                      lineHeight: "24px", // Match the height of the Typography for proper vertical alignment
                      fontWeight: 700,
                      padding: "0px 6px",
                      borderRadius: "6px",
                      color: statusColours[project.status]?.colour || "black", // Fallback to 'black' if status is undefined
                      backgroundColor:
                        statusColours[project.status]?.backgroundColor ||
                        "grey", // Fallback to 'grey'
                      whiteSpace: "nowrap",
                      display: "inline-flex",
                      justifyContent: "center", // Center text horizontally within the badge
                    }}
                  >
                    {project.status}
                  </Typography>
                </Stack>
                {project && project.inserted && (
                  <Typography
                    sx={{
                      m: 0,
                      fontWeight: 400,
                      color: "#919EAB",
                      lineHeight: 1.5,
                      fontSize: 15,
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    {unixToDate(project.inserted)}
                  </Typography>
                )}
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  flexGrow: 1,
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {status && (
                  <FormControl
                    sx={{
                      display: "inline-flex",
                      verticalAlign: "top",
                      flexDirection: "column",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "8px",
                      },
                      "& .MuiSelect-select": {
                        fontSize: "14px", // Decrease font size
                        padding: "8px 12px", // Adjust padding for smaller height
                        lineHeight: "1.5", // Adjust line height
                      },
                    }}
                  >
                    <Select
                      value={status}
                      onChange={handleChangeStatus}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ddd",
                          borderRadius: "8px",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1C252E",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1C252E",
                        },
                        "& .MuiSelect-icon": {
                          color: "#1C252E", // Change this to your preferred color for the dropdown indicator
                        },
                      }}
                    >
                      <MenuItem value={"TBA"}>TBA</MenuItem>
                      <MenuItem value={"On Track"}>On Track</MenuItem>
                      <MenuItem value={"Monitor"}>Monitor</MenuItem>
                      <MenuItem value={"Action Required"}>
                        Action Required
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  disabled={!canUserCreate()}
                  onClick={() => {
                    navigate(`/projects/${id}/edit`);
                  }}
                  sx={{
                    padding: "6px 12px",
                    backgroundColor: "#1C252E",
                    fontWeight: 700,
                    borderRadius: "8px",
                    minWidth: "64px",
                    boxShadow: "none",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    "&:hover": {
                      backgroundColor: "#2D3A45",
                      boxShadow: "none",
                    },
                  }}
                >
                  Edit
                </Button>

                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  disabled={!canUserCreate()}
                  onClick={() => handleClickDeleteOpen()}
                  sx={{
                    color: "#B71D18",
                    padding: "6px 12px",
                    backgroundColor: "rgba(255, 86, 48, 0.16)",
                    fontWeight: 700,
                    borderRadius: "8px",
                    minWidth: "64px",
                    boxShadow: "none",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    "&:hover": {
                      backgroundColor: "rgba(255, 86, 48, 0.1)",
                      boxShadow: "none",
                    },
                  }}
                >
                  Delete
                </Button>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
      {/* Tabs */}
      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            ml: 2,
            overflow: "hidden",
            minHeight: "48px",
            display: "flex",
            "& .MuiTabs-indicator": {
              // backgroundColor: theme.palette.text.primary, // Indicator color
              background: theme.palette.text.primary,
              height: "2px", // Indicator height
              width: "75px !important",
              borderRadius: "4px", // Optional: Rounded corners
            },
            "& .MuiTab-root": {
              minWidth: 0, // Adjust tab width if needed
              width: "75px !important",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
            "& .Mui-selected": {
              color: `${theme.palette.text.primary} !important`, // Force selected tab text color
              // fontWeight: 'bold', // Optional: Make selected tab text bold
            },
            "& .MuiTabs-flexContainer": {
              // backgroundColor: theme.palette.text.primary, // Indicator color
              gap: "20px",
            },
          }}
        >
          <Tab value="1" label="Information" />
          <Tab value="2" label="Financials" />
        </Tabs>
        <TabPanel className="p-0 pt-2" value="1">
          <ProjectInformationDetails
            project={project}
            files={files}
            gateway={gateway}
            handleChangeGateway={handleChangeGateway}
            notes={notes}
            handleChangeNotes={handleChangeNotes}
            handlePostComment={handlePostComment}
          />
        </TabPanel>
        <TabPanel className="p-0 pt-2" value="2">
          <ProjectFinancialsDetails
            project={project}
            projectPriority={projectPriority}
            budgetTotal={budgetTotal}
            view={view}
            handleChangeView={handleChangeView}
            canUserCreate={canUserCreate}
            forecasts={forecasts}
            actuals={actuals}
          />
        </TabPanel>
      </TabContext>
    </MainCard>
  );
};
export default ProjectDetails;
