import React from "react";
import {
  Box,
  Container,
  Typography,
} from "@mui/material";
import moment from "moment";


const statusColours = {
  TBA: {
    colour: "#637381",
    backgroundColor: "rgba(145, 158, 171, 0.16)",
  },
  Monitor: {
    colour: "#B76E00",
    backgroundColor: "rgba(255, 171, 0, 0.16)",
  },
  "On Track": {
    colour: "#118D57",
    backgroundColor: "rgba(34, 197, 94, 0.16)",
  },
  "Action Required": {
    colour: "#B71D18",
    backgroundColor: "rgba(255, 86, 48, 0.16)",
  },
};

const GanttChartPopup = ({ project }) => {

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          py: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            fontFamily:
              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
        >
          <Typography sx={{ fontFamily: "inherit", color: "#637381" }}>
            Name
          </Typography>
          <Typography sx={{ fontFamily: "inherit", fontWeight: 600 }}>
            {project.name}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            }}
          >
            <Typography sx={{ fontFamily: "inherit", color: "#637381" }}>
              Status
            </Typography>
            <Typography
              sx={{
                fontSize: 13,
                height: "24px",
                minWidth: "24px",
                alignItems: "center",
                lineHeight: 0,
                fontWeight: 700,
                padding: "0px 6px",
                borderRadius: "6px",
                color: statusColours[project.status].colour,
                backgroundColor: statusColours[project.status].backgroundColor,
                whiteSpace: "nowrap",
                justifyContent: "center",
                display: "inline-flex",
                fontFamily:
                  '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              }}
            >
              {project.status}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            }}
          >
            <Typography sx={{ fontFamily: "inherit", color: "#637381" }}>
              Range
            </Typography>
            <Typography sx={{ fontFamily: "inherit", fontWeight: 600 }}>
              {moment(project.infoStart).format("DD/MM/YYYY")} -{" "}
              {moment(project.infoEnd).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default GanttChartPopup;
