import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import NotesIcon from "@mui/icons-material/Notes";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LayersIcon from "@mui/icons-material/Layers";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useRef } from "react";
import ReactGA from "react-ga4";

function useIsSmallDevice() {
  const [isSmallDevice, setIsSmallDevice] = useState(
    window.matchMedia("(max-width: 600px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallDevice(window.matchMedia("(max-width: 600px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isSmallDevice;
}

const PublicHomePage = () => {
  let navigate = useNavigate();

  const isSmallDevice = useIsSmallDevice();

  const [scrollScale, setScrollScale] = useState(0);
  const [transformX, setTransformX] = useState(0);
  const [transformFirstY, setTransformFirstY] = useState(0);
  const [transformSecondY, setTransformSecondY] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const [inView, setInView] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    // Google Anayltics
    ReactGA.send({
      hitType: "pageview",
      page: "/public",
      title: "Public",
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const scrollPercent = scrollTop / (scrollHeight - clientHeight);
      setScrollScale(scrollPercent);

      // Calculate the new transform value for the first element
      const newTransformFirstY = Math.max(
        -700,
        Math.min(0, (-scrollTop * 700) / 1440)
      );
      setTransformFirstY(newTransformFirstY);

      // Calculate the new transform value for the second element
      const newTransformSecondY = Math.max(
        -400,
        Math.min(0, (-scrollTop * 400) / 1440)
      );
      setTransformSecondY(newTransformSecondY);

      // Calculate the new opacity value
      const newOpacity = Math.max(0, 1 - scrollTop / 1440);
      setOpacity(newOpacity);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Call on mount to set initial values

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Define a threshold for when to start applying the transform effect
  const scrollThreshold = 1800; // Adjust this value as needed

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      if (inView && scrollTop > scrollThreshold) {
        const maxTranslateX = -3700;
        const adjustedScrollPercent = Math.max(
          0,
          (scrollTop - scrollThreshold) /
            (scrollHeight - clientHeight - scrollThreshold)
        );
        const newTransformX = Math.max(
          maxTranslateX,
          Math.min(0, adjustedScrollPercent * maxTranslateX)
        );
        setTransformX(newTransformX);
      } else {
        setTransformX(0);
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: [0.1] }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollThreshold, inView]);

  return (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
      }}
    >
      {/* Scroll Indicator */}
      <Box
        sx={{
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            top: "0px",
            left: "0px",
            right: "0px",
            zIndex: 1999,
            height: "3px",
            transformOrigin: "0% center",
            transform: `scaleX(${scrollScale})`,
            position: "fixed",
            background: "linear-gradient(135deg, #5BE49B, #00A76F)",
          }}
        ></Box>
      </Box>
      {/* Section 1 */}
      <Box
        sx={{
          minHeight: "760px",
          height: "100vh",
          maxHeight: "1440px",
          display: "block",
          willChange: "opacity",
          mt: "calc(72px * -1)",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            opacity: opacity,
            height: "100%",
            position: "fixed",
            maxHeight: "inherit",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          <Container
            sx={{
              flex: "1 1 auto",
              justifyContent: "center",
              py: "72px",
              px: "24px",
              width: "100%",
              gap: "40px",
              zIndex: 9,
              mx: "auto",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* Title / Desc stack */}
            <Box
              sx={{
                transform: `translateY(${transformFirstY}px);`, // to -700px
                width: "100%", // Ensure the box takes full width
                px: { xs: 2, sm: 4 }, // Padding adjustments for mobile and larger screens
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  textAlign: "center",
                  px: { xs: 1, sm: 3 }, // Padding adjustments for mobile and larger screens
                }}
              >
                {/* Title */}
                <Box
                  sx={{
                    opacity: 1,
                    willChange: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      fontWeight: 800,
                      fontSize: { xs: "2.2rem", sm: "3rem" }, // Responsive font size
                      margin: "0 auto",
                      maxWidth: "900px",
                      fontFamily:
                        'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    <Box
                      component="img"
                      alt="Highlight Dark Mode"
                      src={`${process.env.PUBLIC_URL}/favicon.png`}
                      sx={{
                        height: "80px",
                        width: "auto",
                        mb: { xs: 1, sm: 2 }, // Margin adjustments for mobile and larger screens
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        opacity: 0.24,
                      }}
                    >
                      Boost your project
                    </Box>
                    management with
                    <Box
                      sx={{
                        willChange: "auto",
                        ml: "8px",
                        backgroundClip: "text",
                      }}
                    >
                      Project Pit
                    </Box>
                  </Typography>
                </Box>
                {/* Description */}
                <Box
                  sx={{
                    transform: `none`, // to -600px
                    px: { xs: 2, sm: 4 }, // Padding adjustments for mobile and larger screens
                  }}
                >
                  <Box
                    sx={{
                      opacity: 1,
                      willChange: "auto",
                      transform: "none",
                      maxWidth: "100%", // Ensure it doesn't exceed the viewport width
                      mx: "auto", // Center align horizontally
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        whiteSpace: "pre-wrap", // Ensures text wraps properly
                        margin: "0 auto",
                        fontWeight: 400,
                        fontSize: { xs: "16px", sm: "18px" }, // Responsive font size
                        lineHeight: { xs: "20px", sm: "36px" }, // Responsive line height
                        fontFamily:
                          '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        maxWidth: { xs: "90%", sm: "100%" }, // Prevent overflow
                      }}
                    >
                      The starting point for your next project is Project Pit.{" "}
                      <br />
                      Efficient management and real-time collaboration
                      streamline your workflows and boost productivity.
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Box>

            {/* Buttons Stack */}
            <Box
              sx={{
                transform: `translateY(${transformSecondY}px);`, // to -400px
              }}
            >
              <Box
                sx={{
                  gap: "16px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    boxSizing: "border-box",
                    cursor: "pointer",
                    height: "48px",
                    minWidth: "64px",
                    outline: "0px",
                    p: "8px 16px",
                    borderWidth: 0,
                    color: "white",
                    borderRadius: "8px",
                    boxShadow: "none",
                    backgroundColor: "rgba(28, 37, 46, 1)",
                    fontFamily:
                      '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    transition:
                      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);",
                    "&:hover": {
                      backgroundColor: "#2D3A45",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => {
                    navigate("/documentation");
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <HelpOutlineIcon />
                    <Box
                      sx={{
                        textAlign: "start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          fontWeight: 700,
                        }}
                      >
                        Documentation
                      </Typography>
                      <Typography
                        sx={{
                          opacity: 0.64,
                          fontSize: "0.625rem",
                          fontWeight: 500,
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        v{process.env.REACT_APP_VERSION}
                      </Typography>
                    </Box>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      {/* Section 2 */}
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          backgroundColor: "#FFFFFF",
        }}
      >
        {/* Nested Section 1 */}
        <Box
          sx={{
            py: "160px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Container
            sx={{
              maxWidth: "1200px",
              width: "100%",
              ml: "auto",
              boxSizing: "border-box",
              display: "block",
              position: "relative",
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                flexFlow: "wrap",
                minWidth: "0px",
                boxSizing: "border-box",
                display: "flex",
                margin: "calc(0px / -2) calc(12 / -2)",
                position: "relative",
                zIndex: 9,
              }}
            >
              <Grid
                item
                sx={{
                  flexGrow: 0,
                  flexBasis: "auto",
                  width: "calc(100% * 7 / 12)",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    mb: "64px",
                    textAlign: "left",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "0.75rem",
                      color: "#919EAB",
                      textTransform: "uppercase",
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    Visualising Success
                  </Typography>
                  <Typography
                    sx={{
                      opacity: 1,
                      transform: "none",
                      willChange: "auto",
                      fontSize: "3rem",
                      fontWeight: 800,
                      fontFamily:
                        'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                    }}
                  >
                    {"What's in Project Pit?"}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    maxWidth: "400px",
                    mr: "unset",
                    gap: "48px",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  {/* Item 1 */}
                  <Box
                    sx={{
                      opacity: 1,
                      transform: "none",
                      willChange: "auto",
                      gap: "24px",
                      display: "flex",
                    }}
                  >
                    <AttachMoneyIcon
                      sx={{
                        width: "40px",
                        height: "40px",
                        flexShrink: 0,
                        display: "inline-flex",
                      }}
                    />
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          lineHeight: 1.5,
                          margin: "0px",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Budget
                      </Typography>
                      <Typography
                        sx={{
                          margin: "0px",
                          fontSize: "1rem",
                          fontWeight: 400,
                          color: "#637381",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        In-Depth Financial Analysis and Resource Management.
                      </Typography>
                    </Stack>
                  </Box>
                  {/* Item 2 */}
                  <Box
                    sx={{
                      opacity: 1,
                      transform: "none",
                      willChange: "auto",
                      gap: "24px",
                      display: "flex",
                    }}
                  >
                    <SignalCellularAltIcon
                      sx={{
                        width: "40px",
                        height: "40px",
                        flexShrink: 0,
                        display: "inline-flex",
                      }}
                    />
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          lineHeight: 1.5,
                          margin: "0px",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Forecasting
                      </Typography>
                      <Typography
                        sx={{
                          margin: "0px",
                          fontSize: "1rem",
                          fontWeight: 400,
                          color: "#637381",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Evaluating Forecasts Against Actual Results for Improved
                        Precision.
                      </Typography>
                    </Stack>
                  </Box>
                  {/* Item 3 */}
                  <Box
                    sx={{
                      opacity: 1,
                      transform: "none",
                      willChange: "auto",
                      gap: "24px",
                      display: "flex",
                    }}
                  >
                    <AccountTreeIcon
                      sx={{
                        width: "40px",
                        height: "40px",
                        flexShrink: 0,
                        display: "inline-flex",
                      }}
                    />
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          lineHeight: 1.5,
                          margin: "0px",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Project Management
                      </Typography>
                      <Typography
                        sx={{
                          margin: "0px",
                          fontSize: "1rem",
                          fontWeight: 400,
                          color: "#637381",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Coordinating Strategic Planning and Execution Across
                        Levels.
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
              {/* Image Item */}
              <Grid
                item
                sx={{
                  display: "block",
                  flexGrow: 0,
                  flexBasis: "auto",
                  width: "calc(100%* 5 / 12)",
                }}
              >
                <Stack
                  sx={{
                    opacity: 1,
                    transform: "none",
                    willChange: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      left: "0px",
                      width: "720px",
                      borderRadius: "16px",
                      position: "absolute",
                      backgroundColor: "#FFFFFF",
                      boxShadow:
                        "-40px 40px 80px 0px rgba(145, 158, 171, 0.16)",
                    }}
                  >
                    <Box
                      sx={{
                        width: "720px",
                        maxWidth: "100%",
                        verticalAlign: "middle",
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/Cumulative.png`}
                        alt="Home Chart"
                        style={{ width: "auto", height: "480px" }}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {/* Nested Section 2 */}
        {!isSmallDevice && (
          <>
            <Box
              sx={{
                pt: "160px",
              }}
            >
              <Box>
                <Container
                  sx={{
                    maxWidth: "1200px",
                    px: "24px",
                    width: "100%",
                    mx: "auto",
                    boxSizing: "border-box",
                    display: "block",
                  }}
                >
                  <Stack
                    sx={{
                      alignItems: "flex-start",
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      gap: "40px",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          fontWeight: 700,
                          fontSize: "0.75rem",
                          textTransform: "uppercase",
                          color: "#919EAB",
                          fontFamily:
                            '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        App Features
                      </Typography>
                      <Typography
                        sx={{
                          margin: "0px",
                          fontSize: "3rem",
                          fontWeight: 800,
                          fontFamily:
                            'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                      >
                        Highlight features
                      </Typography>
                    </Stack>
                  </Stack>
                </Container>
              </Box>
            </Box>
            <Box
              sx={{
                pt: "64px",
                zIndex: 9,
                position: "relative",
                height: "8364px",
                minHeight: "100vh",
              }}
            >
              <Box
                data-scrolling="false"
                sx={{
                  background: "transparent",
                  top: 0,
                  height: "100vh",
                  display: "flex",
                  position: "sticky",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  overflow: "hidden",
                  transition:
                    "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                }}
              >
                <Box
                  ref={targetRef}
                  sx={{
                    // translate3d(-7663.5px, 0px, 0px)
                    transform: `translate3d(${transformX}px, 0px, 0px)`,
                    transformOrigin: "50% 50% 0px",
                    gap: "64px",
                    pl: 0,
                    display: "flex",
                    ml: "185px",
                    transition:
                      "margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1), margin-top 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                  }}
                >
                  {/* Item 1 */}
                  <Box
                    sx={{
                      flexShrink: 0,
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        mb: 0,
                      }}
                    >
                      <DashboardIcon
                        sx={{
                          height: "28px",
                          width: "28px",
                          flexShrink: 0,
                          display: "inline-flex",
                          mt: "10px",
                        }}
                      />
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          mb: "48px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2rem",
                            margin: 0,
                            fontWeight: 700,
                            fontFamily:
                              'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          Dashboard
                        </Typography>
                        <Typography
                          sx={{
                            margin: 0,
                            fontWeight: 400,
                            fontSize: "1rem",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            color: "#637381",
                          }}
                        >
                          Real-Time Insights and Performance Tracking for
                          Effective Decision-Making
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        gap: "64px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "16px",
                          overflow: "hidden",
                          boxShadow:
                            "-40px 40px 80px 0px rgba(145, 158, 171, 0.16)",
                        }}
                      >
                        <Box
                          component="img"
                          alt="Highlight Dark Mode"
                          src={`${process.env.PUBLIC_URL}/images/Dashboard.png`}
                          sx={{
                            height: "auto",
                            width: "1140px",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                  {/* Item 2 */}
                  <Box
                    sx={{
                      flexShrink: 0,
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        mb: 0,
                      }}
                    >
                      <AutoGraphIcon
                        sx={{
                          height: "28px",
                          width: "28px",
                          flexShrink: 0,
                          display: "inline-flex",
                          mt: "10px",
                        }}
                      />
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          mb: "48px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2rem",
                            margin: 0,
                            fontWeight: 700,
                            fontFamily:
                              'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          Analytics
                        </Typography>
                        <Typography
                          sx={{
                            margin: 0,
                            fontWeight: 400,
                            fontSize: "1rem",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            color: "#637381",
                          }}
                        >
                          Evaluating Forecasts Against Actual Results for
                          Improved Precision.
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        gap: "64px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "16px",
                          overflow: "hidden",
                          boxShadow:
                            "-40px 40px 80px 0px rgba(145, 158, 171, 0.16)",
                        }}
                      >
                        <Box
                          component="img"
                          alt="Highlight Dark Mode"
                          src={`${process.env.PUBLIC_URL}/images/Anayltics.png`}
                          sx={{
                            width: "1140px",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                  {/* Item 3 */}
                  <Box
                    sx={{
                      flexShrink: 0,
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        mb: 0,
                      }}
                    >
                      <LayersIcon
                        sx={{
                          height: "28px",
                          width: "28px",
                          flexShrink: 0,
                          display: "inline-flex",
                          mt: "10px",
                        }}
                      />
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          mb: "48px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2rem",
                            margin: 0,
                            fontWeight: 700,
                            fontFamily:
                              'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          Programs
                        </Typography>
                        <Typography
                          sx={{
                            margin: 0,
                            fontWeight: 400,
                            fontSize: "1rem",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            color: "#637381",
                          }}
                        >
                          Strategic Alignment of Subprograms and Projects
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        gap: "64px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "16px",
                          overflow: "hidden",
                          boxShadow:
                            "-40px 40px 80px 0px rgba(145, 158, 171, 0.16)",
                        }}
                      >
                        <Box
                          component="img"
                          alt="Highlight Dark Mode"
                          src={`${process.env.PUBLIC_URL}/images/Programs.png`}
                          sx={{
                            width: "1140px",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                  {/* Item 4 */}
                  <Box
                    sx={{
                      flexShrink: 0,
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",
                        mb: 0,
                      }}
                    >
                      <NotesIcon
                        sx={{
                          height: "28px",
                          width: "28px",
                          flexShrink: 0,
                          display: "inline-flex",
                          mt: "10px",
                        }}
                      />
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          mb: "48px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "2rem",
                            margin: 0,
                            fontWeight: 700,
                            fontFamily:
                              'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          }}
                        >
                          Details
                        </Typography>
                        <Typography
                          sx={{
                            margin: 0,
                            fontWeight: 400,
                            fontSize: "1rem",
                            fontFamily:
                              '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                            color: "#637381",
                          }}
                        >
                          Key Project Details and Objectives
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        gap: "64px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "16px",
                          overflow: "hidden",
                          boxShadow:
                            "-40px 40px 80px 0px rgba(145, 158, 171, 0.16)",
                        }}
                      >
                        <Box
                          component="img"
                          alt="Highlight Dark Mode"
                          src={`${process.env.PUBLIC_URL}/images/ProjectDetails.png`}
                          sx={{
                            width: "1140px",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Stack>
      {/* Footer */}
      <Box
        sx={{
          pt: "20px",
          pb: "40px",
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            maxWidth: "1200px",
            px: "24px",
            width: "100%",
            mx: "auto",
            boxSizing: "border-box",
            display: "block",
          }}
        >
          <Box
            component="img"
            alt="Highlight Dark Mode"
            src={`${process.env.PUBLIC_URL}/images/favicon.png`}
            sx={{
              mt: 4,
              width: "35px",
              height: "40px",
              display: "inline-flex",
              verticalAlign: "middle",
            }}
          />
          <Box
            sx={{
              mt: "8px",
              fontWeight: 400,
              fontSize: "0.75rem",
              fontFamily:
                '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            }}
          >
            © All rights reserved.
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default PublicHomePage;
