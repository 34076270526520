import { Box } from "@mui/material";

const GanttItem = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        textAlign: "left",
        justifyContent: "center",
        ml: 2,
        position: "relative", // Needed for accurate measurement of the box width
        overflow: "hidden", // Prevents text from overflowing if it's too long
      }}
    ></Box>
  );
};

export default GanttItem;
